import styles from "./WizardStep.module.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@/components/UI/Tooltip";

const WizardStep = ({ stepNumber, text, url, visited }) => {
  const number = visited ? <FontAwesomeIcon icon={faCheck} /> : stepNumber;
  const numberCssClasses = visited ? `${styles.number} ${styles.visited}` : styles.number;
  const textCssClasses = visited ? `${styles.text} ${styles.visited}` : styles.text;

  return (
    <NavLink to={url} className={({ isActive }) => styles.step + (isActive ? ` ${styles.active}` : "")}>
      <Tooltip content={text}>
        <div className={numberCssClasses}>{number}</div>
      </Tooltip>
      <div className={textCssClasses}>{text}</div>
    </NavLink>
  );
};

export default WizardStep;
