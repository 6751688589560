import React from "react";
import leftImg from "@/assets/imgs/certificates/left.png";
import rightImg from "@/assets/imgs/certificates/right.png";
import styles from "./css/Certification.module.css";

function Certification() {
  return (
    <div className={`col-12 ${styles.certification}`}>
      <div className={`${styles.left}`}>
        <img src={leftImg} alt="icon" />
      </div>
      <div className={`d-flex align-items-center text-center ${styles.text}`}>
        <p>
          Pearl Certification is transforming the housing market. We’re making a visible
          difference nationwide for homeowners and the businesses that serve them.
        </p>
      </div>
      <div className={`${styles.right}`}>
        <img src={rightImg} alt="icon" />
      </div>
    </div>
  );
}

export default Certification;
