import { FormikProvider, useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import InputGroupField from "@/components/UI/FormFields/InputGroup";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import {
  useSolarValuationAdjustment,
  useUpdateSolarValuationAdjustment,
} from "@/hooks/use-solar-valuation-adjustment";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";

const schema = Yup.object().shape({
  discount_rate: Yup.number().min(
    0,
    "The Discount Rate should be greater than or equal to 0"
  ),
  electricity_escalation_rate: Yup.number()
    .min(0, "The Electricity Escalation Rate should be greater than or equal to 0")
    .max(100, "The Electricity Escalation Rate should be less than or equal to 100"),
  operations_and_maintenance_costs: Yup.number().min(
    0,
    "The Operation And Maintenance costs should be greater than or equal to 0"
  ),
  inverter_replacement_cost: Yup.number().min(
    0,
    "The Inverter Replacement Cost should be greater than or equal to 0"
  ),
});

const SolarValuationAdjustmentForm = () => {
  const { homeId } = useParams();
  const { data } = useSolarValuationAdjustment(homeId);
  const { mutate } = useUpdateSolarValuationAdjustment(homeId);

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setErrors }) => {
      try {
        await mutate(values);
      } catch (error) {
        handleMutationError(error, setStatus, setErrors);
      }
    },
    validationSchema: schema,
  });

  return (
    <FormikProvider value={formik}>
      <AutoSaveForm>
        <FormLevelError error={formik.status?.formLevelError} />
        <Row>
          <Col md={6}>
            <InputGroupField
              label="Discount Rate"
              name="discount_rate"
              appendedText="%"
              tooltipContent={<DiscountRateTooltip />}
            />
          </Col>
          <Col md={6}>
            <InputGroupField
              label="Electricity Escalation Rate"
              name="electricity_escalation_rate"
              appendedText="%"
              tooltipContent={<EscalationRateTooltip />}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputGroupField
              label={`Operation & Maintenance Costs`}
              name="operations_and_maintenance_costs"
              appendedText="$/kWh"
              tooltipContent={<OperationsAndCostsTooltip />}
            />
          </Col>
          <Col md={6}>
            <InputGroupField
              label="Inverter Replacement Cost"
              name="inverter_replacement_cost"
              prependedText="$"
              tooltipContent={<InverterReplacementCostTooltip />}
            />
          </Col>
        </Row>
      </AutoSaveForm>
    </FormikProvider>
  );
};

const DiscountRateTooltip = () => {
  return (
    <div>
      As a default, the Pearl Solar Equity Calculator uses the weekly published 30 year
      mortgage rate from Freddie Mac.
    </div>
  );
};

const EscalationRateTooltip = () => {
  return (
    <div>
      The Pearl Solar Equity Calculator pulls in the specific state’s average percentage
      electricity rate increase over the past 30 years, and applies this rate as a
      constant year-over-year increase to the electricity rate. This assumption implies
      that electricity rates will continue to increase over the useful life of the PV
      system. While past increases are not a guarantee of continued rate increases,
      historical data are typically the best single source for predictions about the
      future.
    </div>
  );
};

const OperationsAndCostsTooltip = () => {
  return (
    <div>
      The Pearl Solar Equity Calculator assumes an O&M cost of $11.50 per kW per year. The
      $11.50 rate is sourced from the two system benchmark studies from NREL, one from
      2018 and the other from 2020. NREL’s assessment of O&M costs is based on a working
      group of experts assessment of costs associated with best practices for maintaining
      an PV system.
    </div>
  );
};

const InverterReplacementCostTooltip = () => {
  return (
    <div>
      The Pearl Solar Equity Calculator breaks out inverter replacement costs as a
      separate item. The type of inverter needs to be considered to assess if there will
      be a replacement cost over the useful life of the system. Micro-inverters have the
      same useful life as the PV system, so no replacement cost is assumed for PV systems
      with micro-inverters. String inverters have a shorter expected useful life than the
      PV system and will likely need to be replaced at least once during the warranty
      period: as a result, a replacement cost is included in the cash flow calculation for
      PV systems with string inverters.
    </div>
  );
};

export default SolarValuationAdjustmentForm;
