import Result from "./Result";
import Promotion from "./Promotion";
import SystemProfile from "./SystemProfile";
import ReviewAndCertification from "./ReviewAndCertification";
import Partners from "./Partners";
import Descriptions from "./Descriptions";
import Footer from "./Footer";
import {
  useSolarSunPowerEstimate,
  useSolarValuation,
  useSolarValuationInputs,
} from "@/hooks/use-solar-valuation";
import { useParams } from "react-router-dom";
import { usePerms } from "@/hooks/use-perms";

const DefaultResults = () => {
  const { homeId } = useParams();
  const { data } = useSolarValuationInputs(homeId);
  const { data: solarValuation } = useSolarValuation(homeId);
  const { hasSunPowerPerm } = usePerms();
  const { data: calculation } = useSolarSunPowerEstimate(homeId, hasSunPowerPerm);
  const sunPowerGain = {
    hasSunPower: false,
    gainPercentage: 0,
    actualSystemValueToShow: 0,
  };
  if (calculation.is_sunpower) {
    sunPowerGain.hasSunPower = true;
    const expectedValue = solarValuation.expected_value;
    const averageEstimatedValue = calculation.expected_value;
    sunPowerGain.actualSystemValueToShow =
      expectedValue > 1000 ? `${Math.round(expectedValue / 1000)}K` : ~~expectedValue;
    sunPowerGain.gainPercentage = Math.round(
      (100 * (expectedValue - averageEstimatedValue)) / averageEstimatedValue
    );
  }

  return (
    <>
      <div id="page-to-download-1">
        <Result address={data.address} data={solarValuation} />
        <Promotion
          firmName={data.firm_name}
          firmLogo={data.firm_logo}
          sunPowerGain={sunPowerGain}
        />
        <SystemProfile
          address={data.address}
          ownership={data.ownership}
          utilityProvider={data.utility_provider}
          systemSize={data.system_size}
          electricityRate={data.electricity_rate}
          inverterType={data.inverter_type}
          panelManufacturer={data.panel_manufacturer}
          isSpecificUtility={data.is_specific_utility}
          specificUtilityPlanName={data.specific_utility_name}
        />
        <ReviewAndCertification />
      </div>
      <div id="page-to-download-2">
        <Partners />
        <Descriptions />
        <Footer address={data.address} />
      </div>
    </>
  );
};

export default DefaultResults;
