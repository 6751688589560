import React from "react";
import styles from "./LoginNoNetwork.module.css";
import { EmailInput, PasswordInput } from "@/components/UI/FormFields/Inputs";
import { Form, useFormikContext } from "formik";
import SubmitButton from "@/components/UI/SubmitButton";
import { Link } from "react-router-dom";

function LoginNoNetwork() {
  const formik = useFormikContext();

  const loginHandler = (event) => {
    event.preventDefault();
    formik.submitForm();
  };

  return (
    <Form onSubmit={loginHandler}>
      <EmailInput name="email" label="Email" shadow={false} />
      <PasswordInput name="password" label="Password" shadow={false} />
      <div className={styles["links"]}>
        <Link to="/appraiser-signup" className={styles["text-success"]}>
          Appraiser Signup
        </Link>
        <a
          href={process.env.REACT_APP_CERT_APP_URL + "/password_reset/"}
          className={styles["text-success"]}
          target="_blank"
          rel="noreferrer"
        >
          Forgot password?
        </a>
      </div>
      <SubmitButton
        variant="success"
        className={`mb-2  ${styles.button}`}
        label="Let’s go"
        isLoading={formik.isSubmitting}
        isRightIcon={true}
      />
      <p className={styles["info"]}>
        Please ensure that Pearl has setup your firm’s credentials in the Certification App before logging in.
      </p>
    </Form>
  );
}

export default LoginNoNetwork;
