import styles from "../common/AdvancedFieldsAccordion.module.css";
import { Accordion } from "react-bootstrap";
import React from "react";
import CollapsibleHeader from "@/components/Wizard/Step2/CollapsibleHeader";

const AdvancedFieldsAccordion = (props) => {
  return (
    <Accordion className={styles.accordion}>
      <Accordion.Item eventKey="0">
        <CollapsibleHeader title={props.title} eventKey="0" />
        <Accordion.Body>{props.children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default AdvancedFieldsAccordion;
