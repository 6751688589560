import styles from "./AppraiserResources.module.css";

const AppraiserResources = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Appraiser Resources</h2>
      <p>Below are additional resources on appraising high-performing homes.</p>
      <a
        className={`btn btn-primary btn-block ${styles.button}`}
        href="https://pearlcertification.com/news/webinar-maximizing-the-solar-investment-how-appraisers-value-solar"
        target="_blank"
        rel="noreferrer"
      >
        PV Systems
      </a>
      <a
        className={`btn btn-block ${styles["light-button"]}`}
        href="https://pearlcertification.com/real-estate-pros/appraisal-process"
        target="_blank"
        rel="noreferrer"
      >
        Energy Efficiency
      </a>
      <a
        className={styles.learn}
        href="https://www.appraisalinstitute.org/education/education-resources/green-building-resources/"
        target="_blank"
        rel="noreferrer"
      >
        Learn more about appraising high performing homes
      </a>
    </div>
  );
};

export default AppraiserResources;
