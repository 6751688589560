import { Outlet } from "react-router-dom";
import styles from "./AfterLoginLayout.module.css";
import Header from "@/components/AfterLogin/Header/Header";
import Sidebar from "@/components/AfterLogin/Sidebar/Sidebar";
import PrivateRoute from "@/routing/PrivateRoute";

const AfterLoginLayout = () => {
  return (
    <PrivateRoute>
      <div className={styles["page-container"]}>
        <Sidebar />
        <main className={styles.main}>
          <Header />
          <div className={styles["page-content"]}>
            <Outlet />
          </div>
        </main>
      </div>
    </PrivateRoute>
  );
};

export default AfterLoginLayout;
