import React from "react";
import partner1 from "@/assets/imgs/partners/1.png";
import partner2 from "@/assets/imgs/partners/2.png";
import partner5 from "@/assets/imgs/partners/5.png";
import partner6 from "@/assets/imgs/partners/6.png";
import styles from "./css/Partner.module.css";
function Partners() {
  return (
    <div className={`avoid-page-break-inside ${styles.container}`}>
      <div className={`col-12 ${styles["partner-text"]}`}>
        <p>
          We partner with the most respected names in energy efficiency, solar, and real
          estate.
        </p>
      </div>
      <div className={`col-12 ${styles["partner-imgs"]}`}>
        <img src={partner1} alt="partner" />
        <img src={partner2} alt="partner" />
        <img src={partner5} alt="partner" />
        <img src={partner6} alt="partner" />
      </div>
    </div>
  );
}

export default Partners;
