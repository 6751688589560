import AutocompleteField from "@/components/UI/FormFields/AutocompleteField";
import { useModelNumberSuggestions } from "@/hooks/use-suggestions";
import { useFormikContext } from "formik";
import { useDebouncedCallback } from "use-debounce";

const getSuggestionValue = (suggestion) => suggestion.number;

const renderSuggestion = (suggestion) => <div>{suggestion.number}</div>;

const ModelNumberAutocompleteField = ({ number = 1, system, onSuggestionSelected }) => {
  const { values } = useFormikContext();
  const { data, refetch } = useModelNumberSuggestions(
    system,
    values.manufacturer,
    values.model_number
  );
  const fecthSuggestions = useDebouncedCallback(() => refetch(true), 250);
  const onSuggestionsFetchRequested = () => {
    fecthSuggestions();
  };

  const autosuggestProps = {
    suggestions: data || [],
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested: () => { },
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
  };

  return (
    <AutocompleteField
      label="Model Number"
      name="model_number"
      helpText="This field supports autocomplete."
      autosuggestProps={autosuggestProps}
      id={`id-model_number-${number}`}
    />
  );
};

export default ModelNumberAutocompleteField;
