import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";
import { useState, useRef, useEffect, useReducer } from "react";
import HomeAddressFormField from "@/components/UI/FormFields/HomeAddressFormField";
import Suggestions from "./Suggestions";
import { debounce } from "lodash";
import { Row, Col, Button } from "react-bootstrap";
import buildFinalAddress from "./Helper.js";
import { autoCompleteClient } from "./SmartyStreets";
import { useCreateOrUpdateHome } from "@/hooks/use-create-or-update-home";
import styles from "./Autocomplete.module.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { displayBackendError } from "@/components/UI/FormFields/server-errors-display";
import DuplicateAddressModal from "./DuplicateAddressModal";

const renderAddress = (suggestion) => {
  let fullAddress = buildFinalAddress(suggestion);
  if (suggestion.entries > 1) {
    return (
      <>
        {fullAddress}
        <span className={styles.caret} />
      </>
    );
  } else {
    return fullAddress;
  }
};

const Autocomplete = ({ homeId, initialAddress = "", addressInputRef }) => {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (initialAddress) {
      setAddress(initialAddress);
    }
  }, [initialAddress]);

  const [modalState, dispatchModalState] = useReducer(modalReducer, initialModalState);
  const submitBtnText = homeId ? "Update Address" : "Create Home";
  const { isLoading, mutateAsync } = useCreateOrUpdateHome(homeId);
  const handleAddressSubmit = async () => {
    if (address) {
      try {
        await mutateAsync({ full_address: address, source: "solar_app" });
      } catch (error) {
        const status = error.response?.status;
        const data = error.response?.data;
        if (status === 400 && data?.code && data?.detail) {
          handleAddressValidationErrors(data.code, data.detail, dispatchModalState);
        } else {
          displayBackendError(status, data);
        }
      }
    } else {
      toast.dismiss();
      toast.error("Please enter the home's address.");
    }
  };

  const fetchSuggestions = (lookup) => {
    autoCompleteClient
      .send(lookup)
      .then((response) => {
        setSuggestions(response.result);
      })
      .catch(console.warn);
  };
  const debounceFetchingSuggestions = useRef(debounce(fetchSuggestions, 250));
  const addressChangeHandler = (e) => {
    const enteredAddress = e.target.value;
    if (enteredAddress) {
      const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(enteredAddress);
      debounceFetchingSuggestions.current(lookup);
    } else {
      // if the user removed everything he typed, then cancel the outstanding request
      debounceFetchingSuggestions.current.cancel();
      setSuggestions([]);
    }
    setAddress(enteredAddress);
  };

  const suggestionClickHandler = (suggestion) => {
    const isExpandableAddress = suggestion.entries > 1;
    if (isExpandableAddress) {
      const lookup = new SmartyStreetsSDK.usAutocompletePro.Lookup(suggestion.streetLine);
      lookup.selected = buildFinalAddress(suggestion);
      fetchSuggestions(lookup);
      setAddress(suggestion.streetLine);
    } else {
      setAddress(buildFinalAddress(suggestion));
    }
    setSuggestions([]);
  };

  return (
    <Row>
      <DuplicateAddressModal
        modalState={modalState}
        dispatchModalState={dispatchModalState}
      />
      <Col md={12}>
        <div>
          <HomeAddressFormField
            address={address}
            addressChangeHandler={addressChangeHandler}
            addressInputRef={addressInputRef}
          />
          {!!suggestions.length && (
            <Suggestions
              suggestions={suggestions}
              renderSuggestion={renderAddress}
              suggestionClickHandler={suggestionClickHandler}
              header="Suggested Addresses"
            />
          )}
        </div>
        <div className={styles["button-container"]}>
          <Button
            className={styles.submit}
            onClick={handleAddressSubmit}
            disabled={isLoading}
          >
            {submitBtnText}
            {isLoading && <FontAwesomeIcon icon={faSpinner} spin className="fa-fw" />}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

const initialModalState = {
  show: false,
  homeId: null,
  message: null,
  assignToHome: false,
};
const modalReducer = (state, action) => {
  if (action.type === "show") {
    return {
      show: true,
      homeId: action.payload.homeId || null,
      message: action.payload.message,
      assignToHome: action.payload.assignToHome || false,
    };
  } else if (action.type === "hide") {
    return initialModalState;
  } else {
    return state;
  }
};

const handleAddressValidationErrors = (errorCode, errorMsg, dispatchModalState) => {
  if (errorCode === "duplicate_address_with_access") {
    const homeId = errorMsg;
    const message = `
              You already have access to this home. Click the button below
              to load this home.
            `;
    dispatchModalState({ type: "show", payload: { homeId, message } });
  } else if (errorCode === "duplicate_address_without_access_appraiser_home_certified") {
    const homeId = errorMsg;
    const message = `
              This home is already Pearl Certified, which means we have
              data available for you to view on this home. Click the
              button below to load this home.
            `;
    dispatchModalState({
      type: "show",
      payload: { homeId, message, assignToHome: true },
    });
  } else if (
    errorCode === "duplicate_address_without_access_appraiser_home_not_certified" ||
    errorCode === "duplicate_address_without_access"
  ) {
    const message = errorMsg;
    dispatchModalState({ type: "show", payload: { message } });
  } else {
    toast.dismiss();
    toast.error(errorMsg, { autoClose: 5000 });
  }
};

export default Autocomplete;
