import React, { useState } from "react";
import styles from "./css/CertifyButton.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CertifyModal from "./certifyModal";
import { useCertificationRequest } from "@/hooks/use-certification-request";
import { useParams } from "react-router-dom";

function CertifyButton({ address }) {
  const { homeId } = useParams();
  const [show, setShow] = useState(false);
  const { data } = useCertificationRequest(homeId);
  const status = getCertificationStatus(data);
  const btnText = getBtnText(status);
  let btnCssClasses = [styles["certify-container"], "hide-when-printing"];
  if (status === "canRequest") {
    btnCssClasses.push(styles["no-cert-requested"]);
  } else {
    btnCssClasses.push(styles["cert-requested"]);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button
        className={btnCssClasses.join(" ")}
        onClick={handleShow}
        disabled={status !== "canRequest"}
        data-html2canvas-ignore={true}
      >
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
        <h5>{btnText}</h5>
      </button>
      <CertifyModal
        show={show}
        handleClose={handleClose}
        address={address.split(",").length ? address.split(",")[0] : address}
      />
    </>
  );
}

const getCertificationStatus = (certRequests) => {
  let status = "canRequest";
  if (certRequests?.length) {
    const latestCertRequest = certRequests[certRequests.length - 1];
    if (latestCertRequest.status === "pearl_certified") {
      status = "certified";
    } else if (
      ["contractor_approved", "pearl_approved", "pending_automation"].includes(
        latestCertRequest.status
      )
    ) {
      status = "pending";
    }
  }
  return status;
};

const getBtnText = (status) => {
  if (status === "certified") {
    return "Certified";
  } else if (status === "pending") {
    return "Pending Certification";
  } else {
    return "Certify";
  }
};

export default CertifyButton;
