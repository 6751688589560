import ModelNumberAutocompleteField from "./ModelNumberAutocompleteField";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import {
  useCreatePPW,
  usePPWs,
  useUpdatePPW,
} from "@/hooks/use-power-production-warranty";
import { useQueryClient } from "react-query";
import {
  useCreateSolarInverter,
  useSolarInverters,
  useUpdateSolarInverter,
} from "@/hooks/use-solar-inverters";

const ArrayModelNumberAutocompleteField = ({ number, system="solar_panel_and_array" }) => {
  const { homeId } = useParams();
  const { setFieldValue, submitForm } = useFormikContext();
  const queryClient = useQueryClient();

  // autopopulate PPW asset when the solar panel model number is selected
  // from the list of suggestions.
  const { mutateAsync: createPPW } = useCreatePPW(homeId);
  const { mutate: updatePPW } = useUpdatePPW(homeId);
  const { refetch } = usePPWs(homeId, { enabled: false });
  const updateOrCreatePPW = (existingPPWs, suggestion) => {
    const ppwData = {
      warranty: suggestion.ppw_warranty,
      power_at_end_of_warranty: suggestion.ppw_power_end_warranty,
      degradation_rate_year_one: suggestion.ppw_degradation_rate_year_one,
      degradation_rate_year_two: suggestion.ppw_degradation_rate_year_two,
    };
    const asset = getAssetForUpdate(existingPPWs, number);
    if (asset) {
      updatePPW({ assetId: asset.id, data: { ...asset, ...ppwData } });
    } else {
      const createPPWAndUpdateCache = async () => {
        const data = await createPPW({ verified: false, ...ppwData });
        queryClient.setQueryData(["homes", homeId, "ppw"], existingPPWs.concat([data]));
      };
      createPPWAndUpdateCache();
    }
  };

  // autopopulate Solar inverter when the solar panel model number is selected
  // from the list of suggestions.
  const { mutateAsync: createSolarInverter } = useCreateSolarInverter(homeId);
  const { mutate: updateSolarInverter } = useUpdateSolarInverter(homeId);
  const { refetch: fetchInverters } = useSolarInverters(homeId, { enabled: false });
  const updateOrCreateSolarInverter = (existingSolarInverters, suggestion) => {
    const solarInverterData = {
      manufacturer: suggestion.inverter_manufacturer,
      wattage: suggestion.inverter_wattage,
      warranty: suggestion.inverter_warranty,
      warranty_source: suggestion.inverter_warranty_offered,
      efficiency_rate: suggestion.inverter_efficiency_rate,
      model_number: suggestion.inverter_model_number,
      inverter_type: suggestion.inverter_type,
    };
    const asset = getAssetForUpdate(existingSolarInverters, number);
    if (asset) {
      updateSolarInverter({
        assetId: asset.id,
        data: { ...asset, ...solarInverterData },
      });
    } else {
      const createSolarInverterAndUpdateCache = async () => {
        const data = await createSolarInverter({ verified: false, ...solarInverterData });
        queryClient.setQueryData(
          ["homes", homeId, "solarInverters"],
          existingSolarInverters.concat([data])
        );
      };
      createSolarInverterAndUpdateCache();
    }
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setFieldValue("model_number", suggestionValue);
    setFieldValue("efficiency_rating", suggestion.panel_efficiency_rating || "");
    setFieldValue("warranty", suggestion.panel_warranty || "");
    submitForm();

    // auto-populate PPW
    const autoPopulatePPW = async () => {
      const response = await refetch();
      updateOrCreatePPW(response.data, suggestion);
    };
    if (
      suggestion.ppw_warranty ||
      suggestion.ppw_power_end_warranty ||
      suggestion.ppw_degradation_rate_year_one ||
      suggestion.ppw_degradation_rate_year_two
    ) {
      autoPopulatePPW();
    }

    // auto-populate Solar inverter
    const autoPopulateSolarInverter = async () => {
      const response = await fetchInverters();
      updateOrCreateSolarInverter(response.data, suggestion);
    };
    if (
      suggestion.inverter_manufacturer ||
      suggestion.inverter_wattage ||
      suggestion.inverter_warranty ||
      suggestion.inverter_warranty_offered ||
      suggestion.inverter_efficiency_rate ||
      suggestion.inverter_model_number ||
      suggestion.inverter_type
    ) {
      autoPopulateSolarInverter();
    }
  };

  return (
    <ModelNumberAutocompleteField
      system={system}
      number={number}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

/**
 * - if solarPanelNumber=1, then we return the first ppw asset,
 * if 2 then the second asset in the list and so on.
 * - if solarPanelNumber=1 and there is no ppw asset or,
 * solarPanelNumber=2 and there is only one ppw asset (or none),
 * then we return undefined as an indication that we need to create
 * a new ppw asset.
 */
const getAssetForUpdate = (existingPPWs, solarPanelNumber) => {
  if (existingPPWs.length) {
    return existingPPWs[solarPanelNumber - 1];
  }
};

export default ArrayModelNumberAutocompleteField;
