import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import "./HomeAddressFormField.css";
import "../FormFields/InputGroupWithShadow.css";

const HomeAddressFormField = ({ address, addressChangeHandler, addressInputRef }) => {
  return (
    <Form.Group
      controlId="id-home_address"
      className="input-group-with-shadow full-address form-group"
    >
      <Form.Label className="home-address-label">
        {"What is the home's address?"}
      </Form.Label>
      <InputGroup>
        <Form.Control
          ref={addressInputRef}
          name="home_address"
          type="text"
          onChange={addressChangeHandler}
          value={address}
          placeholder="Enter the home's address"
          autoComplete="off"
        />
        <FontAwesomeIcon className="font-awesome" icon={faMapMarkerAlt} size="2x" />
      </InputGroup>
    </Form.Group>
  );
};

export default HomeAddressFormField;
