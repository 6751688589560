import { usePerms } from "@/hooks/use-perms";
import LearnMoreAboutPearl from "../common/LearnMoreAboutPearl";

const ArrayNotes = () => {
  const { hasAppraiserPerm } = usePerms();
  if (hasAppraiserPerm) {
    return (
      <p>
        Please enter the details of the installed array(s) (the collection of solar
        panels) below.If you select the make and model of the panels some data will
        auto-populate. The only two required fields are size and installed date; however,
        the more info entered, the more accurate our calculation.
        <LearnMoreAboutPearl />
      </p>
    );
  } else {
    return (
      <p>
        Please enter the details of the proposed or installed array(s) (the collection of
        solar panels) below. If this is a proposed system please set the installed month
        to this month. If you select the make and model of the panels some data will
        auto-populate. The only two required fields are size and installed date; however,
        the more info entered, the more accurate our calculation.
      </p>
    );
  }
};

export default ArrayNotes;
