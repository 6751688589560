import FormContainer from "../common/FormContainer";
import FormHeader from "../common/FormHeader";
import { FormikProvider, useFormik } from "formik";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import * as Yup from "yup";
import { NumberInput } from "@/components/UI/FormFields/Inputs";
import AdvancedFieldsAccordion from "../common/AdvancedFieldsAccordion";
import React from "react";
import { useParams } from "react-router-dom";
import { useDeletePPW, useUpdatePPW } from "@/hooks/use-power-production-warranty";
import { cleanupNumberFieldsForSubmit, getCachedQuery } from "../common/helpers";
import { useQueryClient } from "react-query";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import InputGroupField from "@/components/UI/FormFields/InputGroup";

const PPWSchema = Yup.object().shape({
  warranty: Yup.number(),
  power_at_end_of_warranty: Yup.number().positive("Power at end of warranty must be a positive number"),
  degradation_rate_year_one: Yup.number().positive("1st Year Degradation must be a positive number"),
  degradation_rate_year_two: Yup.number().positive("2+ Year Degradation must be a positive number"),
});

const numberFields = [
  "warranty",
  "power_at_end_of_warranty",
  "degradation_rate_year_one",
  "degradation_rate_year_two",
];
const PowerProductionWarranty = ({ initialValues, number, totalPPWs }) => {
  const { homeId } = useParams();

  const { isLoading, mutateAsync: deletePPW } = useDeletePPW(homeId, initialValues.id);

  const queryClient = useQueryClient();
  const { mutateAsync: updatePPW } = useUpdatePPW(homeId);
  const submitHandler = async ({ id, ...rest }, { setStatus, setErrors }) => {
    const values = cleanupNumberFieldsForSubmit(rest, numberFields);
    const oldAssetData = getCachedQuery(queryClient, ["homes", homeId, "ppw"], id);
    const newAssetData = { ...oldAssetData, ...values };

    try {
      await updatePPW({ assetId: id, data: newAssetData });
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    initialTouched: {
      warranty: true,
      power_at_end_of_warranty: true,
      degradation_rate_year_one: true,
      degradation_rate_year_two: true,
    },
    enableReinitialize: true,
    validationSchema: PPWSchema,
    onSubmit: submitHandler,
  });
  return (
    <FormContainer>
      <FormikProvider value={formik}>
        <FormHeader
          title={`Power Production Warranty ${number}`}
          deleteHandler={deletePPW}
          deleteDisabled={totalPPWs === 1}
          isLoading={isLoading}
        />
        <AutoSaveForm>
          <FormLevelError error={formik.status?.formLevelError} />
          <Row>
            <Col md={4}>
              <NumberInput label="Warranty Term (years)" name="warranty" />
            </Col>
            <Col md={4}>
              <InputGroupField
                type="number"
                label="Power at end of warranty"
                name="power_at_end_of_warranty"
                appendedText="%"
              />
            </Col>
          </Row>
          <AdvancedFieldsAccordion title="Advanced Fields">
            <Row>
              <Col md={4}>
                <InputGroupField
                  type="number"
                  label="1st Year Degradation"
                  name="degradation_rate_year_one"
                  appendedText="%"
                />
              </Col>
              <Col md={4}>
                <InputGroupField
                  type="number"
                  label="2+ Year Degradation"
                  name="degradation_rate_year_two"
                  appendedText="%"
                />
              </Col>
            </Row>
          </AdvancedFieldsAccordion>
        </AutoSaveForm>
      </FormikProvider>
    </FormContainer>
  );
};
export default PowerProductionWarranty;
