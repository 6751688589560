import Partners from "@/components/Login/Partners";
import WelcomeAppraiser from "@/components/AppraiserRegistration/WelcomeAppraiser";
import useWindowSize from "@/hooks/use-window-size";
import React from "react";

import OptionSnippets from "@/components/AppraiserRegistration/OptionSnippets";
import RegistrationCall from "@/components/AppraiserRegistration/RegistrationCall";
import LearnMore from "@/components/AppraiserRegistration/LearnMore";
import { useNavigate } from "react-router-dom";
import LoginForm from "@/components/Login/LoginForm";

const AppraiserLogin = () => {
  const [width] = useWindowSize();
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow((bool) => (bool && width >= 992 ? false : bool));
  }, [width]);

  const navigate = useNavigate();
  const freeRegistrationClickHandler = () => {
    navigate("/appraiser-signup");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="col-lg-6 px-4">
          <WelcomeAppraiser setShow={setShow} btnText="Appraiser Login" />
        </div>
        <LoginForm show={show} setShow={setShow} breakpointForHidingLogin={992} />
      </div>
      <Partners />
      <div className="row align-items-center">
        <div className="col-md-7">
          <OptionSnippets />
        </div>
        <div className="col-md-5 px-0">
          <RegistrationCall freeRegistrationClickHandler={freeRegistrationClickHandler} />
        </div>
      </div>
      <LearnMore />
    </>
  );
};

export default AppraiserLogin;
