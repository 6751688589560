import { Form } from "react-bootstrap";
import React from "react";
import { useField } from "formik";

const CheckBox = (props) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const isInvalid = meta.touched && !!meta.error;
  return (
    <Form.Group controlId={`id-${field.name}`} className="form-group">
      <Form.Check>
        <Form.Check.Input {...field} {...props} isInvalid={isInvalid} />
        <Form.Check.Label>{props.label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
      </Form.Check>
    </Form.Group>
  );
};
export default CheckBox;
