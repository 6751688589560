import styles from "./NavButtons.module.css";
import { Button } from "react-bootstrap";

const NavButtons = ({
  onNextClick,
  onBackClick,
  nextText = "Next",
  backText = "Back",
  disableNext = false,
  disableBack = false,
}) => {
  return (
    <div className={styles.buttons}>
      <Button
        variant="light"
        className={styles.back}
        onClick={onBackClick}
        disabled={disableBack}
      >
        {backText}
      </Button>
      <Button type="submit" onClick={onNextClick} disabled={disableNext}>
        {nextText}
      </Button>
    </div>
  );
};

export default NavButtons;
