import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useEffect } from "react";

export const usePPWs = (homeId, options) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId, "ppw"],
    async () => {
      const response = await client.get(`/homes/${homeId}/power_production_warranty/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      ...options,
    }
  );
};

export const useCreatePPW = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await client.post(
        `/homes/${homeId}/power_production_warranty/`,
        data
      );
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["homes", homeId, "ppw"]);
      },
    }
  );
};

const initialData = [
  {
    id: 0,
    warranty: "",
    power_at_end_of_warranty: "",
    degradation_rate_year_one: "",
    degradation_rate_year_two: "",
  },
];
export const useGetOrCreatePPW = (homeId) => {
  const { isFetched: isFetchedGetPPWs, data: existingPPWs } = usePPWs(homeId, {
    initialData,
    initialStale: true,
  });

  const { mutate: createPPW, data: createdPPW } = useCreatePPW(homeId);

  const haveExistingPPWs = existingPPWs?.length && existingPPWs[0]?.id;
  const assets = getPPWs(existingPPWs, createdPPW);

  useEffect(() => {
    if (isFetchedGetPPWs && !haveExistingPPWs && !createdPPW?.id) {
      createPPW({ verified: false });
    }
  }, [isFetchedGetPPWs, haveExistingPPWs, createdPPW, createPPW]);

  return assets;
};

const getPPWs = (existingPPWs, createdPPW) => {
  let assets = [];
  if (existingPPWs?.length) {
    assets = existingPPWs;
  } else if (createdPPW?.id) {
    assets = [createdPPW];
  } else {
    // this prevents the sudden disappearance and then appearance of the form
    // when the home is first created (time it takes to create the first ppw)
    assets = initialData;
  }
  return prepareData(assets);
};

const prepareData = (assets) => {
  const ppws = [];
  for (const asset of assets) {
    ppws.push({
      id: asset.id,
      warranty: asset.warranty || "",
      power_at_end_of_warranty: asset.power_at_end_of_warranty || "",
      degradation_rate_year_one: asset.degradation_rate_year_one || "",
      degradation_rate_year_two: asset.degradation_rate_year_two || "",
    });
  }
  return ppws;
};

export const useUpdatePPW = (homeId) => {
  const client = usePearlApi();

  return useMutation(async ({ assetId, data }) => {
    const response = await client.post(
      `/homes/${homeId}/power_production_warranty/${assetId}/`,
      data
    );
    return response.data;
  });
};

export const useDeletePPW = (homeId, assetId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();
  const url = `/homes/${homeId}/power_production_warranty/${assetId}/`;

  return useMutation(() => client.delete(url), {
    onError: (error) => {
      handleQueryError(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["homes", homeId, "ppw"]);
    },
  });
};
