import styles from "./RecentHomesHeader.module.css";
import HomeAddressSearchFormField from "@/components/UI/FormFields/HomeAddressSearchFormField";

const RecentHomesHeader = ({ headerTitle, enteredAddress, addressChangeHandler }) => {
  return (
    <div className={styles.headerContainer}>
      <h3 className={styles.header}>{headerTitle}</h3>
      <HomeAddressSearchFormField
        onAddressChange={addressChangeHandler}
        enteredAddress={enteredAddress}
      />
    </div>
  );
};

export default RecentHomesHeader;
