import { Alert } from "react-bootstrap";

const FormLevelError = ({ error }) => {
  if (!error) {
    return <></>;
  }
  return (
    <Alert variant="danger" style={{ backgroundColor: "#ffeef0", color: "#dc3545" }}>
      {error}
    </Alert>
  );
};

export default FormLevelError;
