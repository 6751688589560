import ActionButton from "./ActionButton";
import EstimateEquValue from "./EstimateEquValue";
import EstimateEquValueMultipleRates from "./EstimateEquValueMultipleRates";
import React from "react";
import styles from "./css/Result.module.css";
import DownloadButton from "./DownloadButton";
import { usePerms } from "@/hooks/use-perms";
import AppraiserReport from "@/components/ResultAppraiser/Reports/AppraiserReport";

function Result({ address, data }) {
  const { hasAppraiserPerm } = usePerms();
  const multiple_rates =
    !hasAppraiserPerm &&
    Object.hasOwn(data, "historical_rates") &&
    Array.isArray(data.historical_rates) &&
    data.historical_rates.length > 0;
  const actionBtns = (
    <div
      className={`hide-when-printing ${styles.actions}`}
      data-html2canvas-ignore={true}
    >
      <DownloadButton address={address} />
      <ActionButton />
    </div>
  );
  return (
    <div className={styles.container}>
      <div className={styles.child}>
        <h4 className={styles.heading}>Results for {address}</h4>
        {hasAppraiserPerm ? <AppraiserReport /> : actionBtns}
      </div>
      <hr className={styles.hr} />
      {!multiple_rates && <EstimateEquValue data={data} />}
      {multiple_rates && <EstimateEquValueMultipleRates data={data} />}
      <p className={styles.disclaimer}>
        Disclaimer: The data provided by this tool is intended for reference in the
        appraisal process; the indicated value range is an estimate, and is not a
        guarantee of any specific sale price or valuation for the home or its features.
        The above estimate is based on calculations and conditions as of today, and is
        subject to change based on market and industry trends and other factors.*{" "}
      </p>
    </div>
  );
}

export default Result;
