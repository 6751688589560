import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useEffect } from "react";

const useEnergyStorages = (homeId, options) => {
  const client = usePearlApi();
  return useQuery(
    ["homes", homeId, "energyStorage"],
    async () => {
      const response = await client.get(`/homes/${homeId}/energy_storage/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      ...options,
    }
  );
};

export const useEnergyStorage = (homeId) => {
  const { data, ...rest } = useEnergyStorages(homeId);
  const EnergyStorage = data?.length ? data[0] : null;
  return { data: EnergyStorage, ...rest };
};

export const useCreateEnergyStorage = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await client.post(`/homes/${homeId}/energy_storage/`, data);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["homes", homeId, "energyStorage"]);
      },
    }
  );
};

export const useUpdateEnergyStorage = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();
  const updatedCachedAssets = (oldAssets, asset) => {
    const updatedAssets = [];
    for (const oldAsset of oldAssets) {
      updatedAssets.push(asset.id === oldAsset.id ? asset : oldAsset);
    }
    return updatedAssets;
  };
  return useMutation(
    ({ assetId, data }) => {
      const updateUrl = `/homes/${homeId}/energy_storage/${assetId}/`;
      return client.post(updateUrl, data);
    },
    {
      onSuccess: ({ data }) =>
        queryClient.setQueryData(["homes", homeId, "energyStorage"], (oldData) =>
          updatedCachedAssets(oldData, data)
        ),
    }
  );
};
const initialValues = {
  manufacturer: "",
  model_number: ""
};
export const useGetOrCreateEnergyStorage = (homeId) => {
  const {
    isLoading: isLoadingGetEnergyStorage,
    isSuccess: isSuccessGetEnergyStorage,
    data: existingPanel,
  } = useEnergyStorage(homeId);
  const {
    mutate: createEnergyStorage,
    isLoading: isLoadingCreateEnergyStorage,
    data: createdPanel,
  } = useCreateEnergyStorage(homeId);
  const isLoading = isLoadingGetEnergyStorage || isLoadingCreateEnergyStorage;
  const assetId = existingPanel?.id || createdPanel?.id;
  const isSuccess = !!assetId;
  const data = existingPanel || createdPanel || initialValues;
  for(const key in initialValues){
    data[key] = data[key] || ""
  }
  useEffect(() => {
    if (isSuccessGetEnergyStorage && !assetId && !isLoadingCreateEnergyStorage) {
      // assets created through the equity app should be set as unverified
      createEnergyStorage({ verified: false });
    }
  }, [
    isSuccessGetEnergyStorage,
    assetId,
    isLoadingCreateEnergyStorage,
    createEnergyStorage,
    homeId,
  ]);

  return { isLoading, isSuccess, data, assetId };
};
