import logo from "@/assets/imgs/brand.png";
import styles from "./css/Header.module.css";
import { useLocation } from "react-router-dom";
import LoginButton from "../AppraiserRegistration/LoginButton";

const Header = ({ urls }) => {
  const { pathname } = useLocation();

  return (
    <header className={styles.header}>
      <div className={styles.row}>
        <div className={styles.logo}>
          <img src={logo} alt="brand" className={styles.brand} />
          <div className={styles.vr} />
          <h2 className="text-uppercase">Pearl Solar Equity Calculator</h2>
        </div>
        <div className="sidenav-open">
          <a
            href="#sidenav-open"
            id="sidenav-button"
            className="hamburger"
            title="Open Menu"
            aria-label="Open Menu"
          >
            <svg
              viewBox="0 0 50 40"
              role="presentation"
              focusable="false"
              aria-label="trigram for heaven symbol"
            >
              <line x1="0" x2="100%" y1="10%" y2="10%"></line>
              <line x1="0" x2="100%" y1="50%" y2="50%"></line>
              <line x1="0" x2="100%" y1="90%" y2="90%"></line>
            </svg>
          </a>
        </div>

        <div
          className={`col-sm-8 ms-auto col-xs-0 d-flex justify-content-end align-items-center fw-bolder p-0 ${styles["responsive-nav"]}`}
        >
          {urls.map((url, key) => (
            <a
              key={key}
              href={url.link}
              className="d-flex"
              target="_blank"
              rel="noreferrer"
            >
              <img className="me-1" src={url.icon} alt="icon" />
              {url.text}
            </a>
          ))}
          {pathname === "/appraiser-signup" && <LoginButton />}
        </div>
      </div>
    </header>
  );
};

export default Header;
