import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SidebarEntry from "@/components/AfterLogin/Sidebar/SidebarEntry.js";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import pearlLogo from "@/components/AfterLogin/Sidebar/pearl-logo.png";
import styles from "@/components/AfterLogin/Sidebar/SidebarContent.module.css";
import { useLocation } from "react-router-dom";
import LoginButton from "@/components/AppraiserRegistration/LoginButton";

const SidebarContent = ({ urls, closeSidebar }) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className={styles["sidebar-header"]}>
        <Link to="/homes">
          <img className={styles.logo} src={pearlLogo} alt="logo" />
        </Link>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          className={styles.close}
          onClick={closeSidebar}
        />
      </div>
      <h4 className={styles.title}>PEARL CERTIFICATION</h4>
      {urls.map((url, key) => (
        <SidebarEntry key={key} text={url.text} url={url.link} icon={url.icon} />
      ))}
      {pathname === "/appraiser-signup" && (
        <div className={"mx-4 mb-4"}>
          <LoginButton />
        </div>
      )}
    </>
  );
};

export default SidebarContent;
