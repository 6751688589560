import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showSignUp: false,
    showSignUpWithPearl: false,
};

const modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
        setShowSignUp(state, action) {
            state.showSignUp = action.payload
        },
        setShowSignUpWithPearl(state, action) {
            state.showSignUpWithPearl = action.payload
        },
        closeModals(state) {
            state.showSignUp = false
            state.showSignUpWithPearl = false
        },
    },
});

export const modalActions = modalSlice.actions;
export default modalSlice;