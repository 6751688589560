import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./css/RegistrationCall.module.css";

const RegistrationCall = ({ freeRegistrationClickHandler }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.text}>
        Get the most accurate appraisal for high-performing homes.
      </div>
      <button className={`btn ${styles.btn}`} onClick={freeRegistrationClickHandler}>
        Free Registration
        <FontAwesomeIcon icon={faCheckCircle} className={styles.icon} />
      </button>
    </div>
  </div>
);

export default RegistrationCall;
