import { createSlice } from "@reduxjs/toolkit";
import jwt from "jsonwebtoken";

const initialState = {
  isAuthenticated: false,
  email: null,
  firstName: null,
  lastName: null,
  accessToken: null,
  refreshToken: null,
  perms: [],
  lastUserType: null,
};

const loadAuthState = () => {
  try {
    const serializedState = localStorage.getItem("authState");
    if (serializedState === null) {
      return initialState;
    }
    const storedState = JSON.parse(serializedState);
    const authState = Object.keys(initialState).reduce((acc, elm) => {
      acc[elm] = storedState[elm];
      return acc;
    }, {});
    return authState;
  } catch {
    return initialState;
  }
};

export const saveAuthState = (authState) => {
  try {
    const serializedState = JSON.stringify(authState);
    localStorage.setItem("authState", serializedState);
  } catch (err) {
    console.log(err);
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: loadAuthState(),
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.refreshToken = action.payload.refresh;
      state.accessToken = action.payload.access;
      const payload = getTokenPayload(action.payload.access);
      setClaimsAsState(state, payload);
    },
    logout(state) {
      state.isAuthenticated = false;
      state.email = null;
      state.firstName = null;
      state.lastName = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.perms = [];
      // lastUserType not being cleared will be use it to know
      // if the user is an appraiser or not to decide on which login page they
      // should see
    },
    updateAccess(state, action) {
      state.accessToken = action.payload.access;
      const payload = getTokenPayload(action.payload.access);
      setClaimsAsState(state, payload);
    },
  },
});

const getTokenPayload = (token) => {
  const decodedToken = jwt.decode(token, { complete: true });
  return decodedToken.payload;
};

const setClaimsAsState = (state, claims) => {
  state.email = claims.email;
  state.firstName = claims.firstName;
  state.lastName = claims.lastName;
  state.perms = claims.perms;
  state.lastUserType = getLastUserType(claims.perms);
};
const getLastUserType = (perms) => {
  const setPerms = new Set(perms);
  if (setPerms.has("administration.appraise")) {
    return "appraiser";
  }
  if (setPerms.has("has_contractor_perm")) {
    return "contractor";
  }
  if (setPerms.has("has_pearl_perm")) {
    return "admin";
  }
  return null;
};

export const authActions = authSlice.actions;
export default authSlice;
