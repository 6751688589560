import React from "react";
import downloadSVG from "@/assets/icons/download.svg";
import styles from "./css/ActionButton.module.css";
import html2PDF from "@/helpers/jspdf-html2canvas-multi-page-conf/js-pdf";

function DownloadButton({ address }) {
  const downloadPdfDocumentHandler = () => {
    const pages = [
      document.getElementById("page-to-download-1"),
      document.getElementById("page-to-download-2"),
    ];

    // FIXME find a better package for generating pdfs from html instead
    //  of having to copy the source and make changes (or otherwise fork
    //  the repo and make changes)
    html2PDF(pages, {
      jsPDF: {
        format: "letter",
      },
      imageType: "image/jpeg",
      output: `${address.replace(/\s/g, "")}-calculation-results.pdf`,
      margin: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      },
      // the hardcoded values:
      // - windowWidth,
      // - windowHeight,
      // - width (equal to elt.offsetWidth),
      // - height (equal to elt.offsetHeight),
      // - x (equal to elt.offsetLeft) and,
      // - y (equal to elt.offsetTop)
      // are the ideal desktop configuration for each page
      html2canvasMultiPageConf: [
        {
          useCORS: true,
          imageTimeout: 15000,
          logging: true,
          windowWidth: 1920,
        },
        {
          useCORS: true,
          imageTimeout: 15000,
          logging: true,
          windowWidth: 1920,
        },
      ],
    });
  };

  return (
    <button className={`btn ${styles.actionsBtn}`} onClick={downloadPdfDocumentHandler}>
      <img src={downloadSVG} className={styles.downloadImg} alt="Download" />
      Download
    </button>
  );
}

export default DownloadButton;
