import React from "react";
import LoginFormContainerNoNetwork from "./LoginFormContainerNoNetwork";

function LoginFormNoNetwork() {
  return (
    <div className={`col-xs-12 col-md-6 col-lg-5 ms-auto p-0 mb-5 mx-auto`}>
      <LoginFormContainerNoNetwork />
    </div>
  );
}

export default LoginFormNoNetwork;
