import { useParams } from "react-router-dom";
import EnergyBillsGenerateForm from "./EnergyBillsGenerateForm";
import EnergyBillsManualForm from "./EnergyBillsManualForm";
import { useGetOrCreateSolarPanel } from "@/hooks/use-solar-panels";
import Spinner from "@/components/UI/Spinner";

const EnergyBillsForm = () => {
  const { homeId } = useParams();
  const {
    isLoading,
    data: assetData,
  } = useGetOrCreateSolarPanel(homeId);
  return (
    <>
      <Spinner isLoading={isLoading} />
      <EnergyBillsGenerateForm />
      <EnergyBillsManualForm
        assetData={assetData}
      />
    </>
  );
};
export default EnergyBillsForm;
