import usePearlApi from "./use-pearl-api";
import { useQuery } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";

export const useCertificationRequest = (homeId) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId, "CertificationRequest"],
    async () => {
      const response = await client.get(`/homes/${homeId}/certification_requests/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
    }
  );
};

export default useCertificationRequest;
