import OptionSnippet from "./OptionSnippet";
import styles from "./css/OptionSnippets.module.css";
import check from "@/assets/icons/check.svg";
import data from "@/assets/icons/data.svg";
import calculator from "@/assets/icons/calculator.svg";

const OptionSnippets = () => {
  const sections = [
    {
      title: "Sign Up For Free!",
      description:
        "The Pearl Solar Equity Calculator is free for all licensed appraisers. It is easy to create an account and your account will house all your high-performing home’s appraisals. ",
      icon: check,
    },
    {
      title: "Enter Home Data",
      description:
        "With auto-data population and simplified data entry it only takes minutes to complete a valuation for a solar installation.",
      icon: data,
    },
    {
      title: "Receive Discounted Cash Flow",
      description:
        "The Pearl Solar Equity Calculator will return the discounted cash flow along with all the supporting documentation to use in your appraisal of a high-performing home.",
      icon: calculator,
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>How does the Pearl Solar Equity Calculator work?</div>
      <div className={styles.sections}>
        {sections.map(({ title, description, icon }, key) => (
          <OptionSnippet key={key} title={title} description={description} icon={icon} />
        ))}
      </div>
    </div>
  );
};
export default OptionSnippets;
