import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import styles from "./FormFieldWithShadow.module.css";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { datepicker } from "./DatepickerField.module.css";
import RequiredAsterisk from "./RequiredAsterisk";
import { format } from "date-fns";

const DatepickerMonthField = ({
    label,
    helpText,
    name,
    shadow = true,
    required = false,
    id = null,
    ...props
}) => {
    const { setFieldValue, setTouched } = useFormikContext();
    // field is ignored since formik onChange and onBlur don't work with the datepicker
    // onBlur is only triggered when the field loses focus and nothing changed
    // eslint-disable-next-line
    const formik = useFormikContext();
    // eslint-disable-next-line
    const [field, meta] = useField({ name, ...props });
    const isInvalid = meta.touched && !!meta.error;
    const inputCssClasses = isInvalid ? "form-control is-invalid" : "form-control";
    let cssClasses = [datepicker, "form-group"];
    if (shadow) {
        cssClasses.push(styles.shadow);
    }
    const fieldId = id ? id : `id-${name}`;
    let selectedDate = null
    if (meta.value) {
        selectedDate = getLocalStartOfMonth(meta.value)
    }
    const handleChange = (date) => {
        setFieldValue(name, date && format(date, "yyyy-MM"));
        setTouched(name);
        const submit = async () => {
            await formik.validateForm();
            formik.submitForm();
        };
        submit();
    }
    return (
        <Form.Group controlId={fieldId} className={cssClasses.join(" ")}>
            <Form.Label>
                {label}
                {required && <RequiredAsterisk />}
            </Form.Label>
            <DatePicker
                name={name}
                selected={selectedDate}
                className={inputCssClasses}
                onChange={handleChange}
                placeholderText="yyyy-MM"
                dateFormat="yyyy-MM"
                showMonthYearPicker
                {...props}
            />
            {helpText && (
                <Form.Text id={`help-text-${fieldId}`} muted>
                    {helpText}
                </Form.Text>
            )}
            <Form.Control.Feedback type="invalid" className={isInvalid ? "d-block" : ""}>
                {meta.error}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

function getLocalStartOfMonth(dateString) {
    const date = new Date(dateString);
    const offsetInMillis = date.getTimezoneOffset() * 60 * 1000
    const startOfMonth = new Date(date.getTime() + offsetInMillis);
    return startOfMonth;
}
export default DatepickerMonthField;
