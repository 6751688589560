import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./PvSystemOWFTooltip.module.css";
import Tooltip from "@/components/UI/Tooltip";

const PvSystemOWFTooltip = () => {
  const content = (
    <div className={styles.content}>
      To check if this system has a UCC filing please follow this link:
      <br />
      <a
        href="https://www.nass.org/business-services/ucc-filings"
        target="_blank"
        rel="noreferrer"
      >
        https://www.nass.org/business-services/ucc-filings
      </a>
    </div>
  );
  return (
    <Tooltip
      interactive={true}
      content={content}
      className={styles["pvsystem-owf-tooltip"]}
    >
      <span className="ms-2 mt-1">
        <FontAwesomeIcon icon={faInfoCircle} color="#055689" />
      </span>
    </Tooltip>
  );
};

export default PvSystemOWFTooltip;
