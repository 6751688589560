import React from "react";
import styles from "./css/EstimateEquValueMultipleRates.module.css";

const EstimateEquValueSingleRate = ({ rate, index }) => {
  const interest_rate = rate.interest_rate.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
  const low_value = rate.low_value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  const high_value = rate.high_value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div className={`${styles.rate} ${styles["rate-" + index]}`}>
      <div className={styles.rate_range}>
        ${low_value} - ${high_value}
      </div>
      <div className={styles.line}></div>
      <div className={styles.rate_value}>
        <div>
          {interest_rate}
          <sup>%</sup>
        </div>
        <div>Discount Rate</div>
      </div>
    </div>
  );
};

const EstimateEquValueMultipleRatesDesktop = ({ rates }) => {
  return (
    <div className={styles.rates_container}>
      {rates.map((rate, key) => (
        <EstimateEquValueSingleRate rate={rate} key={key} index={key + 1} />
      ))}
    </div>
  );
};

export default EstimateEquValueMultipleRatesDesktop;
