import { Button } from "react-bootstrap";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import { useSolarSystemYearlyValue } from "@/hooks/use-solar-valuation";
import styles from "./CashFlowTableExportButton.module.css";

const CashFlowTableExportButton = ({ valuationId }) => {
  const { homeId } = useParams();
  const { isLoading, refetch } = useSolarSystemYearlyValue(homeId, valuationId, true);

  const handleClick = async () => {
    const { data } = await refetch();
    let blobUrl = window.URL.createObjectURL(data);
    let a = document.createElement("a");
    a.href = blobUrl;
    a.download = "total-discounted-cash-flow.csv";
    a.click();
  };

  return (
    <Button
      variant="outline-secondary"
      disabled={isLoading}
      onClick={handleClick}
      className={styles.button}
    >
      {!isLoading && <FontAwesomeIcon icon={faDownload} />}
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      <span className="ms-2 d-none d-md-inline-block">Export Table</span>
    </Button>
  );
};

export default CashFlowTableExportButton;
