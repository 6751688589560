import FormContainer from "../common/FormContainer";
import FormHeader from "../common/FormHeader";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { NumberInput, TextInput } from "@/components/UI/FormFields/Inputs";
import Select from "@/components/UI/FormFields/Select";
import InputGroupField from "@/components/UI/FormFields/InputGroup";
import * as Yup from "yup";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import {
  useDeleteSolarInverter,
  useUpdateSolarInverter,
} from "@/hooks/use-solar-inverters";
import { useParams } from "react-router-dom";
import { cleanupNumberFieldsForSubmit, getCachedQuery } from "../common/helpers";
import { useQueryClient } from "react-query";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import ManufacturerAutocompleteField from "../Step2/ManufacturerAutocompleteField";
import InverterModelNumberAutocompleteField from "./InverterModelNumberAutocompleteField";
import { usePerms } from "@/hooks/use-perms";

const inverterTypeChoices = [
  { label: "Select Inverter Type", value: "" },
  { label: "String Inverter", value: "string_inverter" },
  { label: "Micro-Inverter", value: "micro_inverter" },
  { label: "Power Optimizer", value: "power_optimizer" },
  { label: "Don't know", value: "dont_know" },
];
const inverterSchema = Yup.object().shape({
  manufacturer: Yup.string(),
  model_number: Yup.string(),
  efficiency_rate: Yup.number().min(90).max(99.5),
  inverter_type: Yup.string(),
  wattage: Yup.number(),
  warranty: Yup.number(),
  warranty_source: Yup.string(),
});

const numberFields = ["efficiency_rate", "wattage", "warranty"];

const Inverter = ({ initialValues, number, totalInverters }) => {
  const { homeId } = useParams();
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync: deleteSolarInverter } = useDeleteSolarInverter(
    homeId,
    initialValues.id
  );

  const { mutateAsync: updateSolarInverter } = useUpdateSolarInverter(homeId);
  const submitHandler = async ({ id, ...rest }, { setStatus, setErrors }) => {
    const values = cleanupNumberFieldsForSubmit(rest, numberFields);
    const oldAssetData = getCachedQuery(
      queryClient,
      ["homes", homeId, "solarInverters"],
      id
    );
    const newAssetData = { ...oldAssetData, ...values };

    try {
      await updateSolarInverter({ assetId: id, data: newAssetData });
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: inverterSchema,
    onSubmit: submitHandler,
  });
  const { hasSunPowerPerm } = usePerms();
  useEffect(() => {
    if (hasSunPowerPerm && initialValues.id && !initialValues.manufacturer) {
      formik.setFieldValue("manufacturer", "SunPower");
      formik.submitForm();
    }
  }, [hasSunPowerPerm, initialValues.id]);

  return (
    <FormContainer>
      <FormikProvider value={formik}>
        <FormHeader
          title={`Inverter ${number}`}
          deleteHandler={deleteSolarInverter}
          isLoading={isLoading}
          deleteDisabled={totalInverters === 1}
        />
        <AutoSaveForm>
          <FormLevelError error={formik.status?.formLevelError} />
          <Row>
            <Col md={4}>
              <ManufacturerAutocompleteField system="solar_inverter" number={number} />
            </Col>
            <Col md={4}>
              <InverterModelNumberAutocompleteField number={number} />
            </Col>
            <Col md={4}>
              <Select
                label="Inverter Type"
                name="inverter_type"
                choices={inverterTypeChoices}
                required={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <InputGroupField
                type="number"
                label="Inverter Efficiency"
                name="efficiency_rate"
                appendedText="%"
              />
            </Col>
            <Col md={4}>
              <NumberInput label="Wattage (watts)" name="wattage" />
            </Col>
            <Col md={4}>
              <NumberInput label="Warranty (years)" name="warranty" />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <TextInput label="Warranty offered by" name="warranty_source" />
            </Col>
          </Row>
        </AutoSaveForm>
      </FormikProvider>
    </FormContainer>
  );
};
export default Inverter;
