import ModelNumberAutocompleteField from "../Step2/ModelNumberAutocompleteField";
import { useFormikContext } from "formik";


const EnergyStorageModelNumberAutocompleteField = ({  system="solar_energy_storage" }) => {
  const { setFieldValue, submitForm } = useFormikContext();
  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setFieldValue("model_number", suggestionValue);
    setFieldValue("capacity", suggestion.es_capacity || "");
    setFieldValue("power_rating", suggestion.es_power || "");
    submitForm();
  };
  return (
    <ModelNumberAutocompleteField
      system={system}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};



export default EnergyStorageModelNumberAutocompleteField;
