import styles from "./SystemProfileInfo.module.css";
import React from "react";

const SystemProfileInfo = ({ address, ownershipType, utilityProvider, isSpecificUtility }) => {
  const utility = utilityProvider ? ` with utilities provided by ${utilityProvider}` : "";
  const average_utility_text = `Calculation for ${address} assuming this system is ${ownershipType} ${utility}.`

  const specific_utility_text = `The calculation for this system used the home’s specific utility plan and modeled
  energy load. That means a more accurate estimation of the value of the system.`

  return (
    <div className={styles.container}>
      {isSpecificUtility ? specific_utility_text : average_utility_text}
    </div>
  );
};

export default SystemProfileInfo;
