import styles from "./Card.module.css";

const Card = ({ children, className }) => {
  let cardCssClass = [styles.card];
  if (className) {
    cardCssClass.push(className);
  }
  return <div className={cardCssClass.join(" ")}>{children}</div>;
};

export default Card;
