import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import VerticallyCenteredModal from "@/components/UI/VerticallyCenteredModal";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./css/LoginForm.module.css";
import useWindowSize from "@/hooks/use-window-size";
import LoginFormContainer from "./LoginFormContainer";

function LoginForm({ show, setShow, breakpointForHidingLogin = 768 }) {
  const [width] = useWindowSize();
  return (
    <>
      {width >= breakpointForHidingLogin && (
        <div className={`col-xs-12 col-md-6 col-lg-5 ms-auto p-0 mb-5`}>
          <LoginFormContainer />
        </div>
      )}
      <VerticallyCenteredModal
        contentClassName={styles.bg_none}
        show={show}
        onHide={() => setShow(!show)}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className={styles["close-modal"]}
          onClick={() => setShow(false)}
        />
        <LoginFormContainer />
      </VerticallyCenteredModal>
    </>
  );
}

export default LoginForm;
