import React from "react";
import SignUpFormsContainer from "./SignUpFormContainer";
import VerticallyCenteredModal from "@/components/UI/VerticallyCenteredModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "@/hooks/use-window-size";
import styles from "../Login/css/LoginForm.module.css";
import { useSelector } from "react-redux";

const SignUp = ({ show, setShow }) => {
  const [width] = useWindowSize();
  const showSignUpWithPearl = useSelector(({ modal }) => modal.showSignUpWithPearl);
  return (
    <>
      {width > 991 && (
        <div className={`col-md-6`}>
          <SignUpFormsContainer />
        </div>
      )}
      <VerticallyCenteredModal
        contentClassName={styles.bg_none}
        show={show}
        onHide={() => setShow(!show)}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className={`${styles["close-modal"]} ${showSignUpWithPearl && "d-none"}`}
          onClick={() => setShow(false)}
        />
        <SignUpFormsContainer showSignUpWithPearl={showSignUpWithPearl} />
      </VerticallyCenteredModal>
    </>
  );
};
export default SignUp;
