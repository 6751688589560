/* eslint-disable */
// we are NOT using css modules intentionally since we need to use ids for the sidebar to work properly
import "@/components/AfterLogin/Sidebar/Sidebar.css";

import SidebarContent from "./SidebarContent";
import { useRef } from "react";
import { getCurrentYear } from "@/helpers/date";

const Sidebar = ({ urls, className }) => {
  const close = useRef(null);
  const closeHandler = () => {
    close.current.click();
  };
  const currentYear = getCurrentYear();

  return (
    <aside id="sidenav-open" className={className ?? ""}>
      <nav>
        <div className="sidebar-content">
          <SidebarContent urls={urls} closeSidebar={closeHandler} />
        </div>
        <div className="sidebar-footer">
          <div className="sidebar-copyright">
            © {currentYear}
            <br />
            Pearl Certification
          </div>
        </div>
      </nav>

      <a
        ref={close}
        href="#"
        id="sidenav-close"
        title="Close Menu"
        aria-label="Close Menu"
      />
    </aside>
  );
};

export default Sidebar;
