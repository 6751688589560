import WizardSteps from "@/components/Wizard/WizardSteps";
import DefaultResults from "@/components/ResultDefault/DefaultResults";
import AppraiserResults from "@/components/ResultAppraiser/AppraiserResults";
import { usePerms } from "@/hooks/use-perms";

const CalculationResults = () => {
  const { hasAppraiserPerm } = usePerms();

  return (
    <>
      <WizardSteps />
      {hasAppraiserPerm ? <AppraiserResults /> : <DefaultResults />}
    </>
  );
};

export default CalculationResults;
