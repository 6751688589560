import React from "react";
import styles from "./Login.module.css";
import { EmailInput, PasswordInput } from "@/components/UI/FormFields/Inputs";
import { Form, useFormikContext } from "formik";
import SubmitButton from "@/components/UI/SubmitButton";
import { Link } from "react-router-dom";

function Login() {
  const formik = useFormikContext();

  const loginHandler = (event) => {
    event.preventDefault();
    formik.submitForm();
  };

  return (
    <Form onSubmit={loginHandler}>
      <EmailInput name="email" label="Email Address" shadow={false} />
      <PasswordInput name="password" label="Password" shadow={false} />
      <SubmitButton
        variant="primary"
        className={`mb-2 btn-block ${styles.button}`}
        label="Login"
        isLoading={formik.isSubmitting}
      />
      <div className="d-flex justify-content-between">
        <Link to="/appraiser-signup" className={styles["text-danger"]}>
          Appraiser Signup
        </Link>
        <a
          href={process.env.REACT_APP_CERT_APP_URL + "/password_reset/"}
          className={styles["text-danger"]}
          target="_blank"
          rel="noreferrer"
        >
          Forgot password?
        </a>
      </div>
    </Form>
  );
}

export default Login;
