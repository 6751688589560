import styles from "./SpecificUtility.module.css";
import Select from "@/components/UI/FormFields/Select";
import {Col, Row} from "react-bootstrap";
import Spinner from "@/components/UI/Spinner";
import {useState} from "react";
import {useFormikContext} from "formik";

const SpecificUtility = (props) => {
  const {initialValues, setFieldValue} = useFormikContext();
  const initialProviderName = initialValues.specific_utility_plan_provider
  const initialPlanUri = initialValues.specific_utility_plan_uri
  const {isLoading, data} = props.UtilityProvidersObj;

  const getPlanChoicesByProviderName = (providerName) => {
    if (providerName == "") return []
    const selectedProviderData = data?.find(({name}) => name === providerName) || {}
    return selectedProviderData && selectedProviderData?.plans ?
      selectedProviderData?.plans.map((plan) => ({
        label: plan.is_default ? `${plan.name} (default)` : plan.name,
        value: plan.external_id,
        uri: plan.uri,
        default: plan.is_default
      }))
      :
      []
  }

  const initialPlanChoices = getPlanChoicesByProviderName(initialProviderName)
  const [planChoices, setPlanChoices] = useState(initialPlanChoices);
  const [selectedPlanUri, setSelectedPlanUri] = useState(initialPlanUri);


  const providerChoices = data
    ? data.map((provider) => ({
      label: provider.name,
      value: provider.name,
    }))
    : [];

  const handleUtilityProviderSelect = (selectedProviderName) => {
    const planChoices = getPlanChoicesByProviderName(selectedProviderName)
    setPlanChoices(planChoices)

    const defaultChoice = planChoices.find((plan) => plan.default)
    if (defaultChoice && defaultChoice?.value) {
      setFieldValue("specific_utility_plan_external_id", defaultChoice.value)
    }
  }

  const handlePlanSelect = (selectedPlanValue) => {
    const selectedPlan = planChoices.find(({value}) => value === selectedPlanValue)
    setSelectedPlanUri(selectedPlan.uri)
  }

  return (
    <>
      <Spinner isLoading={isLoading}/>
      <Row className={styles["specific_utility-container"]}>
        <Col md={4}>
          <Select
            label="Utility Provider"
            placeholder="Select Utility Provider"
            name="specific_utility_plan_provider"
            choices={providerChoices}
            handleSelect={handleUtilityProviderSelect}
            required={true}
          />
        </Col>
        <Col md={4} className={styles["hiden-label-container"]}>
          <Select
            label="Utility Plan"
            placeholder="Select Plan"
            name="specific_utility_plan_external_id"
            choices={planChoices}
            handleSelect={handlePlanSelect}
            required={true}
          />
        </Col>
        {selectedPlanUri && (
          <Col md={4} className={styles["link-container"]}>
            <a className={styles["link"]} rel="noreferrer" target="_blank" href={selectedPlanUri}>
              Preview Plan
            </a>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SpecificUtility;
