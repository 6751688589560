import styles from "./Suggestions.module.css";

const Suggestions = ({
  suggestions,
  renderSuggestion,
  suggestionClickHandler,
  header = "",
}) => {
  const showHeader = !!header && suggestions.length > 0;
  return (
    <div className={styles.container}>
      {showHeader && <div className={styles.header}>{header}</div>}
      {suggestions.map((suggestion, index) => (
        <div
          className={styles.suggestion}
          onClick={() => suggestionClickHandler(suggestion)}
          key={index}
        >
          {renderSuggestion(suggestion)}
        </div>
      ))}
    </div>
  );
};

export default Suggestions;
