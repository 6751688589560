import styles from "./SystemProfileTable.module.css";

const SystemProfileTable = ({
  systemSize,
  electricityRate,
  inverterType,
  panelManufacturer,
  arrayTypes,
  installationCost,
  isSpecificUtility,
  specificUtilityPlanName,
}) => {
  const utilityPlanCell = isSpecificUtility ?
    <>
      <td>Utility Plan</td>
      <td>{specificUtilityPlanName}</td>
    </>
    :
    <>
      <td>Electricity Rate</td>
      <td>{electricityRate} $/kWh</td>
    </>

  return (
    <table className={`table ${styles.table}`}>
      <thead>
        <tr>
          <th>Your Home</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>System Size</td>
          <td>{systemSize} kW</td>
        </tr>
        <tr>
          {utilityPlanCell}
        </tr>
        <tr>
          <td>Inverter Type</td>
          <td>{inverterType}</td>
        </tr>
        <tr>
          <td>Panel Manufacturer</td>
          <td>{panelManufacturer}</td>
        </tr>
        <tr>
          <td>Array Types</td>
          <td>{arrayTypes}</td>
        </tr>
        <tr>
          <td>Installation Cost</td>
          <td>{installationCost && `$${installationCost}`}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SystemProfileTable;
