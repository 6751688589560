import React from "react";
import styles from "./css/Promotion.module.css";

function Promotion({ firmName, firmLogo, sunPowerGain }) {
  return (
    <div className={`row ${styles.container}`}>
      <div className={`col-xs-12 col-sm-2 ${styles.brand}`}>
        <div className={styles.circle}>
          {firmLogo && <img src={firmLogo} alt="logo" />}
        </div>
      </div>
      <div className={`col-xs-12 col-sm-10 ${styles.section}`}>
        <h4>
          {sunPowerGain.hasSunPower
            ? `This SunPower system may add ${sunPowerGain.actualSystemValueToShow} to your home, which is
            ${sunPowerGain.gainPercentage}% higher than an average solar system.`
            : `${firmName} certifies your solar system giving you the most value for your installation.`}
        </h4>
        <p>
          {firmName} provides third-party certification from Pearl Certification,
          providing you with the most value for your solar installation. Only solar
          installations which are properly documented can increase the value of your home
          when you go to sell or refinance.
        </p>
      </div>
    </div>
  );
}

export default Promotion;
