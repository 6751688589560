import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useNavigate } from "react-router-dom";
import useEquityAppApi from "./use-equity-app-api";
import { usePerms } from "@/hooks/use-perms";

const initial = {
  id: "",
  low_value: "$$$$$",
  expected_value: "$$$$$",
  high_value: "$$$$$",
  created_at: new Date().toISOString(),
};
export const useSolarValuation = (homeId) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId, "solarValuation"],
    async () => {
      const response = await client.get(`/homes/${homeId}/solar_valuation/latest/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      initialData: initial,
      initialStale: true,
    }
  );
};

export const useSolarSunPowerEstimate = (homeId, enabled) => {
  const client = useEquityAppApi();

  return useQuery(
    ["homes", homeId, "solarEstimation"],
    async () => {
      const response = await client.get(`/homes/${homeId}/sunpower_estimate/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      initialData: { is_sunpower: false, expected_value: 0 },
      initialStale: true,
      enabled: enabled,
    }
  );
};

export const useCreateSolarValuation = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasAppraiserPerm } = usePerms();
  const historical_rates =  !hasAppraiserPerm;

  return useMutation(() => {
      return client.post(`/homes/${homeId}/solar_valuation/?historical_rates=${historical_rates}`)
    }, {
    onError: (error) => {
      handleQueryError(error);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["homes", homeId, "solarValuation"], response.data);
      navigate(`/homes/${homeId}/results`);
    },
  });
};

const initialData = {
  address: "",
  firm_name: "The contractor",
  firm_logo: "",
  ownership: "",
  utility_provider: "",
  system_size: "",
  electricity_rate: "",
  inverter_type: "",
  panel_manufacturer: "",
  array_types: "",
  installation_cost: "",
  is_specific_utility: "",
  specific_utility_name: "",

};
export const useSolarValuationInputs = (homeId) => {
  const client = useEquityAppApi();

  return useQuery(
    ["homes", homeId, "solarValuationInputs"],
    async () => {
      const response = await client.get(`/homes/${homeId}/result_inputs/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      initialData: initialData,
      initialStale: true,
    }
  );
};

export const useSolarSystemYearlyValue = (homeId, valuationId, exportTable = false) => {
  const client = useEquityAppApi();
  const url = `/homes/${homeId}/solar_valuation/${valuationId}/yearly_value/`;

  return useQuery(
    ["homes", homeId, "solarValuation", valuationId, "yearlyValue", exportTable],
    async () => {
      let conf;
      if (exportTable) {
        conf = { params: { response_type: "csv" }, responseType: "blob" };
      }
      const response = await client.get(url, conf);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      // the query is disabled until we have a valuationId
      // also, it is disabled for the export button since it should be triggered
      // on click only
      enabled: !!valuationId && !exportTable,
    }
  );
};

export const useSolarSystemYearlyValueChart = (homeId, valuationId) => {
  const client = useEquityAppApi();
  const url = `/homes/${homeId}/solar_valuation/${valuationId}/yearly_value_chart/`;

  return useQuery(
    ["homes", homeId, "solarValuation", valuationId, "yearlyValueChart"],
    async () => {
      const response = await client.get(url);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      enabled: !!valuationId,
    }
  );
};
