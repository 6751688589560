import React from "react";
import styles from "./css/EstimateEquValueMultipleRates.module.css";
import EstimateEquValueMultipleRatesDesktop from "./EstimateEquValueMultipleRatesDesktop";
import EstimateEquValueMultipleRatesMobile from "./EstimateEquValueMultipleRatesMobile";
import EstimateEquValueMultipleRatesInfo from "./EstimateEquValueMultipleRatesInfo";

const EstimateEquValueMultipleRates = ({ data }) => {
  const rates = data.historical_rates
  const asOf = new Date(data.created_at);
  const displayText = asOf.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
  });
  return (
    <div>
      <div className={styles.title}>
        <div>Estimated Equity Value Ranges</div>
        <div>as of {displayText}</div>
      </div>
      <EstimateEquValueMultipleRatesDesktop rates={rates} />
      <EstimateEquValueMultipleRatesMobile rates={rates} />
      <EstimateEquValueMultipleRatesInfo />
    </div>
  );
};

export default EstimateEquValueMultipleRates;
