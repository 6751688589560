import WizardSteps from "@/components/Wizard/WizardSteps";
import Card from "@/components/UI/Card";
import Inverters from "@/components/Wizard/Step3/Inverters";
import NavButtons from "@/components/Wizard/NavButtons";
import { useNavigate, useParams } from "react-router-dom";
import LearnMoreAboutPearl from "@/components/Wizard/common/LearnMoreAboutPearl";
import { usePerms } from "@/hooks/use-perms";

const CalculationStep3 = () => {
  const navigate = useNavigate();
  const { homeId } = useParams();
  const { hasAppraiserPerm } = usePerms();

  const backHandler = () => {
    navigate(`/homes/${homeId}/step2`);
  };
  const nextHandler = () => {
    navigate(`/homes/${homeId}/step4`);
  };

  return (
    <>
      <WizardSteps />
      <Card>
        <p>
          Please enter the details of the proposed or installed inverters here. The only
          required field is inverter type.
          {hasAppraiserPerm && <LearnMoreAboutPearl />}
        </p>
        <Inverters />
      </Card>
      <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />
    </>
  );
};

export default CalculationStep3;
