import styles from "./LearnMoreAboutPearl.module.css";

const LearnMoreAboutPearl = () => {
  return (
    <a
      className={styles.link}
      href={
        process.env.REACT_APP_RESOURCES_URL +
        "/en_US/appraiser-resources/methodology-for-calculator"
      }
      target="_blank"
      rel="noreferrer"
    >
      Learn more about the science behind the Pearl Solar Equity Calculator.
    </a>
  );
};

export default LearnMoreAboutPearl;
