import React from "react";
import partner1 from "@/assets/imgs/partners/1.png";
import partner2 from "@/assets/imgs/partners/2.png";
import partner3 from "@/assets/imgs/partners/3.png";
import partner4 from "@/assets/imgs/partners/4.png";
import partner5 from "@/assets/imgs/partners/5.png";
import styles from "./css/Partner.module.css";
function Partners() {
  return (
    <>
      <div className={styles["partner-text"]}>
        <p>
          We partner with the most respected names in energy efficiency, solar, and real
          estate.
        </p>
      </div>
      <div className={styles["partner-imgs"]}>
        <img src={partner1} alt="partner" />
        <img src={partner2} alt="partner" />
        <img src={partner3} alt="partner" />
        <img src={partner4} alt="partner" />
        <img src={partner5} alt="partner" />
      </div>
    </>
  );
}

export default Partners;
