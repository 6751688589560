import Autosuggest from "react-autosuggest";
import { useField } from "formik";
import styles from "./FormFieldWithShadow.module.css";
import { Form } from "react-bootstrap";
import RequiredAsterisk from "./RequiredAsterisk";
import theme from "./AutocompleteFieldTheme.module.css";

const AutocompleteField = ({
  label,
  helpText,
  name,
  shadow = true,
  required = false,
  id = null,
  inputProps = {},
  autosuggestProps = {}, // other than inputProps, id, theme
  autosuggestTheme = null,
}) => {
  const [field, meta] = useField({ name });

  // add the necessary props for formik and autosuggest to work properly
  const allInputProps = { ...field, ...inputProps };
  const isInvalid = meta.touched && !!meta.error;
  const shadowCssClass = shadow ? styles.shadow : "";
  const fieldId = id ? id : `id-${name}`;
  return (
    <Form.Group controlId={fieldId} className={`form-group ${shadowCssClass}`}>
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
      </Form.Label>
      <Autosuggest
        inputProps={allInputProps}
        id={fieldId}
        {...autosuggestProps}
        theme={autosuggestTheme || theme}
      />
      {helpText && (
        <Form.Text id={`help-text-${fieldId}`} muted>
          {helpText}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid" className={isInvalid ? "d-block" : ""}>
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default AutocompleteField;
