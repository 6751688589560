import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useEffect } from "react";
import { createDateFromString } from "@/helpers/date";
import useEquityAppApi from "@/hooks/use-equity-app-api";

const useSolarPanels = (homeId, options) => {
  const client = usePearlApi();
  return useQuery(
    ["homes", homeId, "solarPanels"],
    async () => {
      const response = await client.get(`/homes/${homeId}/solar_panels_and_arrays/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      ...options,
    }
  );
};

export const useSolarPanelIds = (homeId) => {
  const { data } = useSolarPanels(homeId);
  return data?.length ? data.map(({id}) => id) : {};
};

export const useCreateSolarPanel = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await client.post(
        `/homes/${homeId}/solar_panels_and_arrays/`,
        data
      );
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["homes", homeId, "solarPanels"]);
      },
    }
  );
};

export const useUpdateSolarPanel = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();
  const updatedCachedAssets = (oldAssets, asset) => {
    const updatedAssets = [];
    for (const oldAsset of oldAssets) {
      updatedAssets.push(asset.id === oldAsset.id ? asset : oldAsset);
    }
    return updatedAssets;
  };

  return useMutation(
    ({ assetId, data }) => {
      const updateUrl = `/homes/${homeId}/solar_panels_and_arrays/${assetId}/`;
      return client.post(updateUrl, data);
    },
    {
      onSuccess: ({ data }) =>
        queryClient.setQueryData(["homes", homeId, "solarPanels"], (oldData) =>
          updatedCachedAssets(oldData, data)
        ),
    }
  );
};

export const useGenerateSolarPanelBills = (homeId) => {
  const client = useEquityAppApi();
  const queryClient = useQueryClient();
  const updatedCachedAssets = (oldAssets, asset) => {
    const updatedAssets = [];
    for (const oldAsset of oldAssets) {
      updatedAssets.push(asset.id === oldAsset.id ? asset : oldAsset);
    }
    return updatedAssets;
  };
  return useMutation(
    ({ assetId, data }) => {
      const updateUrl = `/homes/${homeId}/solar_panels_and_arrays/${assetId}/generate_bills/`;
      return client.patch(updateUrl, data);
    },
    {
      onSuccess: ({ data }) =>
        queryClient.setQueryData(["homes", homeId, "solarPanels"], (oldData) =>
          updatedCachedAssets(oldData, data)
        ),
    }
  );
};

export const useSolarPanel = (homeId) => {
  const { data, ...rest } = useSolarPanels(homeId);
  const solarPanel = data?.length ? data[0] : null;
  return { data: solarPanel, ...rest };
};

const initialValues = {
  ownership: "",
  financing_type: "",
  utility_company: "",
  utility_kwh_charge: "",
};
export const useSolarPanelData = (homeId) => {
  // extract only needed data
  const { isLoading, isSuccess, data: existingPanel } = useSolarPanel(homeId);
  const asset = existingPanel || initialValues;
  const data = extractPanelData(asset);
  return { isLoading, isSuccess, data };
};
export const useGetOrCreateSolarPanel = (homeId) => {
  const {
    isLoading: isLoadingGetSolarPanel,
    isSuccess: isSuccessGetSolarPanel,
    data: existingPanel,
  } = useSolarPanel(homeId);
  const {
    mutate: createSolarPanel,
    isLoading: isLoadingCreateSolarPanel,
    data: createdPanel,
  } = useCreateSolarPanel(homeId);
  const isLoading = isLoadingGetSolarPanel || isLoadingCreateSolarPanel;
  const assetId = existingPanel?.id || createdPanel?.id;
  const isSuccess = !!assetId;
  const asset = existingPanel || createdPanel || initialValues;
  const data = extractPanelData(asset);

  useEffect(() => {
    if (isSuccessGetSolarPanel && !assetId && !isLoadingCreateSolarPanel) {
      // assets created through the equity app should be set as unverified
      createSolarPanel({ verified: false });
    }
  }, [
    isSuccessGetSolarPanel,
    assetId,
    isLoadingCreateSolarPanel,
    createSolarPanel,
    homeId,
  ]);

  return { isLoading, isSuccess, data, assetId };
};

const extractPanelData = (solarPanel) => {
  return {
    ownership: solarPanel.ownership || "",
    utility_plan_type: solarPanel.utility_plan_type || "",
    financing_type: solarPanel.financing_type || "",
    utility_company: solarPanel.utility_company || "",
    utility_kwh_charge: solarPanel.utility_kwh_charge || "",
    specific_utility_plan_provider: solarPanel.specific_utility_plan_provider || "",
    specific_utility_plan_external_id:
      (solarPanel.specific_utility_plan &&
        solarPanel.specific_utility_plan.external_id) ||
      "",
    specific_utility_plan_name: solarPanel.specific_utility_plan_name || "",
    specific_utility_plan_uri: solarPanel.specific_utility_plan_uri || "",
    specific_utility_plan_is_default: solarPanel.specific_utility_plan_is_default || "",
    is_specific_utility_plan: solarPanel.utility_plan_type == "specific_utility_plan",
    energy_bills: solarPanel.energy_bills || [],
    is_energy_storage_included: solarPanel.is_energy_storage_included ? true : false,
  };
};

const initialData = [
  {
    manufacturer: "",
    model_number: "",
    efficiency_rating: "",
    system_size: "",
    array_type: "",
    installation_date: "",
    azimuth: "",
    tilt_slope: "",
    soiling: "",
    shading: "",
    snow: "",
    mismatch: "",
    wiring: "",
    connections: "",
    light_induced_degradation: "",
    nameplate_rating: "",
    availability: "",
    warranty: "",
    installation_cost: "",
  },
];

export const useSolarArrays = (homeId) => {
  const { data, ...rest } = useSolarPanels(homeId, { initialData, initialStale: true });
  // extract only the data we need in the arrays step
  const arrays = [];
  for (const obj of data) {
    let installDate = "";
    if (obj.installation_date) {
      installDate = createDateFromString(`${obj.installation_date}-01`);
    }
    const array = {
      id: obj.id || 0,
      manufacturer: obj.manufacturer || "",
      model_number: obj.model_number || "",
      efficiency_rating: obj.efficiency_rating || "",
      system_size: obj.system_size || "",
      array_type: obj.array_type || "",
      installation_date: installDate,
      azimuth: obj.azimuth || "",
      tilt_slope: obj.tilt_slope || "",
      soiling: obj.soiling || "",
      shading: obj.shading || "",
      snow: obj.snow || "",
      mismatch: obj.mismatch || "",
      wiring: obj.wiring || "",
      connections: obj.connections || "",
      light_induced_degradation: obj.light_induced_degradation || "",
      nameplate_rating: obj.nameplate_rating || "",
      availability: obj.availability || "",
      warranty: obj.warranty || "",
      installation_cost: obj.installation_cost || "",
    };
    arrays.push(array);
  }
  return { data: arrays, ...rest };
};

export const useDeleteSolarPanel = (homeId, assetId) => {
  const queryClient = useQueryClient();
  const client = usePearlApi();
  const url = `/homes/${homeId}/solar_panels_and_arrays/${assetId}/`;
  return useMutation(() => client.delete(url), {
    onSuccess: () => {
      queryClient.invalidateQueries(["homes", homeId, "solarPanels"]);
    },
    onError: (error) => {
      handleQueryError(error);
    },
  });
};
