import styles from "./InstallationCostTooltip.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@/components/UI/Tooltip";

const InstallationCostTooltip = () => {
  const content = (
    <div>
      Complete installation cost minus any applicable rebates or credits. If you are
      estimating a net installation cost you may want to use $2.71 per watt which is NREL
      calculated cost in this study:
      <a
        className={styles.link}
        href="https://www.nrel.gov/docs/fy21osti/77324.pdf"
        target="_blank"
        rel="noreferrer"
      >
        https://www.nrel.gov/docs/fy21osti/77324.pdf
      </a>
    </div>
  );

  return (
    <Tooltip content={content} interactive={true}>
      <span className="ms-2">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    </Tooltip>
  );
};

export default InstallationCostTooltip;
