import { FormikProvider, useFormik } from "formik";
import styles from "./common/LoginNoNetwork.module.css";
import React, { useState } from "react";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import * as Yup from "yup";
import LoginNoNetwork from "./common/LoginNoNetwork";
import getTokens from "@/helpers/login/login";
import { authActions } from "@/store/auth-slice";
import { useDispatch } from "react-redux";

const initialValues = {
  email: "",
  password: "",
};
const schema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});
const LoginFormContainerNoNetwork = () => {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values, { setSubmitting }) => {
      getTokens(values.email, values.password)
        .then((response) => {
          if (response.error) {
            setError(response.error);
          }
          if (response.data) {
            dispatch(authActions.login(response.data));
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  return (
    <div className={`border-0 ${styles.card}`}>
      <div>
        <h5 className={styles["title"]}>
          Sign in
        </h5>
        <FormikProvider value={formik}>
          <FormLevelError error={error} />
          <LoginNoNetwork />
        </FormikProvider>
      </div>
    </div>
  );
};
export default LoginFormContainerNoNetwork;
