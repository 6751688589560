/* eslint-disable */
// we are NOT using css modules intentionally since we need to use ids for the sidebar to work properly
import "./Sidebar.css";

import SidebarContent from "./SidebarContent";
import { useRef } from "react";
import { getCurrentYear } from "@/helpers/date";
import packageJson from '../../../../package.json';

const Sidebar = () => {
  const close = useRef(null);
  const closeHandler = () => {
    close.current.click();
  };
  const currentYear = getCurrentYear();

  return (
    <aside id="sidenav-open">
      <nav>
        <div className="sidebar-content">
          <SidebarContent closeSidebar={closeHandler} />
        </div>
        <div className="sidebar-footer">
          <div className="sidebar-copyright">
            © {currentYear}
            <br />
            Pearl Certification
            <br />
            Version: {packageJson.version}
          </div>
        </div>
      </nav>
      <a
        ref={close}
        href="#"
        id="sidenav-close"
        title="Close Menu"
        aria-label="Close Menu"
      />
    </aside>
  );
};

export default Sidebar;
