import { faExclamationTriangle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./css/Statistic.module.css";

function Statistic({ number, icon, text, status }) {
  if (status === "loading") {
    number = <FontAwesomeIcon icon={faSpinner} spin className={styles.loading} />;
  } else if (status === "error") {
    number = <FontAwesomeIcon icon={faExclamationTriangle} className={styles.error} />;
  } else {
    number = number.toLocaleString();
  }
  return (
    <div className={styles.container}>
      <div className={`${styles.number} ${status !== "success" && styles.center}`}>
        {number}
      </div>
      <div>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
        <span className={styles.text}>{text}</span>
      </div>
    </div>
  );
}

export default Statistic;
