import { Outlet } from "react-router-dom";
import React from "react";
import HeaderNoNetwork from "@/components/Login/HeaderNoNetwork";
import styles from "./LoginLayoutNoNetwork.module.css";
import AnonymousRoute from "@/routing/AnonymousRoute";
import Footer from "@/components/Login/Footer";


function LoginLayoutNoNetwork() {
  return (
    <AnonymousRoute>
      <div className={styles["page-container"]}>
        <div className={styles["bg_img"]} />
        <main className={styles.main}>
          <HeaderNoNetwork  />
          <div className={styles["page-content"]}>
            <Outlet />
          </div>
        </main>
      </div>
      <Footer />
    </AnonymousRoute>
  );
}

export default LoginLayoutNoNetwork;
