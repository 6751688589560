import { useField } from "formik";
import { Form } from "react-bootstrap";
import styles from "./FormFieldWithShadow.module.css";
import RequiredAsterisk from "./RequiredAsterisk";

const FormField = ({
  label,
  helpText,
  name,
  shadow = true,
  required = false,
  id = null,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const shadowCssClass = shadow ? styles.shadow : "";
  const fieldId = id ? id : `id-${name}`;
  return (
    <Form.Group controlId={fieldId} className={`form-group ${shadowCssClass}`}>
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
      </Form.Label>
      <Form.Control isInvalid={meta.touched && !!meta.error} {...field} {...props} />
      {helpText && (
        <Form.Text id={`help-text-${fieldId}`} muted>
          {helpText}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormField;
