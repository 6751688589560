import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useEffect } from "react";

export const useSolarInverters = (homeId, options) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId, "solarInverters"],
    async () => {
      const response = await client.get(`/homes/${homeId}/solar_inverters/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      ...options,
    }
  );
};

export const useCreateSolarInverter = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await client.post(`/homes/${homeId}/solar_inverters/`, data);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["homes", homeId, "solarInverters"]);
      },
    }
  );
};

const initialData = [
  {
    id: 0,
    manufacturer: "",
    model_number: "",
    efficiency_rate: "",
    inverter_type: "",
    wattage: "",
    warranty: "",
    warranty_source: "",
  },
];

export const useGetOrCreateSolarInverters = (homeId) => {
  // isFetched is set to false initially and becomes true once the data
  // is fetched from the server at least once
  const { data: existingSolarInverters, isFetched: isFetchedGetSolarInverters } =
    useSolarInverters(homeId, { initialData: initialData, initialStale: true });
  const { data: createdSolarInverter, mutate: createSolarInverter } =
    useCreateSolarInverter(homeId);

  // For initial data, the inverter id is 0
  const haveExistingInverters =
    existingSolarInverters?.length && existingSolarInverters[0]?.id;
  const assets = getInverters(existingSolarInverters, createdSolarInverter);

  useEffect(() => {
    if (
      isFetchedGetSolarInverters &&
      !haveExistingInverters &&
      !createdSolarInverter?.id
    ) {
      createSolarInverter({ verified: false });
    }
  }, [
    isFetchedGetSolarInverters,
    haveExistingInverters,
    createdSolarInverter,
    createSolarInverter,
  ]);

  return assets;
};

const getInverters = (existingSolarInverters, createdSolarInverter) => {
  let assets = [];
  if (existingSolarInverters?.length) {
    assets = existingSolarInverters;
  } else if (createdSolarInverter?.id) {
    assets = [createdSolarInverter];
  } else {
    // this prevents the sudden disappearance and then appearance of the form
    // when the home is first created (time it takes to create the first inverter)
    assets = initialData;
  }
  return extractData(assets);
};

const extractData = (assets) => {
  const inverters = [];
  for (const inverter of assets) {
    inverters.push({
      id: inverter.id,
      manufacturer: inverter.manufacturer || "",
      model_number: inverter.model_number || "",
      efficiency_rate: inverter.efficiency_rate || "",
      inverter_type: inverter.inverter_type || "",
      wattage: inverter.wattage || "",
      warranty: inverter.warranty || "",
      warranty_source: inverter.warranty_source || "",
    });
  }
  return inverters;
};

export const useUpdateSolarInverter = (homeId) => {
  const client = usePearlApi();

  return useMutation(async ({ assetId, data }) => {
    const response = await client.post(
      `/homes/${homeId}/solar_inverters/${assetId}/`,
      data
    );
    return response.data;
  });
};

export const useDeleteSolarInverter = (homeId, assetId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    () => client.delete(`/homes/${homeId}/solar_inverters/${assetId}/`),
    {
      onError: (error) => {
        handleQueryError(error);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["homes", homeId, "solarInverters"]);
      },
    }
  );
};
