import FormContainer from "../common/FormContainer";
import FormHeader from "../common/FormHeader";
import {
  useGetOrCreateEnergyStorage,
  useUpdateEnergyStorage,
} from "@/hooks/use-energy-storage";
import DatepickerMonthField from "@/components/UI/FormFields/DatepickerMonthField";
import { FormikProvider, useFormik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import React from "react";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import { Col, Row } from "react-bootstrap";
import { getCachedQuery } from "../common/helpers";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useParams } from "react-router-dom";
import { NumberInput } from "@/components/UI/FormFields/Inputs";
import { useQueryClient } from "react-query";
import RadioButtonGroup from "@/components/UI/FormFields/RadioGroup";
import ManufacturerAutocompleteField from "../Step2/ManufacturerAutocompleteField";
import EnergyStorageModelNumberAutocompleteField from "./EnergyStorageModelNumberAutocompleteField";
const batteryTypeChoices = [
  { label: "Lithium-ion", value: "lithium_ion" },
  { label: "Lithium-ion Polymer", value: "lithium_ion_polymer" },
  { label: "Lead Acid", value: "lead_acid" },
  { label: "Lead Calcium", value: "lead_calcium" },
  { label: "AGM", value: "agm" },
  { label: "GEL", value: "gel" },
];

const EnergyStorageForm = () => {
  const { homeId } = useParams();
  const queryClient = useQueryClient();
  const { isLoading, data, assetId } = useGetOrCreateEnergyStorage(homeId);
  const { mutateAsync: updateEnergyStorage } = useUpdateEnergyStorage(homeId);
  const submitEnergyStorage = async (
    assetData,
    { setStatus, setErrors }
  ) => {
    const oldAssetData = getCachedQuery(
      queryClient,
      ["homes", homeId, "energyStorage"],
      assetId
    );
    const newAssetData = { ...oldAssetData, ...assetData };
    try {
      await updateEnergyStorage({ assetId, data: newAssetData });
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: submitEnergyStorage,
  });
  return (
    <FormContainer>
      <FormikProvider value={formik} >
        <FormHeader
          title="Energy Storage"
          isLoading={isLoading}
          deleteHidden={true}
        />
        <AutoSaveForm dateFields={["installation_date"]}>
          <FormLevelError error={formik.status?.formLevelError} />
          <Row className="align-items-start">
            <Col md={3}>
            <ManufacturerAutocompleteField
              system="solar_energy_storage"
              number={1}
              label="Manufacturer" 
            />
            </Col>
            <Col md={3}>
            <EnergyStorageModelNumberAutocompleteField  />
            </Col>
            <Col md={6}>
              <NumberInput
                label="Manufacturer Warranty Term (years)"
                name="installation_warranty_term"
              />
            </Col>
          </Row>
          <Row>
            <RadioButtonGroup
              label="Battery Type"
              name="type"
              choices={batteryTypeChoices}
            />
          </Row>
          <Row className="align-items-start">
            <Col md={4}>
              <NumberInput label="Storage Capacity (kWh)" name="capacity" required={true} />
            </Col>
            <Col md={4}>
              <NumberInput label="Power Rating (kW)" name="power_rating" required={true} />
            </Col>
            <Col md={4}>
              <DatepickerMonthField
                label="Installed Month"
                name="installation_date"
              />
            </Col>
          </Row>
        </AutoSaveForm>
      </FormikProvider>
    </FormContainer>
  );
};

export default EnergyStorageForm;
