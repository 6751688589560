import { Col, Row } from "react-bootstrap";
import { NumberInput } from "@/components/UI/FormFields/Inputs";
import InstallationCostTooltip from "./InstallationCostTooltip";

const InstallationCostField = ({ show }) => {
  const cssClasses = show ? "d-block" : "d-none";

  return (
    <Row className={cssClasses}>
      <Col md={4}>
        <NumberInput
          label={
            <>
              Net Installation Cost <InstallationCostTooltip />
            </>
          }
          name="installation_cost"
        />
      </Col>
    </Row>
  );
};

export default InstallationCostField;
