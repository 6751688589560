import { useField } from "formik";
import { Form, InputGroup } from "react-bootstrap";
import InfoBubble from "./InfoBubble";
import "./InputGroupWithShadow.css";
import RequiredAsterisk from "./RequiredAsterisk";

const InputGroupField = ({
  prependedText,
  appendedText,
  label,
  helpText,
  name,
  shadow = true,
  required = false,
  tooltipContent = null,
  id = null,
  ...props
}) => {
  const [field, meta] = useField({ name, ...props });
  const shadowCssClass = shadow ? "input-group-with-shadow" : "";
  const fieldId = id ? id : `id-${name}`;
  return (
    <Form.Group controlId={fieldId} className={`form-group ${shadowCssClass}`}>
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
        {tooltipContent && <InfoBubble content={tooltipContent} />}
      </Form.Label>
      <InputGroup hasValidation>
        {prependedText && (
          <InputGroup.Text id={`prepended-text-${fieldId}`}>
            {prependedText}
          </InputGroup.Text>
        )}
        <Form.Control
          isInvalid={meta.touched && !!meta.error}
          type="text"
          {...field}
          {...props}
        />
        {appendedText && (
          <InputGroup.Text id={`appended-text-${fieldId}`}>
            {appendedText}
          </InputGroup.Text>
        )}
        {helpText && (
          <Form.Text id={`help-text-${fieldId}`} muted>
            {helpText}
          </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default InputGroupField;
