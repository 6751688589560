import LoginFormNoNetwork from "@/components/Login/LoginFormNoNetwork.js";
import React from "react";
import MorePearlApps from "@/components/Login/MorePearlApps";

const Login = () => {
  return (
    <>
      <div className="row">
        <div className="row mx-2 col-12 px-0 align-items-center justify-content-center">
          <LoginFormNoNetwork />
        </div>
      </div>
      <MorePearlApps />
    </>
  );
};

export default Login;
