import WizardSteps from "@/components/Wizard/WizardSteps";
import NavButtons from "@/components/Wizard/NavButtons";
import Card from "@/components/UI/Card";
import { useNavigate, useParams } from "react-router-dom";
import CalculatingEquity from "@/components/Wizard/Step4/CalculatingEquity";
import { useCreateSolarValuation } from "@/hooks/use-solar-valuation";
import EnergyStorageForm from "@/components/Wizard/Step5/EnergyStorageForm";
import { useGetOrCreateSolarPanel } from "@/hooks/use-solar-panels";
import LearnMoreAboutPearl from "@/components/Wizard/common/LearnMoreAboutPearl";
import { usePerms } from "@/hooks/use-perms";

const CalculationStep5 = () => {
  const navigate = useNavigate();
  const { homeId } = useParams();
  const { hasAppraiserPerm } = usePerms();
  const { isSuccess, data } = useGetOrCreateSolarPanel(homeId);
  const { isLoading, mutate: createSolarValuation } = useCreateSolarValuation(homeId);

  const backHandler = () => {
    navigate(`/homes/${homeId}/step4`);
  };
  if (isSuccess && !data.is_energy_storage_included) {
    navigate(`/homes/${homeId}/step1`);
  }

  if (isLoading) {
    return (
      <>
        <WizardSteps />
        <Card>
          <CalculatingEquity />
        </Card>
      </>
    );
  }

  return (
    <>
      <WizardSteps />
      {isSuccess && data.is_energy_storage_included && (
        <Card>
          <p>
            Please enter the details about the any home batteries/energy storage devices
            here. This data will be used to assist in calculating the systems production
            over time.
            {hasAppraiserPerm && <LearnMoreAboutPearl />}
          </p>
          <EnergyStorageForm />
        </Card>
      )}
      <NavButtons onBackClick={backHandler} onNextClick={createSolarValuation} />
    </>
  );
};

export default CalculationStep5;
