import { Col, Row } from "react-bootstrap";
import React from "react";
import AdvancedFieldsAccordion from "../common/AdvancedFieldsAccordion";
import InputGroupField from "@/components/UI/FormFields/InputGroup";

const SystemLosses = () => {
  return (
    <AdvancedFieldsAccordion title="System Losses">
      <Row>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Soiling"
            name="soiling"
            placeholder="e.g. 2"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Shading"
            name="shading"
            placeholder="e.g. 3"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Snow"
            name="snow"
            placeholder="e.g. 0"
            appendedText="%"
            step="0.01"
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Mismatch"
            name="mismatch"
            placeholder="e.g. 2"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Wiring"
            name="wiring"
            placeholder="e.g. 2"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Connections"
            name="connections"
            placeholder="e.g. 0.5"
            appendedText="%"
            step="0.01"
          />
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Light-Induced Degradation"
            name="light_induced_degradation"
            placeholder="e.g. 1.5"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Nameplate Rating"
            name="nameplate_rating"
            placeholder="e.g. 1"
            appendedText="%"
            step="0.01"
          />
        </Col>
        <Col md={4}>
          <InputGroupField
            type="number"
            label="Availability"
            name="availability"
            placeholder="e.g. 3"
            appendedText="%"
            step="0.01"
          />
        </Col>
      </Row>
    </AdvancedFieldsAccordion>
  );
};

export default SystemLosses;
