import styles from "./CongratsNote.module.css";
import { usePerms } from "@/hooks/use-perms";

const CongratsNote = () => {
  const { hasAppraiserPerm } = usePerms();
  let title, text;
  if (hasAppraiserPerm) {
    title = "Leased and PPA systems";
    text = (
      <div className={styles.text}>
        Pearl does not generate an equity value for leased or power purchase agreements
        (PPAs). This is based on the guidelines set by Fannie Mae, Freddie Mac and the
        Federal Housing Administration for appraising solar.
        <br />
        <br />
        Fannie Mae and the Federal Housing Administration recognize that PV systems can
        add value to a home. However, there’s an important qualification: Both recognize
        the value of a PV system only if it is owned by the homeowner. If the system is
        leased from a third party, these institutions do not recognize that it will add
        value.
      </div>
    );
  } else {
    title = "Congratulations on deciding to add solar to your home!";
    text = (
      <div className={styles.text}>
        While a leased or PPA system will not add value to your home when you go to sell
        or refinance it will immediately provide your home with electricity generated from
        a renewable source. Through the equity calculator we do not capture additional
        data on leased or PPA systems. Please select owned or financed system to calculate
        an equity value.
      </div>
    );
  }

  return (
    <div className={styles.note}>
      <h2 className={styles.title}>{title}</h2>
      {text}
    </div>
  );
};

export default CongratsNote;
