import { FormikProvider, useFormik } from "formik";
import DatepickerField from "@/components/UI/FormFields/DatepickerField";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import React from "react";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import { Row } from "react-bootstrap";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useParams } from "react-router-dom";
import "./EnergyBillsFormGenerate.css";
import {useGenerateSolarPanelBills, useSolarPanelIds} from "@/hooks/use-solar-panels";
import { format } from 'date-fns';

const EnergyBillsGenerateForm = () => {
  const { homeId } = useParams();
  const { mutateAsync: generateSolarPanelBills } = useGenerateSolarPanelBills(homeId);
  const assetIds = useSolarPanelIds(homeId);
  const submitGenerateSolarPanelBills = async (
    { earliest_bill_date },
    { setStatus, setErrors }
  ) => {
    if (earliest_bill_date) {
      for (const assetId of assetIds) {
        try {
          const data = {earliest_bill_date: format(earliest_bill_date, 'yyyy-MM')}
          await generateSolarPanelBills({assetId, data});
        } catch (error) {
          handleMutationError(error, setStatus, setErrors);
        }
      }
    }
  };
  const formik = useFormik({
    initialValues: { earliest_bill_date: null },
    enableReinitialize: true,
    onSubmit: submitGenerateSolarPanelBills,
  });
  return (
    <>
      <FormikProvider value={formik}>
        <FormLevelError error={formik.status?.formLevelError} />
        <AutoSaveForm>
          <Row className="form-group-container">
            <DatepickerField
              label="Enter the earliest date of the bills to autopopulate the date fields below"
              name="earliest_bill_date"
              placeholderText="yyyy-MM"
              dateFormat="yyyy-MM"
              showMonthYearPicker
              required={false}
            />
          </Row>
        </AutoSaveForm>
      </FormikProvider>
    </>
  );
};
export default EnergyBillsGenerateForm;
