import React from "react";
import styles from "./css/EstimateEquValue.module.css";
import { usePerms } from "@/hooks/use-perms";

function EstimateEquValue({ data }) {
  const expectedValue = data.expected_value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  const { hasAppraiserPerm } = usePerms();
  let title = "Estimated discounted cash flow";
  if (!hasAppraiserPerm) {
    const asOf = new Date(data.created_at);
    const dateDisplay = asOf.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
    });
    title = `Estimated Equity Value as of ${dateDisplay}`;
  }

  return (
    <div className={styles.container}>
      <p>{title}</p>
      <h3>${expectedValue}</h3>
    </div>
  );
}

export default EstimateEquValue;
