import React from "react";
import arrowCircleLeft from "@/assets/icons/arrowCircleLeft.svg";
import logo from "@/assets/imgs/pearl-logo.png";
import styles from "./css/Footer.module.css";
import { getCurrentYear } from "@/helpers/date";

function Footer() {
  const currentYear = getCurrentYear();
  return (
    <div
      className={`w-100 col-12 bg_primary d-flex justify-content-between px-sm-5 align-items-end ${styles.footer}`}
    >
      <div className="text-white">
        <img className={styles.brand} src={logo} alt="brand" />
        <p>
          401 E. Water St. Site 2 <br />
          Charlottesville, VA 22900
          <br />
          (888) 557 - 5543
        </p>
        <p className="m-0">Copyright © {currentYear} Pearl Certification</p>
      </div>
      <a
        href={process.env.REACT_APP_BASE_URL}
        className={`btn btn-primary ${styles.button}`}
        target="_blank"
        rel="noreferrer"
      >
        <img className="me-1" src={arrowCircleLeft} alt="icon" /> Back to Home
      </a>
    </div>
  );
}

export default Footer;
