import Card from "@/components/UI/Card";
import SolarPanelForm from "./SolarPanelForm";
import NavButtons from "../NavButtons";
import { useNavigate, useParams } from "react-router-dom";
import { useReducer } from "react";
import HomeUpdate from "./HomeUpdate";

const Step1Part1 = ({ setPart, isSpecificUtilityPlan, isSuccess, hasAppraiserPerm }) => {
  const navigate = useNavigate();
  const { homeId } = useParams();

  const initialState = { ownedWithFinancing: false, owned: false, notOwned: false };
  const [state, dispatch] = useReducer(ownershipReducer, initialState);

  const backHandler = () => {
    navigate("/homes");
  };
  const nextHandler = () => {
    if (isSuccess) {
      if (isSpecificUtilityPlan) {
        setPart("energy_bills");
      } else if (hasAppraiserPerm) {
        setPart("2");
      } else {
        navigate(`/homes/${homeId}/step2`);
      }
    }
  };

  return (
    <>
      <Card className="mb-4">
        <HomeUpdate />
      </Card>
      <Card>
        <SolarPanelForm ownershipState={state} ownershipDispatch={dispatch} />
      </Card>
      {state.owned && <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />}
    </>
  );
};

const ownershipReducer = (state, action) => {
  if (action === "OWNED_WITH_FINANCING") {
    return {
      ownedWithFinancing: true,
      owned: true,
      notOwned: false,
    };
  } else if (action === "OWNED") {
    return {
      ownedWithFinancing: false,
      owned: true,
      notOwned: false,
    };
  } else if (action === "NOT_OWNED") {
    return {
      ownedWithFinancing: false,
      owned: false,
      notOwned: true,
    };
  } else if (action === "NO_ANSWER") {
    return {
      ownedWithFinancing: false,
      owned: false,
      notOwned: false,
    };
  }
  return state;
};

export default Step1Part1;
