import React from "react";
import { Form as FormikForm, Formik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import TextArea from "@/components/UI/FormFields/TextArea";
import { TextInput } from "@/components/UI/FormFields/Inputs";

import styles from "./ContactForm.module.css";
import SubmitButton from "@/components/UI/SubmitButton";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import useEquityAppApi from "@/hooks/use-equity-app-api";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";

const schema = Yup.object().shape({
  subject: Yup.string().required("This field is required").max(200),
  message: Yup.string().required("This field is required"),
});

const ContactPearlForm = ({ onCancel, onSubmit }) => {
  const client = useEquityAppApi();

  const mutation = useMutation(({ client, data }) =>
    client.post("/partner_support/", data)
  );

  const submitHandler = async (values, { setStatus, setErrors }) => {
    setStatus({ formLevelError: null });
    try {
      await mutation.mutateAsync({ client, data: values });
      onSubmit();
      toast.success("Your request was sent successfully.");
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={submitHandler}
      initialValues={{ subject: "", message: "" }}
    >
      {(formik) => (
        <FormikForm>
          <FormLevelError error={formik.status?.formLevelError} />
          <TextInput label="Subject" name="subject" />
          <TextArea label="Message" name="message" />
          <div className={styles.buttons}>
            <Button variant="light" onClick={onCancel}>
              Close
            </Button>
            <SubmitButton isLoading={formik.isSubmitting} />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ContactPearlForm;
