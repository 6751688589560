import useEquityAppApi from "./use-equity-app-api";
import { useMutation } from "react-query";
import getTokens from "@/helpers/login/login";
import { authActions } from "@/store/auth-slice";
import { modalActions } from "@/store/modal-slice";
import { toast } from "react-toastify";
import { getDateFormat } from "@/helpers/date"

export const cleanAppraiserWithPearlData = (data) => {
  return {
    email: data["email"],
    password: data["password"],
    appraiser: {
      license_number: data["license_number"],
      state: data["state"],
      expiration_date: getDateFormat(data["expiration_date"]),
    },
  };
};

export const useAppraiserSignUpWithPearl = () => {
  const client = useEquityAppApi();
  return useMutation(({ data }) => client.post("/appraiser_signup_with_pearl/", data), {
    onSuccess: (data, variables) => {
      getTokens(variables.data.email, variables.data.password).then((response) => {
        if (response.error) {
          toast.warning(response.error);
        }
        if (response.data) {
          variables.dispatch(authActions.login(response.data));
          variables.dispatch(modalActions.closeModals());
        }
      });
    },
  });
};

