import { useField } from "formik";
import { Form } from "react-bootstrap";
import styles from "./FormFieldWithShadow.module.css";
import RequiredAsterisk from "./RequiredAsterisk";

const Select = ({
  choices,
  label,
  helpText,
  name,
  shadow = true,
  required = false,
  id = null,
  placeholder = null,
  handleSelect=() => null,
  ...props
}) => {
  // choices are expected to be an array of {label: "...", value: "..."}
  const [field, meta] = useField({ name, ...props });
  const shadowCssClass = shadow ? styles.shadow : "";
  const fieldId = id ? id : `id-${name}`;
  return (
    <Form.Group
      controlId={fieldId}
      className={`form-group ${shadowCssClass}`}
      onChange={(event) => handleSelect(event?.target?.value)}
    >
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
      </Form.Label>
      <Form.Select isInvalid={meta.touched && !!meta.error} {...field} {...props}>
        {placeholder && <option key = 'blankChoice' hidden value>{placeholder}</option>}
        {choices.map(({ label, ...choice }) => {
          return (
            <option key={`${choice.value}`} {...choice}>
              {label}
            </option>
          );
        })}
      </Form.Select>
      {helpText && (
        <Form.Text id={`help-text-${fieldId}`} muted>
          {helpText}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
