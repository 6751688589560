import Inverter from "./Inverter";
import React from "react";
import AddFormButton from "../common/AddFormButton";
import { useParams } from "react-router-dom";
import {
  useCreateSolarInverter,
  useGetOrCreateSolarInverters,
} from "@/hooks/use-solar-inverters";

const Inverters = () => {
  const { homeId } = useParams();
  const solarInverters = useGetOrCreateSolarInverters(homeId);

  const { isLoading, mutate: createSolarInverter } = useCreateSolarInverter(homeId);
  const addHandler = () => createSolarInverter({ verified: false });

  return (
    <>
      {solarInverters.map((inverter, index) => (
        <Inverter
          initialValues={inverter}
          number={index + 1}
          totalInverters={solarInverters.length}
          key={inverter.id}
        />
      ))}
      <AddFormButton
        text="Add Inverter"
        isLoading={isLoading}
        addFormHandler={addHandler}
      />
    </>
  );
};
export default Inverters;
