import Certification from "./Certification";
import React from "react";
import Review from "./Review";
import styles from "./css/ReviewAndCertification.module.css";
function ReviewAndCertification() {
  return (
    <div className={`row ${styles.container}`}>
      <div className="col-xs-12 col-sm-5 ps-sm-0">
        <Review />
      </div>
      <div className="col-xs-12 col-sm-7">
        <Certification />
      </div>
    </div>
  );
}

export default ReviewAndCertification;
