import React, { Fragment } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import styles from "./CashFlowChart.module.css";
import { useSolarSystemYearlyValueChart } from "@/hooks/use-solar-valuation";
import { useParams } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const toMoney = (number) =>
  number.toLocaleString("en", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
const addDollar = (value) => (value === 0 ? "$0.00" : toMoney(value));
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        {payload.map(({ color, value }, index) => (
          <div key={index} style={{ color }}>
            {toMoney(value)}
          </div>
        ))}
        <div style={{ color: "var(--primary)" }}>year: {label}</div>
      </div>
    );
  }

  return null;
};
const CashFlowChart = ({ valuationId }) => {
  const { homeId } = useParams();
  const { data, isIdle, isLoading, isError } = useSolarSystemYearlyValueChart(
    homeId,
    valuationId
  );
  if (isIdle || isLoading) {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  }

  if (isError) {
    return <p>There was an error trying to load the Discounted Cash Flow chart.</p>;
  }

  const legends = [
    { name: "high", color: "#F2BD42" },
    { name: "expected", color: "#D95040" },
    { name: "low", color: "#5087EC" },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Expected range for discounted cash flow</div>
        <div className={styles.legend}>
          <div>Key</div>
          {legends.map(({ name, color }, index) => (
            <Fragment key={index}>
              <div className={styles.bar}>
                <div style={{ backgroundColor: color }} />
              </div>
              <div>{capitalize(name)}</div>
            </Fragment>
          ))}
        </div>
      </div>
      <ResponsiveContainer height={350} width="99%">
        <LineChart data={data}>
          <Label value="Expected range for discounted cash flow" position="insideTop" />
          <CartesianGrid />
          <XAxis dataKey="year" />
          <YAxis tickFormatter={addDollar} />
          <Tooltip content={<CustomTooltip />} />
          {legends.map(({ name, color }, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={name}
              stroke={color}
              strokeWidth={3}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default CashFlowChart;
