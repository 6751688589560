import styles from "./ExternalLink.module.css";

const SidebarEntry = (props) => {
  const url = props.active ? "#" : props.url;
  const target = props.active ? undefined : "_blank";
  const cssClasses = props.active ? `${styles.link} ${styles.active}` : styles.link;

  return (
    <div className={cssClasses}>
      <div>
        <img src={props.icon} alt="icon" />
      </div>
      <a href={url} target={target} onClick={props.handleShow}>
        {props.text}
      </a>
    </div>
  );
};

export default SidebarEntry;
