import * as Yup from "yup";
import React from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { Form as FormikForm, Formik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import { EmailInput, PasswordInput, TextInput } from "@/components/UI/FormFields/Inputs";
import SubmitButton from "@/components/UI/SubmitButton";
import Select from "@/components/UI/FormFields/Select";
import DatepickerField from "@/components/UI/FormFields/DatepickerField";
import CheckBox from "@/components/UI/FormFields/CheckBox";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import stateChoices from "@/helpers/stateChoices"
import { cleanAppraiserWithPearlData, useAppraiserSignUpWithPearl } from "@/hooks/use-appraiser-signup-with-pearl";
import linkStyle from "./SignupForm.module.css";
import styles from "./SignUpWithPearl.module.css";
import loginStyles from "../Login/css/LoginForm.module.css";
import VerticallyCenteredModal from "@/components/UI/VerticallyCenteredModal";

const signUpWithPearlSchema = Yup.object().shape({
  email: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
  license_number: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  expiration_date: Yup.mixed().required("This field is required"),
  agree_terms: Yup.boolean().oneOf(
    [true],
    "You must agree on terms and conditions to sign up"
  ),
});

const SignUpWithPearl = ({ show, setShow, initialValues }) => {
  const mutation = useAppraiserSignUpWithPearl();
  const dispatch = useDispatch();
  const signUpWithPearlHandler = async (values, { setStatus, setErrors }) => {
    const data = cleanAppraiserWithPearlData(values);
    try {
      await mutation.mutateAsync({
        data: data,
        dispatch: dispatch,
      });
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };
  return (
    <VerticallyCenteredModal
      contentClassName={loginStyles.bg_none}
      show={show}
      onHide={() => setShow(false)}
    >
      <FontAwesomeIcon
        icon={faTimes}
        className={loginStyles["close-modal"]}
        onClick={() => setShow(false)}
      />
      <div className={`card border-0 ${loginStyles.card}`}>
        <div className="card-body">
          <h5 className={`fw-bold ${loginStyles["text-primary"]}`}>
            Sign up with Pearl account
          </h5>
          <div className={styles["messages"]}>
            <div>You already have an account in another Pearl app with the same email.</div>
            <div>By providing your Pearl password you will be granted access to this app with the same password.</div>
          </div>
          <Formik
            validationSchema={signUpWithPearlSchema}
            onSubmit={signUpWithPearlHandler}
            initialValues={initialValues}
          >
            {(formik) => (
              <FormikForm>
                <FormLevelError error={formik.status?.formLevelError} />
                <EmailInput name="email" label="Email Address" shadow={false} required={true} disabled={true} />
                <PasswordInput
                  name="password"
                  label="Password"
                  shadow={false}
                  required={true}
                />
                <TextInput
                  label="Certification / License Number"
                  name="license_number"
                  shadow={false}
                  required={true}
                />
                <Row>
                  <Col md={6}>
                    <Select
                      label="State"
                      name="state"
                      shadow={false}
                      choices={stateChoices}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <DatepickerField
                      label="Expiration Date (MM/DD/YY)"
                      name="expiration_date"
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                      data-date-format="yyyy-mm-dd"
                      required={true}
                      shadow={false}
                    />
                  </Col>
                </Row>
                <CheckBox
                  name="agree_terms"
                  label={
                    <span>
                      I agree to the &nbsp;
                      <a
                        href="https://res.cloudinary.com/hyxuashac/image/upload/v1632487893/corporate/Legal/August_2021_Terms_and_Conditions_Appraisers_v1_1.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className={linkStyle.link}
                      >
                        terms and conditions
                      </a>
                    </span>
                  }
                />
                <SubmitButton
                  variant="primary"
                  className={`btn-block`}
                  label="Sign up with Pearl account"
                  isLoading={formik.isSubmitting}
                />
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </VerticallyCenteredModal>

  );
};
export default SignUpWithPearl;