import { useDispatch, useSelector } from "react-redux";
import {modalActions} from "@/store/modal-slice"
import Partners from "@/components/Login/Partners";
import WelcomeAppraiser from "@/components/AppraiserRegistration/WelcomeAppraiser";
import useWindowSize from "@/hooks/use-window-size";
import React from "react";
import SignUp from "@/components/AppraiserRegistration/SignUp";
import OptionSnippets from "@/components/AppraiserRegistration/OptionSnippets";
import RegistrationCall from "@/components/AppraiserRegistration/RegistrationCall";
import LearnMore from "@/components/AppraiserRegistration/LearnMore";


const AppraiserSignup = () => {
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const show = useSelector(({ modal }) => modal.showSignUp);
  const setShow = (showValue) => dispatch(modalActions.setShowSignUp(showValue))
  React.useEffect(() => {
    if (show && width >= 992) {
      setShow(show && width >= 992 ? false : show)
    }
  }, [width]);
  const showSignupForm = () => {
    if (width > 991) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <div className="row align-items-stretch">
        <div className="col-lg-6 px-4">
          <WelcomeAppraiser setShow={setShow} btnText="Appraiser Signup" />
        </div>
        <SignUp show={show} setShow={setShow} />
      </div>
      <Partners />
      <div className="row align-items-center">
        <div className="col-md-7">
          <OptionSnippets />
        </div>
        <div className="col-md-5 px-0">
          <RegistrationCall freeRegistrationClickHandler={showSignupForm} />
        </div>
      </div>
      <LearnMore />
    </>
  );
};

export default AppraiserSignup;
