import styles from "./RecentHomesBackLink.module.css";

const RecentHomesBackLink = ({ resetAddress }) => {
  return (
    <div className={styles["back-homes"]}>
      <span onClick={resetAddress}>Back to Recent Homes</span>
    </div>
  );
};

export default RecentHomesBackLink;
