import { Link } from "react-router-dom";
import styles from "./css/LoginButton.module.css";
const LoginButton = () => {
  return (
    <Link to="/appraiser-login" className={`btn ${styles.btn}`}>
      <span>Login</span>
    </Link>
  );
};
export default LoginButton;
