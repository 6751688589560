import { Button } from "react-bootstrap";
import HomeNoResults from "./HomeNoResults";
import styles from "./HomesLoadMore.module.css";

const HomesLoadMore = ({
  isFetchingNextPage,
  hasNextPage,
  hasHomes,
  fetchNextPage,
  status,
  address,
  addressInputRef,
}) => {
  if (status === "loading" && !isFetchingNextPage) {
    return <p>Loading data...</p>;
  }
  if (status === "error") {
    return <p>Error fetching data</p>;
  }
  if (!hasHomes) {
    if (address) {
      return (
        <HomeNoResults
          title={`No Results Found for “${address}”`}
          text={`Please double check the address and try again.`}
        />
      );
    } else {
      return (
        <HomeNoResults
          title={`Nothing in Recent`}
          text={`Start a new calculation and it will show up here.`}
          addressInputRef={addressInputRef}
        />
      );
    }
  }
  return (
    <Button
      className={styles["load-more"]}
      variant="success"
      onClick={() => fetchNextPage()}
      disabled={!hasNextPage || isFetchingNextPage}
    >
      {isFetchingNextPage
        ? "Loading more..."
        : hasNextPage
        ? "Load More"
        : "Nothing more to load"}
    </Button>
  );
};

export default HomesLoadMore;
