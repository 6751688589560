import "./App.css";
import "./custom.scss";

import { Route, Routes, Navigate } from "react-router-dom";

import LoginLayout from "./layouts/LoginLayout";
import LoginLayoutNoNetwork from "./layouts/LoginLayoutNoNetwork";
import AfterLoginLayout from "./layouts/AfterLoginLayout";
import CalculationResults from "./pages/CalculationResults";
import CalculationStep1 from "./pages/CalculationStep1";
import CalculationStep2 from "./pages/CalculationStep2";
import CalculationStep3 from "./pages/CalculationStep3";
import CalculationStep4 from "./pages/CalculationStep4";
import CalculationStep5 from "./pages/CalculationStep5";
import HomeList from "./pages/HomeList";
import AppraiserSignup from "./pages/AppraiserSignup";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import PrivateRoute from "./routing/PrivateRoute";
import React from "react";
import useGoogleAnalytics from "./hooks/use-google-analytics";
import AppraiserLogin from "./pages/AppraiserLogin";

function App() {
  useGoogleAnalytics();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/homes" replace />
          </PrivateRoute>
        }
      />
      <Route path="/" element={<LoginLayoutNoNetwork />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="/" element={<LoginLayout />}>
        <Route path="appraiser-signup" element={<AppraiserSignup />} />
        <Route path="login" element={<Login />} />
        <Route path="appraiser-login" element={<AppraiserLogin />} />
      </Route>
      <Route path="/homes/" element={<AfterLoginLayout />}>
        <Route path="" element={<HomeList />} />
        <Route path=":homeId/step1" element={<CalculationStep1 />} />
        <Route path=":homeId/step2" element={<CalculationStep2 />} />
        <Route path=":homeId/step3" element={<CalculationStep3 />} />
        <Route path=":homeId/step4" element={<CalculationStep4 />} />
        <Route path=":homeId/step5" element={<CalculationStep5 />} />
        <Route path=":homeId/results" element={<CalculationResults />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
