import axios from "axios";

function getTokens(email, password) {
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/api/v6.2.0/tokens/obtain/`, {
      email: email,
      password: password,
    })
    .then(function (response) {
      return { data: response.data };
    })
    .catch(function (error) {
      const msg = error.response.data.detail;
      return { error: msg };
    });
}
export default getTokens;
