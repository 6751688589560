export const getCachedQuery = (queryClient, queryKey, assetId) => {
  const assets = queryClient.getQueryData(queryKey);
  const asset = assets.find((a) => a.id === assetId);
  return asset || {};
};

export const cleanupNumberFieldsForSubmit = (values, numberFields) => {
  for (const fieldName of numberFields) {
    if (values[fieldName] === "") {
      values[fieldName] = null;
    }
  }
  return values;
};
