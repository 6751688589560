import {Link, useParams} from "react-router-dom";
import React from "react";

function SystemProfileHeader({isSpecificUtility, address, ownership, utilityProvider}) {
  const {homeId} = useParams();

  const utility = utilityProvider ? (
    <>
      with utilities provided by <b>{utilityProvider}</b>.
    </>
  ) : (
    ""
  );

  const average_utility_header = (<>
    Calculation for <b>{address}</b> assuming this system is <b>{ownership}</b>{" "}
    {utility}
    &nbsp;
    <Link
      to={`/homes/${homeId}/step1`}
      className="hide-when-printing"
      data-html2canvas-ignore={true}
    >
      Edit System Profile
    </Link>
  </>)
  const specific_utility_header = <>The calculation for this system used the home’s specific utility plan and modeled
    energy load. That means a more accurate estimation of the value of the system. </>

  return (
    <div>
      <p>{isSpecificUtility ? specific_utility_header : average_utility_header}</p>
    </div>
  )

}

export default SystemProfileHeader;
