import { useSelector } from "react-redux";
import { createSelector } from "reselect";

export const selectPerms = createSelector(
  (state) => state.auth.perms,
  (perms) => {
    const setPerms = new Set(perms);
    return {
      hasPearlPerm: setPerms.has("has_pearl_perm"),
      hasContractorPerm: setPerms.has("has_contractor_perm"),
      hasAppraiserPerm: setPerms.has("administration.appraise"),
      hasSunPowerPerm: setPerms.has("has_sunpower_program"),
    };
  }
);
export const usePerms = () => useSelector(selectPerms);
