import useEquityAppApi from "./use-equity-app-api";
import { useQuery } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";

export const useHomesCount = () => {
  const client = useEquityAppApi();

  return useQuery(
    ["homes_count"],
    async () => {
      const response = await client.get(`/homes/count/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
    }
  );
};
