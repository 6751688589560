import styles from "./AiAddendum.module.css";
import aiAddendum from "@/assets/imgs/addendum-preview.svg";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useAiAddendum } from "@/hooks/use-reports";

const AiAddendum = () => {
  const { homeId } = useParams();
  const { refetch, isFetching } = useAiAddendum(homeId);

  const handleClick = async () => {
    const { data: response } = await refetch();
    let blobUrl = window.URL.createObjectURL(response.data);
    let a = document.createElement("a");
    a.href = blobUrl;
    const header = response.headers["content-disposition"];
    a.download = header.replaceAll("attachment; filename=", "").replaceAll('"', "");
    a.click();
  };

  return (
    <div className={styles.container}>
      <h2>AI Addendum</h2>
      <img className="img-fluid" src={aiAddendum} alt="ai-addendum" />
      <Button
        variant="outline-secondary"
        disabled={isFetching}
        onClick={handleClick}
        className={styles.button}
      >
        {!isFetching && <FontAwesomeIcon icon={faDownload} className="fa-fw" />}
        {isFetching && <FontAwesomeIcon icon={faSpinner} className="fa-fw" spin />}
        Download
      </Button>
    </div>
  );
};

export default AiAddendum;
