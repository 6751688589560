import styles from "./TiltTooltipTable.module.css";
const TiltTooltipTable = () => {
  const values = [
    { pitch: "1:12", tilt: 4 },
    { pitch: "2:12", tilt: 9 },
    { pitch: "3:12", tilt: 14 },
    { pitch: "4:12", tilt: 18 },
    { pitch: "5:12", tilt: 22 },
    { pitch: "6:12", tilt: 26 },
    { pitch: "7:12", tilt: 30 },
    { pitch: "8:12", tilt: 33 },
    { pitch: "9:12", tilt: 36 },
    { pitch: "10:12", tilt: 39 },
    { pitch: "11:12", tilt: 42 },
    { pitch: "12:12", tilt: 45 },
  ];
  return (
    <div className={styles.wrapper}>
      <p className={styles["top-paragraph"]}>Common roof pitches and their tilt values</p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={`${styles["table-tilt-data"]} ${styles["th-tilt"]}`}>Pitch</th>
            <th className={`${styles["table-tilt-data"]} ${styles["th-tilt"]}`}>Tilt</th>
          </tr>
        </thead>
        <tbody>
          {values.map((value, index) => (
            <tr key={`tilt${index}`} className={styles["tbody-table-tilt-row"]}>
              <td className={`${styles["table-tilt-data"]} ${styles["td-tilt"]}`}>
                {value.pitch}
              </td>
              <td className={`${styles["table-tilt-data"]} ${styles["td-tilt"]}`}>
                {value.tilt}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TiltTooltipTable;
