import Modal from "react-bootstrap/Modal";
import styles from "./DuplicateAddressModal.module.css";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAssignToHome } from "@/hooks/use-assign-to-home";

const DuplicateAddressModal = ({ modalState, dispatchModalState }) => {
  const navigate = useNavigate();
  const { isLoading, mutateAsync } = useAssignToHome(modalState.homeId);
  const handleLoadHome = async () => {
    if (modalState.assignToHome) {
      await mutateAsync();
    }
    dispatchModalState({ type: "hide" });
    navigate(`/homes/${modalState.homeId}/step1`);
  };
  const handleClose = () => dispatchModalState({ type: "hide" });

  return (
    <Modal show={modalState.show} onHide={handleClose} centered>
      <Modal.Body>
        <div className={styles.header}>
          <FontAwesomeIcon
            icon={faTimes}
            size="2x"
            className={styles.close}
            onClick={handleClose}
          />
        </div>
        <h2 className={styles.title}>This home record already exists</h2>
        <p className={styles.text}>{modalState.message}</p>
        {modalState.homeId && (
          <div className="text-center mb-3">
            <Button
              variant="outline-primary"
              className={styles.load}
              onClick={handleLoadHome}
              disabled={isLoading}
            >
              Load Home Record
              {isLoading && (
                <FontAwesomeIcon icon={faSpinner} spin size="lg" className="fa-fw" />
              )}
            </Button>
          </div>
        )}
        <div className="text-center mb-3">
          <span className={styles.cancel} onClick={handleClose}>
            Cancel
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DuplicateAddressModal;
