import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useCreateOrUpdateHome = (homeId = null) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const client = usePearlApi();
  let url = "/homes/";
  if (homeId) {
    url = `/homes/${homeId}/`;
  }

  return useMutation(
    async (data) => {
      const response = await client.post(url, data, {
        params: { custom_error_format: true },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (!homeId) {
          navigate(`/homes/${data.id}/step1`);
          toast.success("Home created successfully.");
          queryClient.setQueryData(["homes", data.id], data);
        } else {
          toast.success("The home address was updated successfully.");
        }
        queryClient.invalidateQueries("homes");
      },
    }
  );
};

export const useHome = (homeId) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId],
    async () => {
      const response = await client.get(`/homes/${homeId}/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
    }
  );
};
