import { toast } from "react-toastify";

const displayBackendFormErrors = ({ status, data }, formikSetStatus, formikSetErrors) => {
  // helper to display backend form errors when using formik
  // backend and frontend field name are expected to be the same
  if (status === 400 && !data?.detail) {
    // backend validation failed, so we need to display the server error
    const { non_field_errors: formLevelErrors, ...fieldLevelErrors } = data;
    if (formLevelErrors) {
      formikSetStatus({ formLevelError: getServerErrorsText(formLevelErrors) });
    }
    if (fieldLevelErrors && Object.keys(fieldLevelErrors).length) {
      let errors = {};
      for (const [field, fieldErrors] of Object.entries(fieldLevelErrors)) {
        errors[field] = getServerErrorsText(fieldErrors);
      }
      formikSetErrors(errors);
    }
    toast.dismiss();
    toast.error("Please correct the error(s) below.", { autoClose: 3000 });
  } else {
    displayBackendError(status, data);
  }
};

export const handleAddressErrors = ({ status, data }) => {
  if (status === 400 && !data?.detail) {
    let errorText = "";
    const { non_field_errors: formLevelErrors, ...fieldLevelErrors } = data;
    if (formLevelErrors) {
      errorText = getServerErrorsText(formLevelErrors);
    }
    if (fieldLevelErrors && Object.keys(fieldLevelErrors).length) {
      // display the first error
      errorText = getServerErrorsText(Object.entries(fieldLevelErrors)[0][1]);
    }
    toast.dismiss();
    toast.error(errorText, { autoClose: 5000 });
  } else {
    displayBackendError(status, data);
  }
};

export const displayBackendError = (status, data) => {
  if (status === 400 && data?.detail) {
    const msg = data.detail;
    toast.dismiss();
    toast.error(msg, { autoClose: 3000 });
  } else if (status === 401) {
    let msg = "You should be logged in to do this action.";
    toast.dismiss();
    toast.error(msg, { autoClose: 3000 });
  } else if (status === 403) {
    let msg = "You don't have permission to do this action.";
    if (data?.detail) {
      msg = data.detail;
    }
    toast.dismiss();
    toast.error(msg, { autoClose: 3000 });
  } else if (status === 404) {
    const msg =
      "We are unable to find what you're looking for. It might have been deleted.";
    toast.dismiss();
    toast.error(msg, { autoClose: 3000 });
  } else if (status === 429) {
    const msg =
      "Too many failed attempts. Try again later.";
    toast.dismiss();
    toast.error(msg, { autoClose: 3000 });
  } else {
    const msg =
      "We encountered an unexpected issue while trying to fulfill your request. " +
      "If this keeps happening, please send a Partner Support request to the Pearl team.";
    toast.dismiss();
    toast.error(msg);
  }
};

/**
 * for react-query errors when using useQuery and similar hooks to get data
 * */
export const handleQueryError = (error) => {
  if (error.response) {
    displayBackendError(error.response.status, error.response.data);
  } else {
    displayGenericClientSideError(error);
  }
};

/**
 * for react-query errors when using useMutation and we want to display form errors
 * */
export const handleMutationError = (error, formikSetStatus, formikSetErrors) => {
  if (error.response) {
    displayBackendFormErrors(error.response, formikSetStatus, formikSetErrors);
  } else {
    displayGenericClientSideError(error);
  }
};

/**
 * for react-query errors when we do not have an API response, usually due to a network error
 * */
export const displayGenericClientSideError = (error) => {
  let msg =
    "We encountered an unexpected issue while trying to fulfill your request. " +
    "If this keeps happening, please send a Partner Support request to the Pearl team.";
  const errorDetails = error.toJSON ? error.toJSON() : error;
  if (errorDetails?.message === "Network Error") {
    msg =
      "We are unable to reach the server. Please make sure you are connected to the Internet. " +
      "If this keeps happening, please send a Partner Support request to the Pearl team.";
  }
  toast.dismiss();
  toast.error(msg);
};

const getServerErrorsText = (errors) => {
  if (errors.length === 1) {
    return getServerErrorText(errors[0]);
  } else if (errors.length > 1) {
    return (
      <ul className="mb-0 ps-4">
        {errors.map((error, idx) => (
          <li key={idx}>{getServerErrorText(error)}</li>
        ))}
      </ul>
    );
  }
  return "";
};

const getServerErrorText = (error) => (typeof error === 'object' && "message" in error) ? error["message"] : error

export default displayBackendFormErrors;
