import React, { useState } from "react";
import styles from "./css/EstimateEquValueMultipleRates.module.css";

const EstimateEquValueMultipleRatesMobile = ({ rates }) => {
  const [activeTab, setActiveTab] = useState(0);
  const changeTab = (index) => {
    setActiveTab(index);
  };
  const rate = rates[activeTab];
  const low_value = rate.low_value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  const high_value = rate.high_value.toLocaleString("en-US", {
    maximumFractionDigits: 0,
  });
  return (
    <div className={styles.rates_container_mobile}>
      <div className={styles.tabs}>
        {rates.map((rate, key) => (
          <div
            className={`${styles.tab} ${key == activeTab && styles.active}`}
            key={key}
            onClick={() => changeTab(key)}
          >
            <div>
              {rate.interest_rate.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
              <sup>%</sup> Rate
            </div>
            <div className={styles.tab_line}></div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center my-4">
        <div className={styles.rate_range}>
          ${low_value} - ${high_value}
        </div>
      </div>
    </div>
  );
};

export default EstimateEquValueMultipleRatesMobile;
