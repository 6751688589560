import axios from "axios";
import { useSelector } from "react-redux";
import {
  addAuthorizationHeaderForRequest,
  autoRefreshAccessToken,
} from "@/helpers/login/interceptors";

const useEquityAppApi = () => {
  const auth = useSelector((store) => store.auth);
  const requestService = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/equity_app_api`,
  });
  if (auth.isAuthenticated) {
    addAuthorizationHeaderForRequest(requestService);
    autoRefreshAccessToken(requestService);
  }
  return requestService;
};

export default useEquityAppApi;
