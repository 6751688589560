import PowerProductionWarranty from "./PowerProductionWarranty";
import AddFormButton from "../common/AddFormButton";
import { useParams } from "react-router-dom";
import { useCreatePPW, useGetOrCreatePPW } from "@/hooks/use-power-production-warranty";

const PowerProductionWarranties = () => {
  const { homeId } = useParams();
  const ppws = useGetOrCreatePPW(homeId);
  const { mutate: createPPW, isLoading } = useCreatePPW(homeId);
  const addHandler = () => createPPW({ verified: false });

  return (
    <>
      {ppws.map((warranty, index) => {
        return (
          <PowerProductionWarranty
            initialValues={warranty}
            number={index + 1}
            totalPPWs={ppws.length}
            key={index}
          />
        );
      })}
      <AddFormButton
        text="Add Power Production Warranty"
        addFormHandler={addHandler}
        isLoading={isLoading}
      />
    </>
  );
};
export default PowerProductionWarranties;
