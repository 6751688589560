// we are NOT using css modules intentionally since we need to use ids
// for the sidebar to work properly
import "./MobileSidebarToggle.css";

const MobileSidebarToggle = () => {
  return (
    <a
      href="#sidenav-open"
      id="sidenav-button"
      className="hamburger"
      title="Open Menu"
      aria-label="Open Menu"
    >
      <svg
        viewBox="0 0 50 40"
        role="presentation"
        focusable="false"
        aria-label="trigram for heaven symbol"
      >
        <line x1="0" x2="100%" y1="10%" y2="10%"></line>
        <line x1="0" x2="100%" y1="50%" y2="50%"></line>
        <line x1="0" x2="100%" y1="90%" y2="90%"></line>
      </svg>
    </a>
  );
};

export default MobileSidebarToggle;
