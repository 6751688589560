import FormField from "./FormField";

export const TextInput = (props) => {
  return <FormField type="text" {...props} />;
};

export const NumberInput = (props) => {
  return <FormField type="number" {...props} />;
};

export const EmailInput = (props) => {
  return <FormField type="email" {...props} />;
};

export const PasswordInput = (props) => {
  return <FormField type="password" {...props} />;
};
