import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./css/LearnMore.module.css";

const LearnMore = () => (
  <div className={`col-12 justify-content-center my-4`}>
    <div className={`col-11 d-flex mx-auto  ${styles.container}`}>
      <div className={`pt-6 ${styles.content}`}>
        <div className={styles.text}>
          The Pearl Solar Equity Calculator: Making it simple to appraise high-performing
          homes.
        </div>
        <a
          href={
            process.env.REACT_APP_RESOURCES_URL +
            "/en_US/appraiser-resources/methodology-for-calculator"
          }
          className={`btn ${styles.btn}`}
          target="_blank"
          rel="noreferrer"
        >
          Learn more about the science behind the Pearl Solar Equity Calculator
          <FontAwesomeIcon icon={faArrowAltCircleRight} className={styles.icon} />
        </a>
      </div>
    </div>
  </div>
);

export default LearnMore;
