import styles from "./SystemProfile.module.css";
import { Link, useParams } from "react-router-dom";
import SystemProfileInfo from "./SystemProfileInfo";
import SystemProfileTable from "./SystemProfileTable";

const SystemProfile = ({ inputs }) => {
  const { homeId } = useParams();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>System Profile</h2>
      <SystemProfileInfo
        address={inputs.address}
        ownershipType={inputs.ownership}
        utilityProvider={inputs.utility_provider}
        isSpecificUtility={inputs.is_specific_utility}
      />
      <SystemProfileTable
        systemSize={inputs.system_size}
        electricityRate={inputs.electricity_rate}
        inverterType={inputs.inverter_type}
        panelManufacturer={inputs.panel_manufacturer}
        arrayTypes={inputs.array_types}
        installationCost={inputs.installation_cost}
        isSpecificUtility={inputs.is_specific_utility}
        specificUtilityPlanName={inputs.specific_utility_name}
      />
      <Link to={`/homes/${homeId}/step1`} className={styles.link}>
        Edit System Profile
      </Link>
    </div>
  );
};

export default SystemProfile;
