import styles from "./ReportsSection.module.css";
import AiAddendum from "./AiAddendum";
import AppraiserResources from "./AppraiserResources";
import CertificationReport from "./CertificationReport";
import { useCertificateReport } from "@/hooks/use-reports";
import { useParams } from "react-router-dom";

const ReportsSection = () => {
  const { homeId } = useParams();
  const { data } = useCertificateReport(homeId);
  const crUrl = data?.report_url;

  return (
    <div className={styles.container}>
      <div className={styles["ai-addendum-and-resources"]}>
        <AiAddendum />
        <AppraiserResources />
      </div>
      {crUrl && <CertificationReport crUrl={crUrl} />}
    </div>
  );
};

export default ReportsSection;
