import styles from "../Login/common/Login.module.css";
import React from "react";
import SignUpForm from "./SignUpForm";

const SignUpFormsContainer = ({showSignUpWithPearl}) => {
  return (
    <div className={`card border-0 ${styles.card} ${showSignUpWithPearl && "d-none"}`}>
      <div className="card-body">
        <h5 className={`fw-bold ${styles["text-primary"]}`}>
          Free registration for Appraisers
        </h5>
        <SignUpForm />
      </div>
    </div>
  );
};
export default SignUpFormsContainer;
