import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";

const SmartyStreetsCore = SmartyStreetsSDK.core;
const websiteKey = process.env.REACT_APP_SMARTY_STREETS_WEBSITE_KEY;
const smartyStreetsSharedCredentials = new SmartyStreetsCore.SharedCredentials(
  websiteKey
);
const autoCompleteClientBuilder = new SmartyStreetsCore.ClientBuilder(
  smartyStreetsSharedCredentials
).withLicenses(["us-autocomplete-pro-cloud"]);

export const autoCompleteClient =
  autoCompleteClientBuilder.buildUsAutocompleteProClient();
