import jwt from "jsonwebtoken";
import { authActions } from "@/store/auth-slice";
import store from "@/store";

export const TokenService = (function tokenService() {
  let service;
  function getServiceFunc() {
    if (!service) {
      service = this;
      return service;
    }
    return service;
  }

  const setToken = (refreshData) => {
    if (refreshData.access) {
      store.dispatch(authActions.updateAccess(refreshData));
    }
  };

  const getAccessToken = () => {
    const currentState = store.getState();
    return currentState.auth.accessToken;
  };

  const getRefreshToken = () => {
    const currentState = store.getState();
    return currentState.auth.refreshToken;
  };

  const getTokenValidity = (tokenObj) => {
    const decodedToken = jwt.decode(tokenObj, { complete: true });
    const dateNow = new Date();
    const timeStamp = dateNow.getTime() / 1000;

    return decodedToken.payload.exp >= timeStamp;
  };

  const getRefreshTokenValidity = () => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      return getTokenValidity(refreshToken);
    }
    return null;
  };

  return {
    getService: getServiceFunc,
    setToken,
    getAccessToken,
    getRefreshToken,
    getRefreshTokenValidity,
  };
})();

export default TokenService;
