import React from "react";
import styles from "./css/EstimateEquValueMultipleRatesInfo.module.css";
import calculator_only from "@/assets/icons/calculator_only.svg";
import solar_battery from "@/assets/icons/solar_battery.svg";

const EstimateEquValueMultipleRatesInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div>
          <img src={calculator_only} alt="img" />
        </div>
        <div>
          <p>
            At the core of Pearl&apos;s calculation is energy modeling - we convert inputs
            like your home&apos;s specific utility rates, energy storage, panel
            efficiency, pitch and azimuth into the key output: system production. Pearl
            then layers approved appraiser methodologies to calculate the potential value
            of the installation.
          </p>
        </div>
      </div>
      <div className={styles.info}>
        <div>
          <img src={solar_battery} alt="img" />
        </div>
        <div>
          <p>
            The discount rate, otherwise known as the interest rate, effects the value of
            the system over time. The calculations above factor in the potential of
            different interest rates over the lifetime of the system.
          </p>
        </div>
      </div>
    </div>
  );
};

export default EstimateEquValueMultipleRatesInfo;
