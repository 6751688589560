import { useQuery } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import useEquityAppApi from "./use-equity-app-api";
import usePearlApi from "./use-pearl-api";

export const useManufacturerSuggestions = (system, searchTerm) => {
  const client = useEquityAppApi();
  return useQuery(
    ["manufacturerSuggestions", system, searchTerm],
    async () => {
      const params = { system: system, search_term: searchTerm };
      const response = await client.get("/manufacturer_suggestions/", { params });
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      staleTime: 300 * 1000,
    }
  );
};

export const useModelNumberSuggestions = (system, manufacturer, searchTerm) => {
  const client = useEquityAppApi();
  return useQuery(
    ["modelNumberSuggestions", system, manufacturer, searchTerm],
    async () => {
      const params = {
        system: system,
        manufacturer: manufacturer,
        search_term: searchTerm,
      };
      const response = await client.get("/model_number_suggestions/", { params });
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      enabled: false,
      cacheTime: 300 * 1000,
    }
  );
};

export const useProviderSuggestions = (homeId, searchTerm) => {
  const client = useEquityAppApi();
  return useQuery(
    ["homes", homeId, "providerSuggestions", searchTerm],
    async () => {
      const params = { search_term: searchTerm };
      const response = await client.get(`homes/${homeId}/providers/`, { params });
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      staleTime: 300 * 1000,
    }
  );
};

export const useSpecificProviderSuggestions = (homeId) => {
  const client = usePearlApi();
  return useQuery(
    ["homes", homeId, "specificProviderSuggestions"],
    async () => {
      const response = await client.get(`homes/${homeId}/specific_utility_plan_providers/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      cacheTime: 300 * 1000,
    }
  );
};
