import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./css/CertifyModal.module.css";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import { EmailInput, TextInput } from "@/components/UI/FormFields/Inputs";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useMutation, useQueryClient } from "react-query";
import NavButtons from "../Wizard/NavButtons";
import usePearlApi from "@/hooks/use-pearl-api";
import { useParams } from "react-router-dom";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const CertifySchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email").required("This field is required"),
});

function CertifyModal({ show, handleClose, address }) {
  const { homeId } = useParams();
  const client = usePearlApi();
  const [requested, setRequested] = useState(false);
  const queryClient = useQueryClient();

  const contactMutation = useMutation((data) =>
    client.post(`/homes/${homeId}/contacts/`, data)
  );
  const certificationMutation = useMutation((data) =>
    client.post(`/homes/${homeId}/certification_requests/`, data)
  );

  const requestCertificationHandler = async (
    { firstName: first_name, lastName: last_name, email },
    { setStatus, setErrors }
  ) => {
    setStatus({ formLevelError: null });
    const contactData = { first_name, last_name, email, relationship: "homeowner" };
    try {
      const {
        data: { id },
      } = await contactMutation.mutateAsync(contactData);
      await certificationMutation.mutateAsync({
        customers_on_report: [id],
        auto_create_service_professional_information_asset: true,
      });
      await queryClient.invalidateQueries(["homes", homeId, "CertificationRequest"]);
      setRequested(true);
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };
  return (
    <Modal
      centered
      autoFocus
      size="lg"
      show={show}
      onHide={handleClose}
      className={styles["modal-backdrop"]}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {requested ?? (
            <h5 className={styles.header}>Certification request for {address}</h5>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {requested ? (
          <>
            <div className={styles["requested-container"]}>
              <FontAwesomeIcon icon={faCheckCircle} size={"3x"} className={styles.icon} />
              <div>
                <h5 className={styles.header}>
                  Congratulations! We have received your certification request.
                </h5>
                <p>We will notify you when the certification has been processed.</p>
              </div>
            </div>
            <div className={styles["close-container"]}>
              <Button
                variant="light"
                className={styles["close-button"]}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            <p>
              In order to process this request we need the homeowner’s full name and email
              address.
            </p>
            <div>
              <Formik
                validationSchema={CertifySchema}
                onSubmit={requestCertificationHandler}
                initialValues={{ firstName: "", lastName: "", email: "" }}
              >
                {(formik) => (
                  <FormikForm>
                    <div className={styles.row}>
                      <FormLevelError error={formik.status?.formLevelError} />
                      <TextInput label="Homeowner First Name" name="firstName" />
                      <TextInput label="Homeowner last Name" name="lastName" />
                      <EmailInput label="Email" name="email" />
                    </div>
                    <p>
                      By clicking submit you are certifying that this system has been{" "}
                      <strong>installed</strong> at and all the information entered is
                      true and accurate.
                    </p>
                    <NavButtons
                      onBackClick={handleClose}
                      nextText={"Submit"}
                      backText={"Cancel"}
                    />
                  </FormikForm>
                )}
              </Formik>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CertifyModal;
