import styles from "./RecentHomes.module.css";
import { Link } from "react-router-dom";
import HomePreview from "./HomePreview";
import HomesLoadMore from "./HomesLoadMore";
import { useHomeList } from "@/hooks/use-home-list";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import RecentHomesHeader from "./RecentHomesHeader";
import RecentHomesBackLink from "./RecentHomesBackLink";

const RecentHomes = ({ addressInputRef }) => {
  const [enteredAddress, setEnteredAddress] = useState("");
  const [address] = useDebounce(enteredAddress, 250);

  const addressChangeHandler = (event) => {
    setEnteredAddress(event.target.value);
  };
  const resetAddress = () => {
    setEnteredAddress("");
  };

  const {
    data,
    fetchNextPage,
    hasNextPage = true,
    isFetchingNextPage,
    status,
  } = useHomeList(address);
  let homes = [];
  let pages = data ? data.pages : [];
  let headerTitle = "Recent Homes";
  let backHomes = status === "success" && address;
  if (status === "success") {
    for (const page of pages) {
      homes.push(...page.data.results);
    }
    if (address && homes.length) {
      if (homes.length > 1) {
        headerTitle = `${pages[0].data.count} Results for “${address}”`;
      } else {
        headerTitle = `1 Result for “${address}”`;
      }
    }
  }

  return (
    <>
      <RecentHomesHeader
        headerTitle={headerTitle}
        enteredAddress={enteredAddress}
        addressChangeHandler={addressChangeHandler}
      />
      <div className={styles["grid-container"]}>
        {homes.map(({ id, street_address, city, state }) => (
          <Link className={styles.link} key={`home-${id}`} to={`/homes/${id}/step1`}>
            <HomePreview firstLine={street_address} secondLine={`${city}, ${state}`} />
          </Link>
        ))}
      </div>
      <div className={styles["flex-container"]}>
        <HomesLoadMore
          address={address}
          status={status}
          hasHomes={homes.length}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          addressInputRef={addressInputRef}
        />
      </div>
      {backHomes && <RecentHomesBackLink resetAddress={resetAddress} />}
    </>
  );
};

export default RecentHomes;
