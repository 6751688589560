import styles from "./WizardSteps.module.css";
import { useState, useEffect } from "react";
import WizardStep from "./WizardStep";
import React from "react";
import { useLocation, useParams } from "react-router";
import { useSolarPanelData } from "@/hooks/use-solar-panels";

const getSteps = (homeId, currentUrl) => {
  const [isEnergyStorageIncluded, setIsEnergyStorageIncluded] = useState(false);
  const { isSuccess, data } = useSolarPanelData(homeId);
  const is_energy_storage_included = data && data.is_energy_storage_included
  useEffect(() => {
    if (isSuccess) {
      setIsEnergyStorageIncluded(is_energy_storage_included === true);
    }
  }, [isSuccess, is_energy_storage_included]);
  const steps = [
    { text: "Home Details", url: `/homes/${homeId}/step1`, visited: false },
    { text: "Arrays", url: `/homes/${homeId}/step2`, visited: false },
    { text: "Inverters", url: `/homes/${homeId}/step3`, visited: false },
    { text: "Warranty", url: `/homes/${homeId}/step4`, visited: false },
  ];
  if (isEnergyStorageIncluded) {
    steps.push({ text: "Energy Storage", url: `/homes/${homeId}/step5`, visited: false });
  }
  // this wizard appears for step pages and results page
  // steps are already ordered
  for (const step of steps) {
    if (step.url === currentUrl) {
      break;
    } else {
      step.visited = true;
    }
  }
  return steps;
};

const WizardSteps = () => {
  const { homeId } = useParams();
  const location = useLocation();

  const steps = getSteps(homeId, location.pathname);

  return (
    <div className={`hide-when-printing ${styles.steps}`}>
      {steps.map((step, idx) => {
        if (idx < steps.length - 1) {
          return (
            <React.Fragment key={idx}>
              <WizardStep stepNumber={idx + 1} {...step} />
              <hr className={styles.separator} />
            </React.Fragment>
          );
        }
        return <WizardStep key={idx} stepNumber={idx + 1} {...step} />;
      })}
    </div>
  );
};

export default WizardSteps;
