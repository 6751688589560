import axios from "axios";
import {
  addAuthorizationHeaderForRequest,
  autoRefreshAccessToken,
} from "@/helpers/login/interceptors";

const usePearlApi = (addInterceptors = true) => {
  const requestService = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v6.2.0`,
  });
  if (addInterceptors) {
    addAuthorizationHeaderForRequest(requestService);
    autoRefreshAccessToken(requestService);
  }
  return requestService;
};

export default usePearlApi;
