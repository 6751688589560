import { useParams } from "react-router-dom";
import { useSolarSystemYearlyValue } from "@/hooks/use-solar-valuation";
import styles from "./CashFlowTable.module.css";
import CashFlowTableExportButton from "./CashFlowTableExportButton";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";

const CashFlowTable = ({ valuationId }) => {
  const { homeId } = useParams();
  const { isLoading, isError, isIdle, data } = useSolarSystemYearlyValue(
    homeId,
    valuationId
  );
  let tableBody;
  if ((isLoading && !data) || isIdle) {
    tableBody = (
      <tr>
        <td className="text-center p-2" colSpan={4}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </td>
      </tr>
    );
  } else if (isError) {
    tableBody = (
      <tr>
        <td className="text-center p-2" colSpan={4}>
          There was an error while fetching the solar system yearly value.
        </td>
      </tr>
    );
  } else {
    tableBody = data.map((row) => (
      <tr key={row.year}>
        <td>{row.year}</td>
        <td>{row.low}</td>
        <td>{row.expected}</td>
        <td>{row.high}</td>
      </tr>
    ));
  }

  const [collapsed, setCollapsed] = useState(true);
  const showBtn = data?.length && collapsed;
  useEffect(() => {
    if (data?.length <= 10) {
      setCollapsed(false);
    }
  }, [data]);
  const expandTable = () => setCollapsed(false);
  let cssClasses = [styles["table-container"]];
  if (collapsed) {
    cssClasses.push(styles.expandable);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Total Discounted Cash Flow</h2>
        <CashFlowTableExportButton valuationId={valuationId} />
      </div>
      <div className={cssClasses.join(" ")}>
        <Table className={styles.table}>
          <thead>
            <tr>
              <th>Year</th>
              <th>Low</th>
              <th>Expected</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </Table>
        {showBtn && (
          <div className={styles.expand}>
            <Button variant="primary" onClick={expandTable}>
              Expand Table
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CashFlowTable;
