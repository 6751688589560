import { Outlet } from "react-router-dom";
import Header from "@/components/Login/Header";
import React from "react";
import styles from "./LoginLayout.module.css";
import chatSVG from "@/assets/icons/chat.svg";
import homeSVG from "@/assets/icons/home.svg";
import infoSVG from "@/assets/icons/info.svg";
import Sidebar from "@/components/Login/Sidebar";
import AnonymousRoute from "@/routing/AnonymousRoute";
import Certification from "@/components/Login/Certification";
import Footer from "@/components/Login/Footer";

const urls = [
  {
    link: process.env.REACT_APP_BASE_URL + "/",
    icon: homeSVG,
    text: "Pearl Certification Home",
  },
  {
    link: process.env.REACT_APP_BASE_URL + "/about-pearl",
    icon: infoSVG,
    text: "About Pearl Certification",
  },
  {
    link: process.env.REACT_APP_BASE_URL + "/contact-us",
    icon: chatSVG,
    text: "Contact Support",
  },
];
function LoginLayout() {
  return (
    <AnonymousRoute>
      <div className={styles["page-container"]}>
        <div className={styles["bg_img"]} />
        <Sidebar urls={urls} className={styles["side-nav"]} />
        <main className={styles.main}>
          <Header urls={urls} />
          <div className={styles["page-content"]}>
            <Outlet />
          </div>
        </main>
      </div>
      <Certification />
      <Footer />
    </AnonymousRoute>
  );
}

export default LoginLayout;
