import usePearlApi from "./use-pearl-api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";

const initial = {
  discount_rate: "",
  electricity_escalation_rate: "",
  operations_and_maintenance_costs: "",
  inverter_replacement_cost: "",
};

export const useSolarValuationAdjustment = (homeId) => {
  const client = usePearlApi();

  return useQuery(
    ["homes", homeId, "solarValuationAdjustment"],
    async () => {
      const response = await client.get(`/homes/${homeId}/solar_valuation_adjustments/`);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
      initialData: initial,
      initialStale: true,
    }
  );
};

export const useUpdateSolarValuationAdjustment = (homeId) => {
  const client = usePearlApi();
  const queryClient = useQueryClient();

  return useMutation(
    (data) => client.post(`/homes/${homeId}/solar_valuation_adjustments/`, data),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          ["homes", homeId, "solarValuationAdjustment"],
          response.data
        );
      },
    }
  );
};
