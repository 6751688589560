import { useFormikContext } from "formik";
import ModelNumberAutocompleteField from "../Step2/ModelNumberAutocompleteField";

const InverterModelNumberAutocompleteField = ({ number }) => {
  const { setFieldValue, submitForm } = useFormikContext();

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setFieldValue("model_number", suggestionValue);
    setFieldValue("wattage", suggestion.inverter_wattage || "");
    setFieldValue("efficiency_rate", suggestion.inverter_efficiency_rate || "");
    setFieldValue("warranty", suggestion.inverter_warranty || "");
    setFieldValue("warranty_source", suggestion.inverter_warranty_offered || "");
    submitForm();
  };

  return (
    <ModelNumberAutocompleteField
      system="solar_inverter"
      number={number}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default InverterModelNumberAutocompleteField;
