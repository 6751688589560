import WizardSteps from "@/components/Wizard/WizardSteps";
import { useState, useEffect } from "react";
import Step1Part1 from "@/components/Wizard/Step1/Step1Part1";
import Step1Part2 from "@/components/Wizard/Step1/Step1Part2";
import Step1PartEnergyBills from "@/components/Wizard/Step1/Step1PartEnergyBills";
import { usePerms } from "@/hooks/use-perms";
import { useSolarPanelData } from "@/hooks/use-solar-panels";
import { useParams } from "react-router-dom";

const CalculationStep1 = () => {
  const { homeId } = useParams();
  const { hasAppraiserPerm } = usePerms();
  const [isSpecificUtilityPlan, setIsSpecificUtilityPlan] = useState(false);
  const { isSuccess, data } = useSolarPanelData(homeId);
  const is_specific_utility_plan = data && data.is_specific_utility_plan;
  useEffect(() => {
    if (isSuccess) {
      setIsSpecificUtilityPlan(is_specific_utility_plan);
    }
  }, [isSuccess, is_specific_utility_plan]);
  const [part, setPart] = useState("1");

  if (part == "2") {
    return (
      <>
        <WizardSteps />
        <Step1Part2 setPart={setPart} isSpecificUtilityPlan={isSpecificUtilityPlan} />
      </>
    );
  }
  if (part == "energy_bills") {
    return (
      <>
        <WizardSteps />
        <Step1PartEnergyBills setPart={setPart} hasAppraiserPerm={hasAppraiserPerm} />
      </>
    );
  }

  return (
    <>
      <WizardSteps />
      <Step1Part1
        setPart={setPart}
        isSpecificUtilityPlan={isSpecificUtilityPlan}
        isSuccess={isSuccess}
        hasAppraiserPerm={hasAppraiserPerm}
      />
    </>
  );
};

export default CalculationStep1;
