import Description from "./Description";
import React from "react";
import des1Img from "@/assets/imgs/des/des_1.svg";
import des2Img from "@/assets/imgs/des/des_2.svg";
import styles from "./css/Descriptions.module.css";

function Descriptions() {
  return (
    <div className={styles.container}>
      <div className="col-12 p-0">
        <Description
          className="my-2 avoid-page-break-inside"
          img={des1Img}
          heading={"Make sure you get the full value of your high performing home!"}
        >
          <p>
            High-performing home features, like this solar system, increase home value –
            when they’re properly documented. If they’re not, you could lose money on the
            investments you’ve made in your home. A home’s high-performing features should
            be made highly visible and appropriately presented to an appraiser. If the
            lender and appraiser doesn’t know these features exist, that value will not be
            added. &nbsp;
            <a
              href={process.env.REACT_APP_BASE_URL + "/contractors"}
              target="_blank"
              className="hide-when-printing"
              data-html2canvas-ignore={true}
              rel="noreferrer"
            >
              Learn How Pearl Helps
            </a>
          </p>
        </Description>
      </div>
      <div className="col-12 p-0">
        <Description
          className="my-4 avoid-page-break-inside"
          img={des2Img}
          heading={"Homes with solar systems sell for more"}
        >
          <p>
            Studies have consistently found that homes with solar systems sell for more
            than comparable homes without them. The method used in this calculation is the
            income-based approach. This approach calculates the discounted cash flow of
            the solar system. That means it looks at the total future benefits that the
            homeowner will receive from the solar system over its useful life, and
            calculates what the value of those future benefits is right now.
          </p>
        </Description>
      </div>
    </div>
  );
}

export default Descriptions;
