import { useState, useEffect } from "react";
import WizardSteps from "@/components/Wizard/WizardSteps";
import NavButtons from "@/components/Wizard/NavButtons";
import Card from "@/components/UI/Card";
import { useNavigate, useParams } from "react-router-dom";
import PowerProductionWarranties from "@/components/Wizard/Step4/PowerProductionWarranties";
import LearnMoreAboutPearl from "@/components/Wizard/common/LearnMoreAboutPearl";
import { usePerms } from "@/hooks/use-perms";
import { useGetOrCreateSolarPanel } from "@/hooks/use-solar-panels";
import { useCreateSolarValuation } from "@/hooks/use-solar-valuation";
import CalculatingEquity from "@/components/Wizard/Step4/CalculatingEquity";

const CalculationStep4 = () => {
  const { homeId } = useParams();
  const { hasAppraiserPerm } = usePerms();
  const [isEnergyStorageIncluded, setIsEnergyStorageIncluded] = useState(false);
  const { isSuccess, data } = useGetOrCreateSolarPanel(homeId);
  useEffect(() => {
    if (isSuccess) {
      setIsEnergyStorageIncluded(data.is_energy_storage_included === true);
    }
  }, [isSuccess, data.is_energy_storage_included]);
  const navigate = useNavigate();
  const { isLoading, mutate: createSolarValuation } = useCreateSolarValuation(homeId);

  const backHandler = () => {
    navigate(`/homes/${homeId}/step3`);
  };
  const nextHandler = () => {
    if(isEnergyStorageIncluded){
      navigate(`/homes/${homeId}/step5`);
    } else {
      createSolarValuation()
    }
  };
  if (isLoading) {
    return (
      <>
        <WizardSteps />
        <Card>
          <CalculatingEquity />
        </Card>
      </>
    );
  }

  return (
    <>
      <WizardSteps />
      <Card>
        <p>
          Please enter the details of the power production warranty here. This data will
          be used to assist in calculating the system’s production over time.
          {hasAppraiserPerm && <LearnMoreAboutPearl />}
        </p>
        <PowerProductionWarranties />
      </Card>
      <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />
    </>
  );
};

export default CalculationStep4;
