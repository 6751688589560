import { TokenService } from "./TokenService";
import store from "@/store";
import { authActions } from "@/store/auth-slice";
import axios from "axios";

export const addAuthorizationHeaderForRequest = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

/**
 * automatically refresh the access token once it expires
 */
export const autoRefreshAccessToken = (axiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const valid = TokenService.getRefreshTokenValidity();
      if (!valid) {
        store.dispatch(authActions.logout());
      }
      if (error.response.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true;
        return axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/api/v6.2.0/tokens/refresh/`,
          method: "post",
          data: { refresh: TokenService.getRefreshToken() },
        }).then((res) => {
          if (res.status === 200) {
            TokenService.setToken(res.data);
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;
            return axiosInstance(originalRequest);
          }
          return null;
        });
      }
      return Promise.reject(error);
    }
  );
};
