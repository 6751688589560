import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import styles from "./SubmitButton.module.css";

const SubmitButton = ({ label, isLoading,isRightIcon, ...props }) => {
  return (
    <Button className={styles.submit} type="submit" disabled={isLoading} {...props}>
      <span></span>
      <span>{label || "Submit"}</span>
      <span className="right-icon">
        {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        {!isLoading && isRightIcon && <FontAwesomeIcon icon={faAngleRight} />}
      </span>
    </Button>
  );
};

export default SubmitButton;
