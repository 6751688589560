import React from "react";
import styles from "./css/SystemProfile.module.css";
import CertifyButton from "./CertifyButton";
import SystemProfileHeader from "./SystemProfileHeader";

function SystemProfile({ address, ownership, utilityProvider, hasSunPower, ...rest }) {
  const systemSize = rest.systemSize ? ` ${rest.systemSize} kW` : "-";
  const electricityRate = rest.electricityRate ? ` ${rest.electricityRate} $/kWh` : "-";
  const inverterType = rest.inverterType ? ` ${rest.inverterType}` : "-";
  const panelManufacturer = rest.panelManufacturer ? ` ${rest.panelManufacturer}` : "-";

  const utilityPlanCell =
    (rest.isSpecificUtility ?
        <>
          <p>Utility Plan</p>
          <span>{rest.specificUtilityPlanName}</span>
        </>
        :
        <>
          <p>Electricity Rate</p>
          <span>{electricityRate}</span>
        </>
    )

  return (
    <div className={styles.container}>
      <div className={styles["header-container"]}>
        <h3>System Profile</h3>
        {hasSunPower && <CertifyButton address={address} />}
      </div>
      <div className="row">
        <div className={`col-12 ${styles["col-12"]}`}>
          <SystemProfileHeader isSpecificUtility={rest.isSpecificUtility}
                               address={address}
                               ownership={ownership}
                               utilityProvider={utilityProvider}
          />
        </div>
        <div className={`col-xs-12 col-sm-6 ${styles["col-6"]}`}>
          <div>
            <p>System Size</p>
            <span>{systemSize}</span>
          </div>
        </div>
        <div className={`col-xs-12 col-sm-6 ${styles["col-6"]}`}>
          <div>
            {utilityPlanCell}
          </div>
        </div>
        <div className={`col-xs-12 col-sm-6 ${styles["col-6"]}`}>
          <div>
            <p>Inverter Type</p>
            <span>{inverterType}</span>
          </div>
        </div>
        <div className={`col-xs-12 col-sm-6 ${styles["col-6"]}`}>
          <div>
            <p>Panel Manufacturer</p>
            <span>{panelManufacturer}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemProfile;
