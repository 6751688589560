import Modal from "react-bootstrap/Modal";
import ContactPearlForm from "./ContactPearlForm";

const PartnerSupportModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Contact Pearl</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactPearlForm onCancel={props.closeModal} onSubmit={props.closeModal} />
      </Modal.Body>
    </Modal>
  );
};

export default PartnerSupportModal;
