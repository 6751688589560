import styles from "./AiAddendum.module.css";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import {useAppraiserReport} from "@/hooks/use-reports";

const AppraiserReport = () => {
  const {homeId} = useParams();
  const {refetch, isFetching} = useAppraiserReport(homeId);

  const handleClick = async () => {
    const {data: response} = await refetch();
    let blobUrl = window.URL.createObjectURL(response.data);
    let aTag = document.createElement("a");
    aTag.href = blobUrl;
    const header = response.headers["content-disposition"];
    aTag.download = header.replaceAll("attachment; filename=", "").replaceAll('"', "");
    aTag.click();
  };

  return (
    <Button
      variant="outline-secondary"
      disabled={isFetching}
      onClick={handleClick}
      className={styles.button}
    >
      {!isFetching && <FontAwesomeIcon icon={faDownload} className="fa-fw"/>}
      {isFetching && <FontAwesomeIcon icon={faSpinner} className="fa-fw" spin/>}
      Download
    </Button>
  );
};

export default AppraiserReport;
