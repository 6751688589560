import styles from "./Overview.module.css";

const Overview = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Overview</h2>
      <p className={styles.text}>
        The values presented here are calculated using a discounted cash flow method. Our
        algorithm looks at the total benefits the homeowner will receive from the
        photovoltaic system over its useful life* and calculates what the value of those
        future benefits is right now.
      </p>
      <p className={styles.text}>
        The discounted cash flow can be used as an input into the income-based appraisal
        methodology.
      </p>
      <p className={styles.text}>
        *The useful life of the photovoltaic system is determined by the length of the
        power production warranty. A system will likely continue to operate after the
        warranty has expired and in that sense the value presented here is a conservative
        estimate of the potential value of the system.
      </p>
    </div>
  );
};

export default Overview;
