import styles from "./NotFound.module.css";
import notFound_icon from "@/assets/icons/notFound_icon.png";
import back_arrow from "@/assets/icons/back_arrow.png";
import pearlLogo from "@/components/AfterLogin/Sidebar/pearl-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import PartnerSupportModal from "@/components/AfterLogin/Sidebar/PartnerSupportModal";

const NotFound = () => {
  const navigate = useNavigate();
  const goToDashboardHandler = () => {
    navigate("/");
  };

  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const showModal = () => {
    setShow(true);
  };

  return (
    <div className={styles.container}>
      <Link to="/homes" className={styles.logo}>
        <img src={pearlLogo} alt="logo" />
      </Link>
      <div className={styles.content}>
        <div className={styles.centered}>
          <img src={notFound_icon} alt="icon" />
          <h1>The page you were looking for was not found.</h1>
          <h3>
            Double check your link and try again. If this problem persists click{" "}
            <button className={styles.here} onClick={showModal}>
              here
            </button>{" "}
            to report it.
          </h3>
          <PartnerSupportModal show={show} closeModal={closeModal} />
          <button className={styles.button} onClick={goToDashboardHandler}>
            <img src={back_arrow} className={styles["back-arrow"]} alt="icon" />
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
