import AutocompleteField from "@/components/UI/FormFields/AutocompleteField";
import { useProviderSuggestions } from "@/hooks/use-suggestions";
import { useFormikContext } from "formik";
import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

const ProviderAutocompleteField = () => {
  const { homeId } = useParams();
  const { values, setFieldValue, submitForm } = useFormikContext();
  const [debouncedProvider] = useDebounce(values.utility_company, 250);
  const { data } = useProviderSuggestions(homeId, debouncedProvider);
  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setFieldValue("utility_company", suggestionValue);
    setFieldValue("utility_kwh_charge", suggestion.electricity_rate);
    submitForm();
  };

  const autosuggestProps = {
    suggestions: data || [],
    onSuggestionsFetchRequested: () => {},
    onSuggestionsClearRequested: () => {},
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
    shouldRenderSuggestions: () => true,
  };

  return (
    <AutocompleteField
      label="Utility Provider"
      name="utility_company"
      helpText="This field supports autocomplete."
      autosuggestProps={autosuggestProps}
      id="id_utility_provider"
    />
  );
};

export default ProviderAutocompleteField;
