import styles from "./HomePreview.module.css";

const HomePreview = ({ firstLine, secondLine }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles["view-by"]}>{firstLine}</span>
      <span>{secondLine}</span>
    </div>
  );
};

export default HomePreview;
