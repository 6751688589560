import React from "react";
import styles from "./css/Description.module.css";
function Description({ img, heading, children, className }) {
  return (
    <div className={`row ${className} ${styles.container}`}>
      <div className={`col-xs-12 col-sm-3 ${styles["col-3"]}`}>
        <img src={img} alt="img" />
      </div>
      <div className={`col-xs-12 col-sm-9 ${styles["col-9"]}`}>
        <h2>{heading}</h2>
        {children}
      </div>
    </div>
  );
}

export default Description;
