import { FormikProvider, useFormik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import React, { useEffect } from "react";
import RadioButtonGroup from "@/components/UI/FormFields/RadioGroup";
import RadioBooleanGroup from "@/components/UI/FormFields/RadioBooleanGroup";
import InputGroupField from "@/components/UI/FormFields/InputGroup";
import CongratsNote from "./CongratsNote";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import { Col, Row } from "react-bootstrap";
import ProviderAutocompleteField from "./UtilityProviderAutocomplete";
import {
  useGetOrCreateSolarPanel,
  useSolarPanelIds,
  useUpdateSolarPanel
} from "@/hooks/use-solar-panels";
import { getCachedQuery } from "../common/helpers";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import Spinner from "@/components/UI/Spinner";
import * as Yup from "yup";
import OwnedWithFinancingLabel from "@/components/UI/FormFields/OwnedWithFinancingLabel";
import SpecificUtility from "@/components/Wizard/Step1/SpecificUtility";
import { useSpecificProviderSuggestions } from "@/hooks/use-suggestions";

const schema = Yup.object().shape({
  ownership: Yup.string(),
  financing_type: Yup.string(),
  utility_company: Yup.string(),
  utility_kwh_charge: Yup.number().max(
    1,
    "The Electricity Rate should be less than or equal to 1"
  ),
});

const ownershipChoices = [
  { label: "Owned Outright", value: "owned" },
  { label: <OwnedWithFinancingLabel />, value: "financed" },
  { label: "Leased", value: "leased" },
  { label: "PPA", value: "power_purchase_agreement" },
];

const utilityPlanTypeChoices = [
  { label: "The average utility rate", value: "average_utility_rate" },
  { label: "Specific utility plan", value: "specific_utility_plan" },
];

const financingChoices = [
  { label: "Unsecured Loan", value: "unsecured_loan" },
  { label: "Loan Secured with UCC Filing", value: "solar_loan_with_ucc_filing" },
  { label: "Other", value: "other" },
];

const SolarPanelForm = ({ ownershipState, ownershipDispatch }) => {
  const { homeId } = useParams();
  const queryClient = useQueryClient();

  // Pre Load Choices for utility Plan Provider
  const UtilityProvidersObj = useSpecificProviderSuggestions(homeId);

  // get or create the solar panel
  const { isLoading, isSuccess, data } = useGetOrCreateSolarPanel(homeId);
  const ownership = data && data.ownership
  useEffect(() => {
    if (isSuccess) {
      runOwnershipSkipLogic(data.ownership, ownershipDispatch);
    }
  }, [isSuccess, ownership]);

  const { mutateAsync: updateSolarPanel } = useUpdateSolarPanel(homeId);
  const assetIds = useSolarPanelIds(homeId);
  const submitSolarPanel = async (
    { utility_kwh_charge, specific_utility_plan_external_id, ...rest },
    { setStatus, setErrors }
  ) => {
    // since the field is a number, submit null instead of an empty string
    const charge = utility_kwh_charge === "" ? null : utility_kwh_charge;
    const specific_utility_plan = specific_utility_plan_external_id
      ? { external_id: specific_utility_plan_external_id }
      : null;
    const values = {
      utility_kwh_charge: charge,
      specific_utility_plan,
      ...rest,
    };
    for (const assetId of assetIds) {
      const oldAssetData = getCachedQuery(
      queryClient,
      ["homes", homeId, "solarPanels"],
      assetId
      );
      const newAssetData = { ...oldAssetData, ...values };
      try {
        await updateSolarPanel({ assetId, data: newAssetData });
      }
      catch (error) {
        handleMutationError(error, setStatus, setErrors);
      }
    }
  };
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: submitSolarPanel,
    validationSchema: schema,
  });
  const ownershipClickHandler = (event) => {
    const ownership = event.target.value;
    runOwnershipSkipLogic(ownership, ownershipDispatch);

    if (ownership === "owned") {
      formik.setFieldValue("financing_type", "");
    } else if (["leased", "power_purchase_agreement"].includes(ownership)) {
      formik.setValues({
        ownership: ownership,
        financing_type: "",
        utility_company: "",
        utility_kwh_charge: "",
      });
    }
  };

  const utility_plan_type = formik.getFieldProps("utility_plan_type").value;
  const is_specific_utility_plan = utility_plan_type == "specific_utility_plan";
  const is_average_utility_rate = utility_plan_type == "average_utility_rate";

  return (
    <>
      <Spinner isLoading={isLoading} />
      <FormikProvider value={formik}>
        <AutoSaveForm>
          <FormLevelError error={formik.status?.formLevelError} />
          <RadioButtonGroup
            label="This PV system is"
            name="ownership"
            choices={ownershipChoices}
            onChange={ownershipClickHandler}
            required={true}
          />
          <RadioBooleanGroup
            label="Does this system include energy storage?"
            name="is_energy_storage_included"
          />
          <RadioButtonGroup
            label="Model this system using:"
            name="utility_plan_type"
            choices={utilityPlanTypeChoices}
            required={true}
          />
          {ownershipState.ownedWithFinancing && (
            <RadioButtonGroup
              label="Financing Type"
              name="financing_type"
              choices={financingChoices}
            />
          )}
          {ownershipState.owned && is_average_utility_rate && (
            <Row>
              <Col md={4}>
                <ProviderAutocompleteField />
              </Col>
            </Row>
          )}
          {ownershipState.owned && is_average_utility_rate && (
            <Row>
              <Col md={4}>
                <InputGroupField
                  type="number"
                  step="0.01"
                  label="Electricity Rate"
                  name="utility_kwh_charge"
                  appendedText="$/kWh"
                  required={true}
                />
              </Col>
            </Row>
          )}
          {ownershipState.owned && is_specific_utility_plan && <SpecificUtility UtilityProvidersObj={UtilityProvidersObj} />}
          {ownershipState.notOwned && <CongratsNote />}
        </AutoSaveForm>
      </FormikProvider>
    </>
  );
};

const runOwnershipSkipLogic = (ownership, dispatch) => {
  if (!ownership) {
    dispatch("NO_ANSWER");
  } else if (ownership === "financed") {
    dispatch("OWNED_WITH_FINANCING");
  } else if (ownership === "owned") {
    dispatch("OWNED");
  } else {
    dispatch("NOT_OWNED");
  }
};

export default SolarPanelForm;
