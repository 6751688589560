import styles from "./EnergyBillsManualForm.module.css";
import { FormikProvider, useFormik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import React, { useEffect } from "react";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useParams } from "react-router-dom";
import {useSolarPanelIds, useUpdateSolarPanel} from "@/hooks/use-solar-panels";
import { NumberInput } from "@/components/UI/FormFields/Inputs";
import { TextInput } from "@/components/UI/FormFields/Inputs";
import "./EnergyBillsFormGenerate.css";

const EnergyBillsManualForm = ({ assetData }) => {
  const { homeId } = useParams();
  const { mutateAsync: updateSolarPanel } = useUpdateSolarPanel(homeId);
  const assetIds = useSolarPanelIds(homeId);
  const submitSolarPanel = async (data, { setStatus, setErrors }) => {
    for (const assetId of assetIds) {
      try {
        await updateSolarPanel({assetId, data});
      } catch (error) {
        handleMutationError(error, setStatus, setErrors);
      }
    }
  };
  const formik = useFormik({
    initialValues: assetData,
    enableReinitialize: true,
    onSubmit: submitSolarPanel,
  });
  const billChangeHandler = (evt) => {
    const index = evt.target.dataset.index
    const value = evt.target.value
    const newItems = [...formik.values.energy_bills];
    Object.assign(newItems[index], { power: value })
    formik.setFieldValue("energy_bills", newItems);
  };
  useEffect(() => {
    formik.setValues(assetData);
  }, [assetData]);
  if (!formik.values.energy_bills) {
    return <></>;
  }
  return (
    <>
      <FormikProvider value={formik}>
        <FormLevelError error={formik.status?.formLevelError} />
        <table className={styles["bills-table"]}>
          <thead>
            <tr>
              <th>Date</th>
              <th>kWh</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.energy_bills.map((bill, index) => (
              <tr key={index}>
                <td>
                  <TextInput
                    id={`energy_bills.${index}.date`}
                    name={`energy_bills.${index}.date`}
                    value={formatDate(bill.date)}
                    disabled={true} />
                </td>
                <td>
                  <NumberInput
                    data-index={index}
                    id={`energy_bills.${index}.power`}
                    name={`energy_bills.${index}.power`}
                    value={formik.values.energy_bills[index].power || ""}
                    onChange={billChangeHandler}
                    onBlur={() => formik.submitForm()}
                  />
                </td>
              </tr>
            )
            )}
          </tbody>
        </table>
      </FormikProvider>
    </>
  );
};

function formatDate(dateString) {
  const arr = dateString.split("-")
  return `${arr[1]}/${arr[0]}`;
}
export default EnergyBillsManualForm;
