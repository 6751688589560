import Card from "@/components/UI/Card";
import Autocomplete from "@/components/Wizard/Step1/Autocomplete";
import RecentHomes from "@/components/Homes/RecentHomes";
import { useRef } from "react";

const HomeList = () => {
  const addressInputRef = useRef(null);
  return (
    <>
      <Card className="mb-4">
        <Autocomplete addressInputRef={addressInputRef} />
      </Card>
      <Card>
        <RecentHomes addressInputRef={addressInputRef} />
      </Card>
    </>
  );
};

export default HomeList;
