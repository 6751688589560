import WizardSteps from "@/components/Wizard/WizardSteps";
import Card from "@/components/UI/Card";
import ArrayForm from "@/components/Wizard/Step2/ArrayForm";
import FormContainer from "@/components/Wizard/common/FormContainer";
import AddFormButton from "@/components/Wizard/common/AddFormButton";
import NavButtons from "@/components/Wizard/NavButtons";
import { useNavigate, useParams } from "react-router-dom";
import { useCreateSolarPanel, useSolarArrays } from "@/hooks/use-solar-panels";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { displayGenericClientSideError } from "@/components/UI/FormFields/server-errors-display";
import ArrayNotes from "@/components/Wizard/Step2/ArrayNotes";

const CalculationStep2 = () => {
  const navigate = useNavigate();
  const { homeId } = useParams();
  const queryClient = useQueryClient();

  const { isSuccess, data } = useSolarArrays(homeId);
  useEffect(() => {
    if (isSuccess && !data.length) {
      // send the user to step1 where we auto-create the solar panel
      // if there's none
      navigate(`/homes/${homeId}/step1`);
    }
  }, [isSuccess, data, history, homeId]);

  const { isLoading, mutateAsync: createSolarPanel } = useCreateSolarPanel(homeId);
  const onAddArray = async () => {
    const assets = queryClient.getQueryData(["homes", homeId, "solarPanels"]);
    const newAsset = {
      verified: false,
      ownership: assets[0].ownership,
      financing_type: assets[0].financing_type,
      utility_company: assets[0].utility_company,
      utility_kwh_charge: assets[0].utility_kwh_charge,
      is_energy_storage_included: assets[0].is_energy_storage_included,
      utility_plan_type: assets[0].utility_plan_type,
      specific_utility_plan_provider: assets[0].specific_utility_plan_provider,
      specific_utility_plan: assets[0].specific_utility_plan,
      specific_utility_plan_name: assets[0].specific_utility_plan_name,
      specific_utility_plan_uri: assets[0].specific_utility_plan_uri,
      specific_utility_plan_is_default: assets[0].specific_utility_plan_is_default,
      energy_bills: assets[0].energy_bills,
    };
    try {
      await createSolarPanel(newAsset);
      await queryClient.invalidateQueries(["homes", homeId, "solarPanels"]);
    } catch (error) {
      displayGenericClientSideError(error);
    }
  };

  const backHandler = () => {
    navigate(`/homes/${homeId}/step1`);
  };
  const nextHandler = () => {
    navigate(`/homes/${homeId}/step3`);
  };

  return (
    <>
      <WizardSteps />
      <Card>
        <ArrayNotes />
        {data.map((array, idx) => (
          <FormContainer key={array.id}>
            <ArrayForm initialValues={array} number={idx + 1} totalArrays={data.length} />
          </FormContainer>
        ))}
        <AddFormButton
          text="Add Array"
          addFormHandler={onAddArray}
          isLoading={isLoading}
        />
      </Card>
      <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />
    </>
  );
};

export default CalculationStep2;
