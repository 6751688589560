import { useInfiniteQuery } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import useEquityAppApi from "./use-equity-app-api";

export const useHomeList = (search) => {
  const client = useEquityAppApi();
  const fetchHomeList = async ({ pageParam = "1" }) =>
    client.get("/homes/", { params: { page: pageParam, search } });
  return useInfiniteQuery(["homes", search], fetchHomeList, {
    getNextPageParam: (lastPage) => {
      if (lastPage?.data.next === null) return null;
      const url = new URL(lastPage?.data.next);
      return url.searchParams.get("page");
    },
    onError: (error) => {
      handleQueryError(error);
    },
  });
};
