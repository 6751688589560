import ActionButton from "./ActionButton";
import React from "react";
import styles from "./css/Footer.module.css";
import DownloadButton from "./DownloadButton";
import { getCurrentYear } from "@/helpers/date";

export default function Footer({ address }) {
  const currentYear = getCurrentYear();
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        *Pearl Certification is not responsible for data inputs that are not aligned with
        the actual details of the homeowner’s property. Interpretation and use of data and
        results remains the sole responsibility of the user. Viewing this calculator and
        its results does not create a contractual relationship between the homeowner and
        Pearl Certification; this is intended to serve as a tool for homeowners, and the
        contractors and appraisers they work with, to estimate the value of certain
        features in their home.
      </p>
      <div
        className={`hide-when-printing ${styles.actions}`}
        data-html2canvas-ignore={true}
      >
        <DownloadButton address={address} />
        <ActionButton />
      </div>
      <div className={`hide-when-printing ${styles.copy}`} data-html2canvas-ignore={true}>
        <hr />
        <p>© {currentYear} </p>
        <p>Pearl Certification</p>
      </div>
    </div>
  );
}
