import React from "react";
import { useField } from "formik";
import { Form } from "react-bootstrap";
import styles from "./FormFieldWithShadow.module.css";
import RequiredAsterisk from "./RequiredAsterisk";

const RadioButton = ({ label, id, name, inline, ...props }) => {
  return (
    <Form.Check type="radio" inline={inline}>
      <Form.Check.Input type="radio" id={id} name={name} {...props} />
      <Form.Check.Label htmlFor={id}>{label}</Form.Check.Label>
    </Form.Check>
  );
};

const RadioButtonGroup = ({
  label,
  name,
  helpText,
  inline = true,
  shadow = true,
  required = false,
  id = null,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const isInvalid = meta.touched && !!meta.error;
  let cssClasses = "radio-group";
  if (isInvalid) {
    cssClasses += " is-invalid";
  }
  const shadowCssClass = shadow ? styles.shadow : "";
  const fieldId = id ? id : `id-${name}`;

  const choices = [
    { label: "Yes", value: true },
    { label: "No", value: false }
  ];

  return (
    <Form.Group controlId={fieldId} className={`form-group ${shadowCssClass}`} {...rest}>
      <Form.Label>
        {label}
        {required && <RequiredAsterisk />}
      </Form.Label>

      <div className={cssClasses}>
        {choices.map((choice, idx) => {
          return (
            <RadioButton
              key={`${fieldId}-${idx}`}
              id={`${fieldId}-${idx}`}
              name={name}
              checked={meta.value === choice.value}
              onChange={() => helpers.setValue(choice.value)}
              onBlur={field.onBlur}
              isInvalid={isInvalid}
              inline={inline}
              {...choice}
            />
          );
        })}
      </div>

      {helpText && (
        <Form.Text id={`help-text-${fieldId}`} muted>
          {helpText}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default RadioButtonGroup;