import { useNavigate, useParams } from "react-router-dom";
import Card from "@/components/UI/Card";
import NavButtons from "../NavButtons";
import SolarValuationAdjustmentForm from "./SolarValuationAdjustmentForm";

const Step1Part2 = ({ setPart, isSpecificUtilityPlan }) => {
  const navigate = useNavigate();
  const { homeId } = useParams();

  const backHandler = () => {
    if(isSpecificUtilityPlan){
      setPart("energy_bills");
    } else {
      setPart("1");
    }
    
  };
  const nextHandler = () => {
    navigate(`/homes/${homeId}/step2`);
  };

  return (
    <>
      <Card>
        <SolarValuationAdjustmentForm />
      </Card>
      <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />
    </>
  );
};

export default Step1Part2;
