export const createDateFromString = (dateString) => {
  // creates a date from a string while accounting for the user timezone
  // expected format for dateString is yyyy-mm-dd
  var date = new Date(dateString);
  date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  return date;
};

export const getCurrentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

export const getDateFormat = (date) => {
  return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export const getMonthFormat = (providedDate) => {
  // return YYYY-MM date format
  const date = new Date(providedDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedDate = `${year}-${month}`;
  return formattedDate
}