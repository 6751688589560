import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./NotificationConatiner.css";

const NotificationContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar
      transition={Slide}
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      draggable={false}
      icon={false}
    />
  );
};

export default NotificationContainer;
