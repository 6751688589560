import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import styles from "./Spinner.module.css";

const Spinner = ({ isLoading, text }) => {
  if (!isLoading) {
    return <></>;
  }

  return (
    <span className={styles.spinner}>
      <FontAwesomeIcon icon={faSpinner} spin />
      {text && <span className={styles.text}>{text}</span>}
    </span>
  );
};

export default Spinner;
