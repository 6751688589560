import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, lastUserType] = useSelector(({ auth }) => [
    auth.isAuthenticated,
    auth.lastUserType,
  ]);
  if (isAuthenticated) {
    return children;
  } else if (lastUserType === "appraiser") {
    return (
      <Navigate
        to={{ pathname: "/appraiser-login", state: { from: location } }}
        replace
      />
    );
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: location } }} replace />;
  }
};

export default PrivateRoute;
