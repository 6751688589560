import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@/components/UI/Tooltip";

const InfoBubble = ({ content }) => {
  return (
    <Tooltip content={content} interactive={true}>
      <span className="ms-2">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    </Tooltip>
  );
};

export default InfoBubble;
