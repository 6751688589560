import { FormikProvider, useFormik } from "formik";
import FormLevelError from "@/components/UI/FormFields/FormLevelError";
import React, { useEffect } from "react";
import AutoSaveForm from "@/components/UI/AutoSaveForm";
import { Col, Row } from "react-bootstrap";
import { NumberInput } from "@/components/UI/FormFields/Inputs";
import InputGroupField from "@/components/UI/FormFields/InputGroup";
import Select from "@/components/UI/FormFields/Select";
import FormHeader from "../common/FormHeader";
import SystemLosses from "./SystemLosses";
import DatepickerField from "@/components/UI/FormFields/DatepickerField";
import * as Yup from "yup";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";
import { useDeleteSolarPanel, useUpdateSolarPanel } from "@/hooks/use-solar-panels";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { cleanupNumberFieldsForSubmit, getCachedQuery } from "../common/helpers";
import ManufacturerAutocompleteField from "./ManufacturerAutocompleteField";
import ArrayModelNumberAutocompleteField from "./ArrayModelNumberAutocompleteField";
import TiltTooltipTable from "./TiltTooltipTable";
import InstallationCostField from "./InstallationCostField";
import { usePerms } from "@/hooks/use-perms";

const arrayTypeChoices = [
  { label: "Select Array Type", value: "" },
  { label: "Roof Mounted - Fixed", value: "roof_mounted_fixed" },
  { label: "Ground Mounted - Fixed", value: "ground_mounted_fixed" },
  { label: "1-axis", value: "1_axis" },
  { label: "1-axis backtracking", value: "1_axis_backtracking" },
  { label: "2-axis", value: "2_axis" },
];

const schema = Yup.object().shape({
  manufacturer: Yup.string(),
  model_number: Yup.string(),
  efficiency_rating: Yup.number(),
  system_size: Yup.number(),
  array_type: Yup.string(),
  installation_date: Yup.mixed(),
  azimuth: Yup.number(),
  tilt_slope: Yup.number().max(90),
  soiling: Yup.number(),
  shading: Yup.number(),
  snow: Yup.number(),
  mismatch: Yup.number(),
  wiring: Yup.number(),
  connections: Yup.number(),
  light_induced_degradation: Yup.number(),
  nameplate_rating: Yup.number(),
  availability: Yup.number(),
  warranty: Yup.number(),
  installation_cost: Yup.number(),
});

const getInstallationDate = (date) => {
  if (date) {
    const month = date.toLocaleDateString("en-US", { month: "2-digit" });
    return `${date.getFullYear()}-${month}`;
  } else {
    return null;
  }
};

const numberFields = [
  "efficiency_rating",
  "system_size",
  "azimuth",
  "tilt_slope",
  "soiling",
  "shading",
  "snow",
  "mismatch",
  "wiring",
  "connections",
  "light_induced_degradation",
  "nameplate_rating",
  "availability",
  "warranty",
  "installation_cost",
];

const ArrayForm = ({ number, totalArrays, initialValues }) => {
  const { homeId } = useParams();
  const queryClient = useQueryClient();

  const { mutateAsync: deleteSolarPanel, isLoading } = useDeleteSolarPanel(
    homeId,
    initialValues.id
  );

  const { mutateAsync: updateSolarPanel } = useUpdateSolarPanel(homeId);

  const submitSolarPanel = async (
    { id, installation_date, ...rest },
    { setStatus, setErrors }
  ) => {
    let values = { installation_date: getInstallationDate(installation_date), ...rest };
    values = cleanupNumberFieldsForSubmit(values, numberFields);
    const oldAssetData = getCachedQuery(
      queryClient,
      ["homes", homeId, "solarPanels"],
      id
    );
    const newAssetData = { ...oldAssetData, ...values };
    try {
      await updateSolarPanel({ assetId: id, data: newAssetData });
    } catch (error) {
      handleMutationError(error, setStatus, setErrors);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: submitSolarPanel,
  });
  const { hasAppraiserPerm, hasSunPowerPerm } = usePerms();
  useEffect(() => {
    if (hasSunPowerPerm) {
      formik.setFieldValue(
        "manufacturer",
        initialValues.manufacturer ? initialValues.manufacturer : "SunPower"
      );
      formik.submitForm();
    }
  }, [hasSunPowerPerm]);

  return (
    <FormikProvider value={formik}>
      <FormHeader
        title={`Array ${number}`}
        deleteHandler={deleteSolarPanel}
        isLoading={isLoading}
        deleteDisabled={totalArrays === 1}
      />

      <AutoSaveForm dateFields={["installation_date"]}>
        <FormLevelError error={formik.status?.formLevelError} />

        <Row>
          <Col md={4}>
            <ManufacturerAutocompleteField
              label="Make"
              system="solar_panel_and_array"
              number={number}
            />
          </Col>
          <Col md={4}>
            <ArrayModelNumberAutocompleteField number={number} />
          </Col>
          <Col md={4}>
            <InputGroupField
              label="Efficiency Rating"
              name="efficiency_rating"
              appendedText="%"
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <NumberInput label="Size (kW)" name="system_size" required={true} />
          </Col>
          <Col md={4}>
            <Select label="Array Type" name="array_type" choices={arrayTypeChoices} />
          </Col>
          <Col md={4}>
            <DatepickerField
              label="Installed Month"
              name="installation_date"
              placeholderText="MM/YYYY"
              dateFormat="MM/yyyy"
              showMonthYearPicker
              required={true}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <InputGroupField
              type="number"
              label="Azimuth"
              name="azimuth"
              appendedText="°"
            />
          </Col>
          <Col md={4}>
            <InputGroupField
              type="number"
              label="Tilt"
              name="tilt_slope"
              appendedText="°"
              tooltipContent={<TiltTooltipTable />}
            />
          </Col>
          <Col md={4}>
            <NumberInput label="Warranty (years)" name="warranty" />
          </Col>
        </Row>

        <InstallationCostField show={hasAppraiserPerm} />

        <SystemLosses />
      </AutoSaveForm>
    </FormikProvider>
  );
};

export default ArrayForm;
