import noHomesIcon from "@/assets/icons/noHomesIcon.svg";
import styles from "./HomeNoResults.module.css";
import { Button } from "react-bootstrap";

const HomeNoResults = ({ title, text, addressInputRef }) => {
  const startClickHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    addressInputRef.current.focus();
  };

  return (
    <div className={styles.noresults}>
      <div className={styles.icon}>
        <img src={noHomesIcon} alt="icon" />
      </div>
      <div className={styles.address}>{title}</div>
      <div className={styles.message}>{text}</div>
      {addressInputRef && (
        <Button onClick={startClickHandler} variant="success" className={styles.start}>
          Start
        </Button>
      )}
    </div>
  );
};
export default HomeNoResults;
