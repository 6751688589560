import { Button, Modal } from "react-bootstrap";
const VerticallyCenteredModal = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      {props.header && (
        <Modal.Header closeButton>
          {props.heading && (
            <Modal.Title id="contained-modal-title-vcenter">{props.heading}</Modal.Title>
          )}
        </Modal.Header>
      )}
      <Modal.Body className="p-0 mx-2">{props.children}</Modal.Body>
      {props.footer && (
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default VerticallyCenteredModal;
