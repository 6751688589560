import { Form, useFormikContext } from "formik";
import Spinner from "./Spinner";

const AutoSaveForm = ({ children, dateFields = [], ...props }) => {
  const formik = useFormikContext();

  const changeHandler = (event) => {
    const tagName = event.target.tagName;
    const type = event.target?.type;
    const fieldName = event.target.name;
    const isSelectOne =
      tagName === "SELECT" && Object.keys(formik.values).includes(fieldName);
    const isRadioButton =
      tagName === "INPUT" &&
      type === "radio" &&
      Object.keys(formik.values).includes(fieldName);
    const isCheckbox =
      tagName === "INPUT" &&
      type === "checkbox" &&
      Object.keys(formik.values).includes(fieldName);
    if (isSelectOne || isRadioButton || isCheckbox) {
      formik.submitForm();
    }
  };
  const blurHandler = (event) => {
    const tagName = event.target.tagName;
    const type = event.target?.type;
    const fieldName = event.target.name;
    const isTextArea =
      tagName === "TEXTAREA" && Object.keys(formik.values).includes(fieldName);
    const isInput = tagName === "INPUT" && Object.keys(formik.values).includes(fieldName);
    const isRadioButton = tagName === "INPUT" && type === "radio";
    const isCheckbox = tagName === "INPUT" && type === "checkbox";
    // blur event for datepicker returns old data, so we're not triggering autosave
    // on blur event for datepickers
    if (
      !dateFields.includes(fieldName) &&
      (isTextArea || (isInput && !isRadioButton && !isCheckbox))
    ) {
      formik.submitForm();
    }
  };
  return (
    <Form
      onChange={changeHandler}
      onBlur={blurHandler}
      style={{ position: "relative" }}
      {...props}
    >
      <Spinner isLoading={formik.isSubmitting} text="Saving..." />
      {children}
    </Form>
  );
};

export default AutoSaveForm;
