import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import styles from "./HomeAddressSearchFormField.module.css";
import "../FormFields/InputGroupWithShadow.css";

const HomeAddressSearchFormField = ({ onAddressChange, enteredAddress }) => {
  return (
    <Form.Group
      controlId="id-home_address"
      className={`form-group input-group-with-shadow ${styles["full-address-search"]}`}
    >
      <InputGroup>
        <Form.Control
          name="home_address"
          type="text"
          value={enteredAddress}
          onChange={onAddressChange}
          placeholder="Search"
          autoComplete="off"
        />
        <FontAwesomeIcon className={styles["font-awesome"]} icon={faSearch} />
      </InputGroup>
    </Form.Group>
  );
};

export default HomeAddressSearchFormField;
