import styles from "./UserDropdown.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@/store/auth-slice";
import useLogout from "@/hooks/use-logout";

const UserDropdown = (props) => {
  const { firstName, lastName, refreshToken } = useSelector((store) => store.auth);
  const name = `${firstName} ${lastName}`;
  const logoutDispatch = useDispatch();
  const { mutate } = useLogout();
  const logoutHandler = () => {
    logoutDispatch(authActions.logout());
    mutate({ refresh_token: refreshToken });
  };

  let cssClasses = styles.dropdown;
  if (props.cssClasses) {
    cssClasses += ` ${props.cssClasses}`;
  }
  if (props.desktopOnly) {
    cssClasses += ` ${styles.desktop}`;
  } else if (props.mobileOnly) {
    cssClasses += ` ${styles.mobile}`;
  }

  return (
    <Dropdown className={cssClasses}>
      <Dropdown.Toggle variant="light">{name}</Dropdown.Toggle>

      <Dropdown.Menu className={styles["dropdown-menu"]}>
        <Dropdown.Item href="#" onClick={logoutHandler}>
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserDropdown;
