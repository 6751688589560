import PvSystemOWFTooltip from "@/components/Wizard/Step1/PvSystemOWFTooltip";
import { usePerms } from "@/hooks/use-perms";

const OwnedWithFinancingLabel = () => {
  const { hasAppraiserPerm } = usePerms();

  return (
    <>
      Owned with Financing
      {hasAppraiserPerm && <PvSystemOWFTooltip />}
    </>
  );
};
export default OwnedWithFinancingLabel;
