import { useNavigate, useParams } from "react-router-dom";
import Card from "@/components/UI/Card";
import NavButtons from "../NavButtons";
import styles from "./Step1PartEnergyBills.module.css";
import EnergyBillsForm from "./EnergyBillsForm";

const Step1PartEnergyBills = ({ setPart, hasAppraiserPerm }) => {
  const navigate = useNavigate();
  const { homeId } = useParams();

  const backHandler = () => {
    setPart("1");
  };
  const nextHandler = () => {
    if (hasAppraiserPerm) {
      setPart("2");
    } else {
      navigate(`/homes/${homeId}/step2`);
    }
  };

  return (
    <>
      <Card>
        <p className={styles.infos}>
          To accurately model the system we need to know the kWh from the electricity
          bills. Please provide 12 months of kWh from the electric utility bills.
        </p>
        <EnergyBillsForm  />
      </Card>
      <NavButtons onBackClick={backHandler} onNextClick={nextHandler} />
    </>
  );
};

export default Step1PartEnergyBills;
