import styles from "./css/OptionSnippet.module.css";

const OptionSnippet = ({ title, description, icon }) => (
  <div className={styles.container}>
    <div>
      <img src={icon} alt="icon" />
    </div>
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  </div>
);
export default OptionSnippet;
