import AutocompleteField from "@/components/UI/FormFields/AutocompleteField";
import { useManufacturerSuggestions } from "@/hooks/use-suggestions";
import { useFormikContext } from "formik";
import { useDebounce } from "use-debounce";

const getSuggestionValue = (suggestion) => suggestion.name;

const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

const ManufacturerAutocompleteField = ({ number, system, label = "Make" }) => {
  const { values, setFieldValue, submitForm } = useFormikContext();
  const [debouncedManufacturer] = useDebounce(values.manufacturer, 250);
  const { data } = useManufacturerSuggestions(system, debouncedManufacturer);
  const onSuggestionSelected = (event, { suggestionValue }) => {
    setFieldValue("manufacturer", suggestionValue);
    submitForm();
  };

  const autosuggestProps = {
    suggestions: data || [],
    onSuggestionsFetchRequested: () => { },
    onSuggestionsClearRequested: () => { },
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
  };

  return (
    <AutocompleteField
      label={label}
      name="manufacturer"
      helpText="This field supports autocomplete."
      autosuggestProps={autosuggestProps}
      id={`id-manufacturer-${number}`}
    />
  );
};

export default ManufacturerAutocompleteField;
