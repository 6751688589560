import useEquityAppApi from "./use-equity-app-api";
import { useMutation } from "react-query";
import getTokens from "@/helpers/login/login";
import { authActions } from "@/store/auth-slice";
import { toast } from "react-toastify";
import { getDateFormat } from "@/helpers/date"

export const cleanAppraiserData = (data) => {
  return {
    first_name: data["first_name"],
    last_name: data["last_name"],
    email: data["email"],
    password1: data["password1"],
    password2: data["password2"],
    appraiser: {
      license_number: data["license_number"],
      state: data["state"],
      expiration_date: getDateFormat(data["expiration_date"]),
    },
  };
};

export const useAppraiserSignUp = () => {
  const client = useEquityAppApi();
  return useMutation(({ data }) => client.post("/appraiser_signup/", data), {
    onSuccess: (data, variables) => {
      getTokens(variables.data.email, variables.data.password1).then((response) => {
        if (response.error) {
          toast.warning(response.error);
        }
        if (response.data) {
          variables.dispatch(authActions.login(response.data));
        }
      });
    },
  });
};
