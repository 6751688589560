import { configureStore } from "@reduxjs/toolkit";
import authSlice, { saveAuthState } from "./auth-slice";
import modalSlice from "./modal-slice";

const store = configureStore({
  reducer: { auth: authSlice.reducer, modal: modalSlice.reducer },
  // devTools: true
});

store.subscribe(() => {
  const state = store.getState();
  saveAuthState(state.auth);
});

export default store;
