import styles from "./FormHeader.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

const FormHeader = ({
  title,
  deleteHandler,
  isLoading,
  deleteDisabled,
  deleteHidden,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      {!deleteHidden && (
        <button
          type="button"
          className={styles.delete}
          onClick={deleteHandler}
          disabled={isLoading || deleteDisabled}
        >
          {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
          {!isLoading && <FontAwesomeIcon icon={faTrash} />}
          Delete
        </button>
      )}
    </div>
  );
};

export default FormHeader;
