import { useParams } from "react-router-dom";
import { useSolarValuation, useSolarValuationInputs } from "@/hooks/use-solar-valuation";
import Result from "../ResultDefault/Result";
import CashFlowChart from "./CashFlowChart";
import Overview from "./Overview";
import SystemProfile from "./SystemProfile";
import CashFlowTable from "./CashFlowTable";
import Partners from "../ResultDefault/Partners";
import styles from "../ResultDefault/css/Footer.module.css";
import React from "react";
import ReportsSection from "./Reports/ReportsSection";

const AppraiserResults = () => {
  const { homeId } = useParams();
  const { data: inputs } = useSolarValuationInputs(homeId);
  const { data: solarValuation } = useSolarValuation(homeId);

  return (
    <>
      <Result address={inputs.address} data={solarValuation} />
      <div className="row my-4">
        <div className="col-md-6 mb-3 mb-md-0">
          <Overview />
        </div>
        <div className="col-md-6">
          <SystemProfile inputs={inputs} />
        </div>
      </div>
      <CashFlowChart valuationId={solarValuation.id} />
      <ReportsSection />
      <CashFlowTable valuationId={solarValuation.id} />
      <Partners />
      <p className={styles.text}>
        *Pearl Certification is not responsible for data inputs that are not aligned with
        the actual details of the homeowner’s property. Interpretation and use of data and
        results remains the sole responsibility of the user. Viewing this calculator and
        its results does not create a contractual relationship between the homeowner and
        Pearl Certification; this is intended to serve as a tool for homeowners,
        contractors, and appraisers to estimate the value of certain features of a home.
      </p>
    </>
  );
};

export default AppraiserResults;
