import styles from "./CalculatingEquity.module.css";
import loadingImg from "@/assets/imgs/loading.svg";

const CalculatingEquity = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.text}>Calculating Equity...</div>
      <img src={loadingImg} alt="loading-icon" />
    </div>
  );
};

export default CalculatingEquity;
