import React from "react";
import styles from "./css/MorePearlApps.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import awardStar from "@/assets/icons/awardStar.svg";
import MoneyB from "@/assets/icons/MoneyB.svg";

const MorePearlApps = () => {
  return (
    <div className={styles.more_apps}>
        <div className={styles.title}>MORE PEARL APPS</div>
        <div className={styles.sub_title}>Multiple tools.  One place.  Limitless value.</div>
        <div className={styles.text}>
            Pearl&apos;s suite of marketing and sales tools work together to help you build trust, 
            lower cost sensitivity, and showcase the value you deliver on every project — before and after installation.
        </div>
        <div className={styles.apps}>
            <div className={`${styles.app} ${styles.certification}`}>
                <div className={styles.header}>
                    <div>
                        <img  src={awardStar} alt="icon" /> 
                    </div>
                    <div>Certification App</div>
                </div>
                <div className={styles.body}>
                    Capture the value of<br /> your work.
                </div>
                <div className={styles.action}>
                    <a href="https://user.pearlcertification.com/" className="btn" target="blank">
                        <span></span>
                        Let&apos;s go
                        <FontAwesomeIcon icon={faAngleRight} />
                    </a>
                </div>
            </div>
            <div className={`${styles.app} ${styles.incentives}`}>
                <div className={styles.header}>
                    <div>
                        <img  src={MoneyB} alt="icon" /> 
                    </div>
                    <div>IncentivesHub</div>
                </div>
                <div className={styles.body}>
                    Turn browsers into buyers.
                </div>
                <div className={styles.action}>
                    <a href="https://admin.pearlcertification.com/sign_in" className="btn btn-dark" target="blank">
                        <span></span>
                        Let&apos;s go
                        <FontAwesomeIcon icon={faAngleRight} />
                    </a>
                </div>
            </div>
        </div>
    </div>
  );
};

export default MorePearlApps;
