import MobileSidebarToggle from "./MobileSidebarToggle";
import UserDropdown from "./UserDropdown";
import brandImg from "@/assets/imgs/brand.png";
import styles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const re = new RegExp("^/homes/.+");
  const isHomeDetailPathActivate = re.test(location.pathname); // test if pathname start and has more than /homes/
  return (
    <header className={`hide-when-printing ${styles.header}`}>
      <div className={styles.left}>
        <h1>Pearl Solar Equity Calculator</h1>
        {isHomeDetailPathActivate && (
          <Link to="/homes" className={`btn btn-primary ${styles["new-button"]}`}>
            New Calculation
          </Link>
        )}
      </div>
      <div className={styles.right}>
        <img className={styles.brand} src={brandImg} alt="brand" />
        <MobileSidebarToggle />
        <UserDropdown desktopOnly={true} />
      </div>
    </header>
  );
};

export default Header;
