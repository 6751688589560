import React from "react";
import printerSVG from "@/assets/icons/printer.svg";
import styles from "./css/ActionButton.module.css";

function ActionButton() {
  return (
    <button className={`btn ${styles.actionsBtn}`} onClick={window.print}>
      <img src={printerSVG} alt="P" />
      Print
    </button>
  );
}

export default ActionButton;
