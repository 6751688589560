import { useContext } from "react";
import { Accordion, AccordionContext } from "react-bootstrap";
import styles from "./CollapsibleHeader.module.css";

function CollapsibleHeader({ title, eventKey }) {
  const { activeEventKey } = useContext(AccordionContext);
  const isExpanded = activeEventKey === eventKey;
  let text = "Collapse";
  if (!isExpanded) {
    text = "Expand";
  }

  return (
    <Accordion.Header>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.toggle}>{text}</div>
      </div>
    </Accordion.Header>
  );
}

export default CollapsibleHeader;
