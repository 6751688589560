import { useEffect } from "react";

const useGoogleAnalytics = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
    script.async = true;

    document.body.appendChild(script);

    if (typeof window == "undefined") {
      return;
    }
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());
    gtag("config", process.env.REACT_APP_GA_TRACKING_ID);
  }, []);
};

export default useGoogleAnalytics;
