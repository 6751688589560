import logo from "@/assets/imgs/brand.png";
import styles from "./css/HeaderNoNetwork.module.css";

const HeaderNoNetwork = () => {

  return (
    <header className={styles.header}>
      <div className={styles.row}>
        <div className={styles.logo}>
          <img src={logo} alt="brand" className={styles.brand} />
          <div className={styles.vr} />
          <h2 className="text-uppercase">Pearl Solar Equity Calculator</h2>
        </div>
      </div>
    </header>
  );
};

export default HeaderNoNetwork;
