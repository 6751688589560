import React from "react";
import rightIcon from "@/assets/icons/rightIcon.svg";
import styles from "./css/Certification.module.css";
function Certification() {
  return (
    <div className={styles.container}>
      <p className={styles.p1}>Who is Pearl Certification?</p>
      <h2>
        Pearl Certification is dedicated to transforming the national housing market for
        good.{" "}
      </h2>
      <p className={styles.p2}>
        We help homeowners while simultaneously partnering with businesses and public
        entities to build a market that rewards energy efficiency. This holistic approach
        increases both the supply of and demand for sustainable, energy-efficient housing,
        from now on
      </p>
      <a
        href={process.env.REACT_APP_BASE_URL + "/about-pearl"}
        target="_blank"
        className="hide-when-printing"
        data-html2canvas-ignore={true}
        rel="noreferrer"
      >
        Learn more about Pearl Certification{" "}
        <img src={rightIcon} className="ms-2" alt="learn-more-icon" />
      </a>
    </div>
  );
}
export default Certification;
