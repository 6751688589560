import styles from "./CertificationReport.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const CertificationReport = ({ crUrl }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <FontAwesomeIcon icon={faStar} className={styles.star} size="2x" />
        <div className={styles.text}>
          This home has been certified by Pearl Certification
        </div>
      </div>
      <a
        className={`btn btn-primary ${styles.button}`}
        href={crUrl}
        target="_blank"
        rel="noreferrer"
      >
        View Certification
      </a>
    </div>
  );
};

export default CertificationReport;
