import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import styles from "./AddFormButton.module.css";

const AddFormButton = ({ isLoading, text, addFormHandler }) => {
  return (
    <Button
      variant="primary"
      className={styles.button}
      onClick={addFormHandler}
      disabled={isLoading}
    >
      {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      {!isLoading && <FontAwesomeIcon icon={faPlusCircle} className="fa-fw" size="xs" />}
      {text}
    </Button>
  );
};

export default AddFormButton;
