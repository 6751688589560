import React from "react";
import quote from "@/assets/icons/quote.png";
import styles from "./css/Review.module.css";
import userImg from "@/assets/imgs/user.png";
function Review() {
  return (
    <div className={styles.container}>
      <img className={styles.quote} src={quote} alt="db_quote" />
      <p className={styles.quote_line}>
        “I knew that there was a true value associated with the panels and
        batteries....Pearl made the difference in capturing the value of my solar system,”
      </p>
      <div className={styles.user}>
        <img src={userImg} alt="user" />
        <div className="d-flex flex-column">
          <p>Max</p>
          <span>Homeowner, AZ</span>
        </div>
      </div>
    </div>
  );
}

export default Review;
