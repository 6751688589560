import useEquityAppApi from "./use-equity-app-api";
import { useQuery } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";

export const useAiAddendum = (homeId) => {
  const client = useEquityAppApi();
  const url = `/homes/${homeId}/ai_addendum/`;

  return useQuery(
    ["homes", homeId, "aiAddendum"],
    () => client.get(url, { responseType: "blob" }),
    {
      onError: (error) => {
        handleQueryError(error);
      },
      enabled: false,
    }
  );
};


export const useAppraiserReport = (homeId) => {
  const client = useEquityAppApi();
  const url = `/homes/${homeId}/appraiser_report/`;

  return useQuery(
    ["homes", homeId, "appraiserReport"],
    () => client.get(url, { responseType: "blob" }),
    {
      onError: (error) => {
        handleQueryError(error);
      },
      enabled: false,
    }
  );
};

export const useCertificateReport = (homeId) => {
  const client = useEquityAppApi();
  const url = `/homes/${homeId}/report/`;

  return useQuery(
    ["homes", homeId, "report"],
    async () => {
      const response = await client.get(url);
      return response.data;
    },
    {
      onError: (error) => {
        handleQueryError(error);
      },
    }
  );
};
