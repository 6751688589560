import { useMutation, useQueryClient } from "react-query";
import { handleQueryError } from "@/components/UI/FormFields/server-errors-display";
import useEquityAppApi from "./use-equity-app-api";

export const useAssignToHome = (homeId) => {
  const client = useEquityAppApi();
  const queryClient = useQueryClient();

  return useMutation(() => client.post(`/homes/${homeId}/assign_appraiser/`), {
    onError: (error) => {
      handleQueryError(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("homes");
    },
  });
};
