import usePearlApi from "./use-pearl-api";
import { useMutation } from "react-query";
import { handleMutationError } from "@/components/UI/FormFields/server-errors-display";

const useLogout = () => {
  const client = usePearlApi(false);

  return useMutation((data) => client.post("/tokens/blacklist/", data), {
    onError: (error) => {
      handleMutationError(error);
    },
  });
};

export default useLogout;
