import { faHome, faStar } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import loginImg from "@/assets/imgs/login_bg.svg";
import { useHomesCount } from "@/hooks/use-homes-count";
import stylesContractor from "../Login/css/Welcome.module.css";
import styles from "./css/WelcomeAppraiser.module.css";
import Statistic from "./Statistic";

function WelcomeAppraiser({ btnText, setShow }) {
  const { data, status } = useHomesCount();
  let homes_certified_count = "";
  let homes_scored_count = "";
  if (data) {
    homes_certified_count = data.homes_certified_count;
    homes_scored_count = data.homes_scored_count;
  }
  return (
    <div className={`${stylesContractor.container} d-flex flex-column  h-100`}>
      <img className={stylesContractor.bg_img} src={loginImg} alt="logo" />
      <div>
        <h1 className={stylesContractor.heading}>
          Appraise High-Performing Homes Easily & Accurately
        </h1>
        <hr className={stylesContractor.hr} />
        <p className={stylesContractor["text-primary"]}>
          The Pearl Solar Equity Calculator currently calculates the discounted cash flow
          value of a residential photovoltaic (PV) installation. We plan to incorporate
          additional high-performing home features into the equity calculator in the
          future — including energy storage, heating and cooling, insulation, and more.
        </p>
        <p className={styles.registration}>
          Registration is free for licensed appraisers.
        </p>
      </div>
      <div>
        <div className={styles.statistics}>
          <Statistic
            number={homes_certified_count}
            icon={faHome}
            text={"HOMES CERTIFIED"}
            status={status}
          />
          <Statistic
            number={homes_scored_count}
            icon={faStar}
            text={"HOMES SCORED"}
            status={status}
          />
        </div>
        <button
          className={`btn btn-primary ${styles["member-login"]}`}
          onClick={() => setShow(true)}
        >
          {btnText}
        </button>
        <div className="d-flex justify-content-sm-start justify-content-center">
          <a
            href={process.env.REACT_APP_BASE_URL + "/about-pearl"}
            className={stylesContractor["learn-more-link"]}
            target="_blank"
            rel="noreferrer"
          >
            Learn More About Pearl Certification
          </a>
        </div>
      </div>
    </div>
  );
}

export default WelcomeAppraiser;
