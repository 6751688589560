import { useHome } from "@/hooks/use-create-or-update-home";
import Autocomplete from "./Autocomplete";
import { useParams } from "react-router-dom";

const HomeUpdate = () => {
  const { homeId } = useParams();
  const { data: home } = useHome(homeId);
  const address = home?.full_address ? home?.full_address : "";
  return <Autocomplete homeId={homeId} initialAddress={address} />;
};

export default HomeUpdate;
